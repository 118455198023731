import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/storage";
import moment from "moment";
import { getAnalytics } from "firebase/analytics";
const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID
} = process.env;

// Firebase Configuration Object
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};
console.log("firebaseConfig--------------------------***********************>", firebaseConfig);
//Update user name
export const updateUserName = async (
  first_name,
  last_name,
  timeStamp,
  image
) => {
  var user = firebase.auth().currentUser.uid;
  if (user) {
    try {
      firebase
        .database()
        .ref("users/" + timeStamp)
        .update({
          first_name: first_name,
          last_name: last_name,
          image: image,
        });
    } catch (e) {
      // console.log("this is error", e);
    }
  }
  return Promise.resolve({ user: user });
};
//Update user password
export const updateUserPassword = async (email, oldPassword) => {
  return firebase.auth().signInWithEmailAndPassword(email, oldPassword);
};

//Create User in Firebase
export const createUserProfileDocument = async (userAuth, additionalData) => {
  const dbRef = firebase.database().ref();
  let createdAt = userAuth._delegate.metadata.createdAt;
  if (!userAuth) return;
  if (userAuth !== null) {
    await dbRef
      .child("users")
      .child(createdAt)
      .get()
      .then((snapshot) => {
        if (!snapshot.exists()) {
          const { displayName, email } = userAuth;
          let image = "";
          let timeStamp = userAuth._delegate.metadata.createdAt;
          try {
            firebase
              .database()
              .ref("users/" + timeStamp)
              .set({
                displayName,
                email,
                timeStamp,
                image,
                ...additionalData,
              })
              .then(function () {
                var actionCodeSettings = {
                  url: "https://receiptmate.page.link/DQbR",
                  handleCodeInApp: false,
                  dynamicLinkDomain: "receiptmate.page.link",
                };
                firebase
                  .auth()
                  .currentUser.sendEmailVerification(actionCodeSettings)
                  .then(function (res) {
                    // Verification email sent.
                    // console.log('Verification email sent -------------', res)
                  })
                  .catch(function (error) {
                    // Error occurred. Inspect error.code.
                    // console.log("-------------", error);
                  });
              })
              .catch(function (error) {});
          } catch (error) {
            // console.log("error creating user", error.message)
          }
        }
      });
  }
};
//Check the databse for user value
export const getSnapshotFromDb = async (createdAt) => {
  const dbRef = firebase.database().ref();
  try {
    let snapshot_val = await dbRef
      .child("users")
      .child(createdAt)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          //Handle subscription type for old user
          const data = snapshot.val();
          if (!("account_type" in data)) {
            //adding additional data for checking the current user subscription status
            updateUserInformation(createdAt);
            snapshot_val = dbRef
              .child("users")
              .child(createdAt)
              .once("value")
              .then((snapshot) => {
                //Returning updated value of user
                if (snapshot.exists()) {
                  return snapshot.val();
                }
              });
          } else {
            return snapshot.val();
          }
        }
      });
    return Promise.resolve({ user: snapshot_val });
  } catch (error) {
    // console.log(error);
  }
};
//adding the additional info of user to handle account subscription status
// *****This was a later feature so I am just making it runable for previous user
const updateUserInformation = (timeStamp) => {
  let sub = {
    status: "active",
    start_date: getDateFormCurrentDateTo(Date.now(), 0),
    end_date: getDateFormCurrentDateTo(Date.now(), 90),
    plateform: "",
    product_id: "",
    transaction_id: "",
    transaction_receipt: "",
    amount: 0,
    currency: "$",
    original_transaction_id: "",
  };
  let transaction = [sub];
  try {
    firebase
      .database()
      .ref("users/" + timeStamp)
      .update({
        original_date: "",
        status: "active",
        notification_type: "",
        original_transaction_id: "",
        plateform: "",
        account_type: 1,
        subscription_date: Date.now(),
        transaction_history: transaction,
        subscription: sub,
      });
  } catch (e) {
    // console.log("this is error", e);
  }
};
//calculating the date value
const getDateFormCurrentDateTo = (timer, days) => {
  const myPastDate = new Date(timer);
  const timeStamp = myPastDate.setDate(myPastDate.getDate() + days);
  const t = new Date(timeStamp);
  const formatted = moment(t).format("MMM DD, YYYY");
  // const formatted = moment(t).format("MMM DD, YYYY H:mma");
  console.log("get formatted", formatted);
  return formatted;
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged((userAuth) => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  });
};

// Firebase web app init
const app = firebase.initializeApp(firebaseConfig);
getAnalytics(app);

const auth = firebase.auth();
const database = firebase.database();
export { firebase, auth, database, firebaseConfig };
