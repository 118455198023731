
import { receiptItemSave, getRecipetDataSnapshot } from "../../Firebase/dbReceiptItem";
import { receiptItemSaveSuccess, receiptItemSaveFailure, receiptItemFetchRequestSuccess, receiptItemFetchRequestFailure } from "./receiptItem.action";
import { takeLatest, all, put, call } from "redux-saga/effects";

import { receiptItemTypes } from "./receiptIem.types";
export function* recipetItemSagas() {
    yield all([
        call(onReceiptItemSaveRequest),
        call(onReceiptItemFetchRequest)

    ]);
}
export function* onReceiptItemSaveRequest() {
    yield takeLatest(receiptItemTypes.RECEIPT_ITEM_SAVE_REQUEST, extractData);
}
export function* extractData(data) {
    const {
        merchant,
        payment,
        date,
        totalAmount,
        currency,
        timeStamp,
        currencySymbol
    } = data.payload;
    //Making an object 
    const receiptItem = {
        merchant,
        payment,
        date,
        totalAmount,
        currency,
        timeStamp,
        currencySymbol

    }
    const { receiptImage } = data.payload;
    try {
        const result = yield call(receiptItemSave, receiptItem, receiptImage)
        if (result === '200') {
            const dataFetch = yield call(getRecipetDataSnapshot, data.payload.timeStamp);
            yield put(receiptItemSaveSuccess({ payload: dataFetch.payload }))
        }
    } catch (e) {
        yield put(receiptItemSaveFailure(e.message));
    }
}
export function* onReceiptItemFetchRequest() {
    yield takeLatest(receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST, fetchData)
}
export function* fetchData(data) {
    try {
        const dataFetch = yield call(getRecipetDataSnapshot, data.payload);
        if (dataFetch !== undefined) {
            yield put(receiptItemFetchRequestSuccess({ payload: dataFetch.payload }))
        }
    } catch (e) {
        yield put(receiptItemFetchRequestFailure(e.message));
    }
}