import React from "react";
import "./slider.scss";
import { PdfTag, PdfView, ImageTag } from "../../Utils/Constant";
import { NoImage } from "../../Utils/Constant";
export default function ImageView(props) {
  return (
    <div>
      {props.data !== undefined ? (
        props.data &&
        props.data.slice(0, 1).map((image, i) => (
          <div key={i + 1} className="ImageContainer ">
            <span className={image?.firebaseUrl ? 'topright-logo':image?.receiptImage ? 'topright-logo-image':''}></span>
            <img
              key={i}
              className="slider_img " 
              src={
                image?.firebaseUrl
                  ? PdfView
                  : image?.receiptImage
                  ? image.receiptImage
                  : NoImage
              }
              alt="not found"
            />
           
          </div>
        ))
      ) : (
        <div className="ImageContainer ">
          <img
            className="slider_img rounded mx-auto d-block"
            src={props.preview ? this.props.preview : NoImage}
            alt="not found"
          />
        </div>
      )}
    </div>
  );
}
