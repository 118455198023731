import { all, call } from 'redux-saga/effects';
import { authSagas } from "./Auth/auth.sagas";
import { updateSagas } from "./UpdateUserData/updateUser.sagas";
import { recipetItemSagas } from './RecieptListItem/receiptItem.sagas';
import { editReceiptDataSagas } from './EditRecieptData/editRecieptData.sagas';
export function* rootSaga() {
    yield all([
        call(authSagas),
        call(updateSagas),
        call(recipetItemSagas),
        call(editReceiptDataSagas)
    ])
}