import React, { useState } from 'react'
import CloseButton from '../CloseButton/CloseButton'
import { ArrowRight } from '../../Utils/Constant'
import './settings.scss'
import TermsConditon from '../TermsCondition/TermsConditon'
import PrivacyPolicy from '../TermsCondition/PrivacyPolicy'

export default function Settings() {
  const [openModel, setopenModel] = useState(false)
  const [openPrivacyModel, setopenPrivacyModel] = useState(false)
  const goBack = () => {}
  return (
    <div className="Settings">
      <TermsConditon show={openModel} onHide={() => setopenModel(false)} />
      <PrivacyPolicy
        show={openPrivacyModel}
        onHide={() => setopenPrivacyModel(false)}
      />
      <div className="Settings__title--container">
        <div className="d-flex">
          <h1 className="Settings__title--text">Settings</h1>
          <CloseButton back={goBack} />
        </div>
      </div>
      <div className="Settings__card ">
        <div
          className="row Settings__items g-0"
          onClick={() => setopenModel(true)}
        >
          <div className="col-sm-8  col-8 padding ">
            <label className="Settings__label--text">Terms of Services</label>
          </div>
          <div className="col-sm-4 col-4 Settings__text-right padding ">
            <img className="text-right" src={ArrowRight} alt="not found" />
          </div>
          <div className="Settings__separtor"></div>
        </div>
        <div
          className="row Settings__items g-0 "
          onClick={() => setopenPrivacyModel(true)}
        >
          <div className="col-sm-8  col-8 padding">
            <label className="Settings__label--text">Privacy Policy</label>
          </div>
          <div className="col-sm-4 col-4 Settings__text-right padding">
            <img src={ArrowRight} alt="not found" />
          </div>
        </div>
      </div>
    </div>
  )
}
