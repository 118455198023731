import React from 'react'
import { Search } from '@material-ui/icons'
import './search.scss'
export default function Searchbutton(props) {
  const getTypedValue = (e) => {
    props.onInputChange(e.target.value)
  }
  return (
    <div className="SearchBar w-100">
      <div className="SearchBar__container d-flex">
        <Search
     
          className="SearchBar__icon"
          style={{
            color: '#112153',
            marginTop: '10px',
            margin: '10px',
            width: '35px',
            height: '35px',
          }}
        />
        <input
          className="SearchBar__search-input"
          type="search"
          id="Search"
          name="search_receipts"
          placeholder={props.placeholder}
          onChange={(e) => getTypedValue(e)}
        />
      </div>
    </div>
  )
}
