export const editReceiptItemTypes = {
  RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REQUEST:
    'RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REQUEST',
  RECEIPT_ITEM_EDITRECEIPT_DATA_FETCH_REQUEST:
    'RECEIPT_ITEM_EDITRECEIPT_DATA_FETCH_REQUEST',
  RECEIPT_ITEM_EDITRECEIPT_STORE_SUCCCESS:
    ' RECEIPT_ITEM_EDITRECEIPT_STORE_DATA',
  RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_REQUEST:
    'RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_REQUEST',
  RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_SUCCESS:
    'RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_SUCCESS',
}
