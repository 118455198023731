import './loaderImage.scss'

import React from 'react'

function RecieptImageLoader() {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center RecieptImageLoader">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
      <br />
      <h6 className="h4 text-light">Processing...</h6>
    </div>
  )
}

export default RecieptImageLoader
