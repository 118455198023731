import { userUpdateTypes } from "./UpdateUser.type"

export const updateProfileStart = userInfo => ({
    type: userUpdateTypes.UPDATE_PROFILE_REQUEST,
    payload: userInfo
})

export const updateProfileSuccess = user => ({
    type: userUpdateTypes.UPDATE_PROFILE_REQUEST_SUCCESS,
    payload: user
})
export const updateProfileFaliure = error => ({
    type: userUpdateTypes.UPDATE_PROFILE_REQUEST_FAILURE,
    payload: error
})






