import React, { useState } from "react";
import Swal from "sweetalert2";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Backlogo } from "../../Utils/Constant";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../Loader/LoginLoader";
import { signUpStart } from "../../Redux/Auth/auth.actions";

import {
  selectAuthLoadingStateSignUp,
  selectAuthErrors,
} from "../../Redux/Auth/auth.selectors";
//Capitltalize the first letter of name
export const capitalizeWord = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
export default function SignUp(props) {
  const authError = useSelector(selectAuthErrors);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoadingStateSignUp);

  return (
    <Modal
      style={{ paddingBottom: "20px" }}
      {...props}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton className="LoginForm--model_header">
        <Modal.Title
          id="contained-modal-title-vcenter "
          className="LoginForm--model_title"
        >
          <div className="LoginForm--logo">
            <img className="LoginForm--img" src={Backlogo} alt="not found" />
          </div>
          <h1 className="LoginForm--title_sign_up">Welcome to ReceiptMate!</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ marginTop: "-20px" }}>
        <Formik
          initialValues={{
            email: "",
            password: "",
            repassword: "",
            first_name: "",
            last_name: "",
          }}
          validate={(values) => {
            const errors = {};
            setError(false);
            if (!values.first_name) {
              errors.first_name = "Please provide first name!";
            } else if (/[^A-Za-z0-9]+/i.test(values.first_name)) {
              errors.first_name = "Invalid name format!";
            } else {
              const updated_name = capitalizeWord(values.first_name);
              values.first_name = updated_name;
            }

            if (!values.last_name) {
              errors.last_name = "Please provide last name!";
            } else if (/[^A-Za-z0-9]+/i.test(values.last_name)) {
              errors.last_name = "Invalid name format!";
            } else {
              const updated_name = capitalizeWord(values.last_name);
              values.last_name = updated_name;
            }
            if (!values.email) {
              errors.email = "Please enter email address!";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address!";
            }
            if (!values.password) {
              errors.password = "Please enter password!";
            }
            if (values.password !== values.repassword) {
              errors.repassword = "Password should be the same!";
            }

            return errors;
          }}
          //Submit function for Sign Up

          onSubmit={(values, { setSubmitting }) => {
            const { first_name, last_name, email, password } = values;

            dispatch(signUpStart({ first_name, last_name, email, password }));
            setSubmitting(false);
            if (props.userToken !== null || props.userToken !== undefined) {
              setError(false);
              Swal.fire({
                title: "You are signed Up successfully.",
                icon: "success",
                showConfirmButton: true,
              });

              props.onHide();
            } else {
              setError(true);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="LoginForm--content_signup">
              <div className="d-flex justify-content-between">
                <div style={{ width: "calc(50% - 10px)" }}>
                  <Field
                    className="LoginForm--username"
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                  />
                  <ErrorMessage
                    className="LoginForm__err-msg"
                    name="first_name"
                    component="div"
                  />
                </div>
                <div style={{ width: "calc(50% - 10px)" }}>
                  <Field
                    className="LoginForm--username"
                    type="text"
                    name="last_name"
                    placeholder="Last name"
                  />
                  <ErrorMessage
                    className="LoginForm__err-msg"
                    name="last_name"
                    component="div"
                  />
                </div>
              </div>

              <Field
                className="LoginForm--username "
                type="email"
                name="email"
                placeholder="Email address"
              />
              <ErrorMessage
                className="LoginForm__err-msg"
                name="email"
                component="div"
              />

              <Field
                className="LoginForm--user-password"
                type="password"
                name="password"
                placeholder="Password"
              />
              <ErrorMessage
                className="LoginForm__err-msg"
                name="password"
                component="div"
              />

              <Field
                className="LoginForm--user-password"
                type="password"
                name="repassword"
                placeholder="Confirm Password"
              />
              <ErrorMessage
                className="LoginForm__err-msg"
                name="repassword"
                component="div"
              />
              <span className="LoginForm__err-msg">
                {error ? authError : ""}
              </span>
              <button
                className="LoginForm--signup_submit"
                type="submit"
                disabled={isSubmitting}
              >
                {isLoading ? <Loader /> : "Sign Up"}
              </button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
