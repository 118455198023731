import { takeLatest, all, put, call } from 'redux-saga/effects'
import { editReceiptItemTypes } from './editRecieptData.types'
import {
  receiptItemStoreRequestSuccess,
  receiptItemStoreRemoveDataSuccess,
} from './editRecieptData.action'

//Function for storing(persist) the value during edit receipt
export function* onEditReceiptDataStore() {
  yield takeLatest(
    editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REQUEST,
    saveToStore,
  )
}
//Function to store the editReciept view data
export function* saveToStore(editReceiptData) {
  yield put(receiptItemStoreRequestSuccess(editReceiptData))
}
//empty the store
export function* onEditReceiptDataRemove() {
  yield takeLatest(
    editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_REQUEST,
    emptyStore,
  )
}
//function for empty the store
export function* emptyStore(data) {
  yield put(receiptItemStoreRemoveDataSuccess(data))
}
export function* editReceiptDataSagas() {
  yield all([call(onEditReceiptDataStore), call(onEditReceiptDataRemove)])
}
