import React, { useState } from "react";
import { Eclipseinner, ArrowCurve } from "../../Utils/Constant";
import ImageCropper from "../../ImageCropper/ImageCropper";
import Loader from "../Loader/RecieptImageLoader";
import { useNavigate } from "react-router-dom";
import "./norecipts.scss";
import RecieptButton from "../ReceiptButton/RecieptButton";
import { FileUploader } from "react-drag-drop-files";

export default function NoReceipt({ displayItem }) {
  const [isCropWindow, setCropWindow] = useState(false);
  const [modelState, setModelState] = useState(false);
  const [merchantName, setMerchantName] = useState("");

  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [imageToCrop, setImageToCrop] = useState(false);
  const handleChange = (file) => {
    // setFile(file)
    if (file) {
      if (file.type === "application/pdf") {
        //navigate to pdf view screen
        navigate("pdf-view", {
          state: { pdfUrl: file, button: true },
        });
      }
      const reader = new FileReader();
      const filename = file.name;
      setMerchantName(filename);
      reader.addEventListener("load", () => {
        const image = reader.result;

        setImageToCrop(image);
      });

      reader.readAsDataURL(file);
      setCropWindow(true);
      setModelState(false);
    }
  };
  const onLoader = () => {
    setLoader(true);
  };
  const offLoader = () => {
    setLoader(false);
  };
  const fileTypes = ["png", "jpg", "jpeg", "pdf"];
  //Fetch user results
  const style = {
    paddingTop: "50px",
  };
  return (
    <>
      <ImageCropper
        show={isCropWindow}
        imagetocrop={imageToCrop}
        loaderon={onLoader}
        merchantname={merchantName}
        loaderoff={offLoader}
        Imagecropped={() => setImageToCrop("")}
        onHide={() => setCropWindow(false)}
      />
      {loader && <Loader />}

      <div className="Receipt " style={style}>
        <div className="Receipt__container">
          <div className="Receipt__eclipse ">
            <div className="Receipt__eclipse--outer ">
              <img
                className="Receipt__eclipse--inner--img"
                src={Eclipseinner}
                alt="not found"
              ></img>
            </div>
            <span className="Receipt__noreceipt--text">
              No receipts here yet
            </span>
          </div>
          <div className="Receipt__input--container">
            <FileUploader
              classes=""
              multiple={false}
              label="Drop files here"
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              children={
                <>
                  {" "}
                  <span className="Receipt__input--text">Drop files here</span>
                  <br />
                  <small className="Receipt__input-text--small">
                    or use the “add Receipt” button.
                  </small>{" "}
                </>
              }
            />
          </div>
        </div>

        <div>
          <img
            className="Receipt__arrow--image"
            src={ArrowCurve}
            alt="not found"
          />

          <RecieptButton bottom={"4%"} modelBottom={"10%"} />
        </div>
      </div>
    </>
  );
}
