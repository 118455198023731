import React, { Component } from "react";
// import BarcodeReader from 'react-barcode-reader'
import { Modal } from "react-bootstrap";
import Barcode from "react-barcode";
import "./barcode.scss";
import { toast } from "react-toastify";
export class BarcodeScanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: props.data,
      data: props.data,
    };

    this.handleScan = this.handleScan.bind(this);
    this.saveInputValue = this.saveInputValue.bind(this);
    this.deleteBarcodeValue = this.deleteBarcodeValue.bind(this);
  }
  handleScan(data) {
    this.setState({
      result: data,
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        result: props.data,
        data: props.data,
      };
    }

    // Return null to indicate no change to state.
    return null;
  }

  handleError(err) {
    // console.error(err)
  }
  saveInputValue() {
    if (this.state.result === "") {
      toast.error("Please enter barcode number!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      this.props.onPress(this.state.result);
      this.props.onHide();
      toast.success("Barcode added succesfully!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  //Remove barcode value
  deleteBarcodeValue() {
    if (this.state.result) {
      this.setState({ result: "" });
      this.props.onDelete("");
      this.props.onHide();
      toast.success("Barcode deleted succesfully!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      toast.warn("No barcode value avaliable to delete!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="sm"
        backdrop="static"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton style={{ display: "inline" }}>
          <Modal.Title id="contained-modal-title-vcenter ">
            <h1 className="LoginForm--title_sign_up">
              {this.state.data && "Edit receipt barcode number"}
              {this.state.data === "" && "Enter receipt barcode number"}
            </h1>
            <div className="text-center barcode">
              {this.state.result && <Barcode value={this.state.result} />}
            </div>
            <div>
              <div style={{ display: "grid" }}>
                <input
                  maxLength={48}
                  type="text"
                  className="LoginForm--username"
                  value={this.state.result}
                  placeholder="Enter barcode number"
                  onChange={(e) =>
                    this.setState(() => ({
                      result: e.target.value.toString(),
                    }))
                  }
                />

                <div className="d-flex justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline-danger mx-2"
                    onClick={this.deleteBarcodeValue}
                  >
                    Delete
                  </button>

                  <button
                    style={{ background: "#00b050" }}
                    type="button"
                    class="btn btn-success"
                    onClick={this.saveInputValue}
                  >
                    Done
                  </button>
                </div>
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
      </Modal>
    );
  }
}
