import React, { useEffect, useState } from "react";
import "./navigation.scss";
import { Backlogo, Home } from "../../Utils/Constant";
import { FolderButton } from "../../Utils/Constant";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import { SettingsButton, DefaultProfilePic } from "../../Utils/Constant";
import { MenuOpen } from "@material-ui/icons";
import { NavLink, useNavigate } from "react-router-dom";
export default function Navigation({ HandleToggle, Toggle }) {
  const currentUser = useSelector(selectCurrentUser);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [smallScreen, setSmallScreen] = useState(false);

  const navigate = useNavigate();
  //Close drawer on click
  const handleToggle = () => {
    window.scrollTo(0, 0);
    HandleToggle(!Toggle);
  };

  useEffect(() => {
    function reportWindowSize() {
      setWindowWidth(window.innerWidth);
      if (windowWidth < "576") {
        setSmallScreen(true);
      }
    }
    // Trigger this function on resize
    window.addEventListener("resize", reportWindowSize);
    //  Cleanup for componentWillUnmount
    return () => window.removeEventListener("resize", reportWindowSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="Navigation">
      <div className={Toggle ? "toggle open" : "toggle closed"}>
        <MenuOpen
          onClick={handleToggle}
          style={{ width: "2em", height: "2em", fontSize: "1rem" }}
        />
        {smallScreen ? (
          <nav className="box-shadow">
            {/* {!Toggle && (
              <img className="mobile_view_image" src={Backlogo} alt="" />
            )} */}
          </nav>
        ) : (
          ""
        )}
      </div>
      <div className="Navigation__logo" onClick={() => navigate("/")}>
        <img
          onClick={handleToggle}
          className="Navigation__logo_img"
          src={Backlogo}
          alt="sorry not found"
        ></img>
      </div>
      <div className="Navigation__user_image_container">
        <img
          src={
            currentUser && currentUser.payload.image !== ""
              ? currentUser.payload.image
              : DefaultProfilePic
          }
          className="Navigation__user_image"
          alt="not found"
        />

        <span className="Navigation__user_name">
          {currentUser && currentUser.payload.first_name ? (
            <strong style={{ fontSize: "20px" }}>
              {" "}
              {currentUser.payload.first_name}
            </strong>
          ) : (
            ""
          )}
          {currentUser && currentUser.payload.last_name ? (
            <strong style={{ fontSize: "20px" }}>
              {" "}
              {currentUser.payload.last_name}
            </strong>
          ) : (
            ""
          )}
        </span>
      </div>
      <div className="Navigation__user_profile_btn_container">
        <NavLink
          onClick={handleToggle}
          to="edit-profile"
          className={(navData) =>
            navData.isActive
              ? "Navigation_user_profile_active"
              : "Navigation__user_profile_btn"
          }
        >
          EDIT PROFILE
        </NavLink>
        {currentUser.payload.account_type === 1 && (
          <>
          <NavLink
            style={{ color: "#8e5500" }}
            onClick={() => handleToggle}
            to="account-information"
            className={(navData) =>
              navData.isActive
                ? "Navigation__user_profile_status"
                : "Navigation__user_profile_status"
            }
          >
            Premium Free Trial
          </NavLink>
           <span className="Navigation_user_profile_status_text">
           Your free trial premium account will expire on {currentUser.payload.subscription.end_date}
         </span>
         </>
        )}
        {currentUser.payload.account_type === 2 && (
          <>
            <NavLink
            style={{color: '#56dd93'}}
              onClick={() => handleToggle}
              to="account-information"
              className={(navData) =>
                navData.isActive
                  ? "Navigation__user_profile_status"
                  : "Navigation__user_profile_status"
              }
            >
            
              Activate Premium Account
            </NavLink>
           
          </>
        )}
        {currentUser.payload.account_type === 3 && (
          <NavLink
          style={{ color: "#8e5500" }}

            onClick={() => handleToggle}
            to="account-information"
            className={(navData) =>
              navData.isActive
                ? "Navigation__user_profile_status"
                : "Navigation__user_profile_status"
            }
          >
            Premium Account
          </NavLink>
        )}
      </div>
      <div className="Navigation__bottom_container">
        <div className="d-flex ">
          {/* <NavLink
            onClick={handleToggle}
            to="preferences"
            className={(navData) =>
              navData.isActive
                ? ' Navigation_link_text_active hvr-underline-from-center'
                : 'Navigation_link_text'
            }
            // activeclassname="Navigation__active"
          >
            <img
              className="Navigation_switch_icon"
              src={SwitchButtonDead}
              alt="not found"
            ></img>
            Preferences
          </NavLink> */}
        </div>
        <div className="d-flex ">
          <NavLink
            onClick={handleToggle}
            to="/"
            className={(navData) =>
              navData.isActive
                ? " Navigation_link_text_active "
                : "Navigation_link_text hvr-underline-from-center"
            }
            // activeclassname="Navigation__active"
          >
            <img className="grayscale" src={Home} alt="not found"></img>
            Home
          </NavLink>
        </div>

        <div className="d-flex">
          <NavLink
            onClick={handleToggle}
            to="receipt-folder"
            className={(navData) =>
              navData.isActive
                ? " Navigation_link_text "
                : "Navigation_link_text hvr-underline-from-center"
            }
            // activeclassname="Navigation__active"
          >
            <img
              className="Navigation_switch_icon"
              src={FolderButton}
              alt="not found"
            ></img>
            Receipt Folders
          </NavLink>
        </div>

        <div className="d-flex">
          <NavLink
            onClick={handleToggle}
            to="settings"
            className={(navData) =>
              navData.isActive
                ? " Navigation_link_text "
                : "Navigation_link_text hvr-underline-from-center"
            }
            // className=""
            // activeclassname="Navigation__active"
          >
            <img
              className="Navigation_switch_icon"
              src={SettingsButton}
              alt="not found"
            ></img>
            Settings
          </NavLink>
        </div>
      </div>
    </div>
  );
}
