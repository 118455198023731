import React, { Component } from "react";
import Slider from "react-slick";
import {
  SlickArrow,
  SlickPrev,
  PdfTag,
  PdfView,
  ImageTag,
} from "../../Utils/Constant";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NoImage } from "../../Utils/Constant";
import "./slider.scss";
function SampleNextArrow(props) {
  const { className, style } = props;
  return (
    <div
      className={className}
      style={{
        ...style,
        display: "block",
        background: "#E5E5E5",
        backgroundImage: `url(${SlickArrow})`,
        backgroundRepeat: "no-repeat ",
        backgroundPosition: "center",
        backgroundSize: "20px 20px",
        borderRadius: "4px",
      }}
      onClick={() => showNext(props)}
    />
  );
}
const showNext = (props) => {
  let index = props.currentSlide;
  if (props.slideCount >= index) {
    props.onNext(index + 1, props.slideCount);
    props.onClick();
  }
};

function SamplePrevArrow(props) {
  const { className } = props;
  return (
    <div
      className={className}
      style={{
        display: "block",
        background: "#E5E5E5",
        backgroundImage: `url(${SlickPrev})`,
        backgroundRepeat: "no-repeat ",
        backgroundPosition: "center",
        backgroundSize: "20px 20px",
        borderRadius: "4px",
      }}
      onClick={() => showPrev(props)}
    />
  );
}
const showPrev = (props) => {
  let index = props.currentSlide;
  if (index !== 0) {
    props.onPrev(index, props.slideCount);
    props.onClick();
  } else {
    let indexNow = props.slideCount;
    props.onPrev(indexNow, props.slideCount);
    props.onClick();
  }
};

export default class RecieptImage extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
      nextArrow: <SampleNextArrow onNext={this.props.onArrowPressNext} />,
      prevArrow: <SamplePrevArrow onPrev={this.props.onArrowPressPrev} />,
      slidesToScroll: 0,
    };
    return (
      <div>
        <Slider {...settings}>
          {this.props.data !== undefined ? (
            this.props.data &&
            this.props.data.slice(0, 1).map((image, i) => (
              <div key={i + 1} className="ImageContainer">
                {/* <img
                  key={i}
                  className={image?.firebaseUrl ? "slider_img topright-logo " :image?.receiptImage ? 'slider_img topright-logo': 'slider_img 123' }
                  src={
                    image?.firebaseUrl
                      ? PdfView
                      : image?.receiptImage
                      ? image.receiptImage
                      : NoImage
                  }
                  alt="not found"
                /> */}
                {image?.firebaseUrl && (
                  <img
                    className="pdf-tag"
                    src={image?.firebaseUrl ? PdfTag : ""}
                    alt=""
                  />
                )}{" "}
                {image?.receiptImage && (
                  <img
                    className="pdf-tag "
                    src={image.receiptImage !== "" ? ImageTag : ""}
                    alt=""
                  />
                )}
              </div>
            ))
          ) : (
            <div className="ImageContainer ">
              <img
                className="slider_img"
                src={this.props.preview ? this.props.preview : NoImage}
                alt="not found"
              />
            </div>
          )}
        </Slider>
      </div>
    );
  }
}
