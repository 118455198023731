import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./Imagewrpper.scss";
import axios from "axios";
import { selectCurrentUser } from "../Redux/Auth/auth.selectors";
import { Modal } from "react-bootstrap";
import { editReceiptItemPersistRequest } from "../Redux/EditRecieptData/editRecieptData.action";
import { uploadImage } from "../Firebase/dbReceiptItem";
import styled from "@emotion/styled";

function ImageCropper(props) {
  console.log(props, "props is here!");
  const [errorMsg, setErrMsg] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { imagetocrop } = props;
  const { merchantname } = props;
  const [croppedImage, setCroppedImage] = useState("");
  const [imageRef, setImageRef] = useState();
  const currentUser = useSelector(selectCurrentUser);
  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%", // default, can be 'px' or '%'
      x: 0,
      y: 0,
      width: 100,
      height: 100,
    }
  );
  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        imageRef.name // destination filename
      );
      // croppedImage to the parent component
      setCroppedImage(croppedImage);
    }
  }

  // 	});
  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );
    // ctx.putImageData(preprocessImage(canvas), 0, 0);
    // const dataUrl = canvas.toDataURL("image/jpeg");
    // setHighResImage(dataUrl)
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = fileName;
        resolve(file);
      });
    });
  }

  //Const save cropped image in redux store and
  const saveImage = () => {
    if (croppedImage === "") {
      setErrMsg("Please crop the image!");
    } else {
      props.loaderon();
      //upload the image file to firebase
      let timeStamp = currentUser.payload.timeStamp;
      uploadImage(timeStamp, croppedImage)
        .then((url) => {
          ConnectToServer(url);
        })
        .catch((err) => {
          setErrMsg("Something went wrong!");
        });
      console.log(timeStamp, "timeStamp-----");
    }
    //   const worker = createWorker();

    //   (async () => {
    //     await worker.load();
    //     await worker.loadLanguage("eng");
    //     await worker.initialize("eng");
    //     const {
    //       data: { text },
    //     } = await worker.recognize(imagetocrop);
    //     await worker.terminate();
    //     if (text) {
    //       // console.log(text, "text");
    //       ExtractImageData(text, imagetocrop, merchantname)
    //         .then((dataEtracted) => {
    //           const {
    //             currency,
    //             currencySymbol,
    //             date,
    //             merchant,
    //             payment,
    //             totalAmount,
    //           } = dataEtracted;
    //           const newObj = {
    //             currency,
    //             currencySymbol,
    //             date,
    //             merchant,
    //             payment,
    //             totalAmount,
    //             receiptImage: croppedImage,
    //           };
    //           //Save it to redux store
    //           dispatch(editReceiptItemPersistRequest(newObj));
    //           navigate("../create-receipt", { state: { from: "Scan" } }); //edit-receipt page
    //         })
    //         .catch((err) => {
    //           console.log(err, "errrr");
    //         });
    //       if (!isLoading) {
    //         props.loaderoff();
    //         props.onHide();
    //       }
    //     }
    //     //Close the loader if nothing is extracted from the Image
    //     //Rare case
    //     if (!isLoading) {
    //       props.loaderoff();
    //       props.onHide();
    //     }
    //   })();
    // }
  };

  //Sending the Image file to server for further parsing
  const ConnectToServer = (url) => {
    setErrMsg("");
    const file = croppedImage; //By default I am sending the not cropped one Image to stay safe from unusal results
    let email = currentUser.payload.email;
    if (file && email) {
      var formData = new FormData(); // Currently empty
      formData.append("email", email);
      formData.append("fileUrl", file, merchantname);
      formData.append("type", 2);
      // const data={
      //   email:email,
      //   fileUrl: url,
      //   type: 2
      // }
      const berarer =
        "8e3riH7G4NEcxmBuzvq41qzMnqvzQZtINgTgH7IudfX63pKxstT32RaFP47KZgzqzTgmcqYFZQeoe31ZsoEafZ84RvkOMvaE3eJqAlv6JEWsiJu5BoGmw2qVAS6USgrtRblyotX1hzLAJNxgCAZsE4Gz2BY1CVh6mUCSCh0ydGLBpmuXp9CyBRoVEYBs3hxrc7pKjOHXU2Qn6ATS9YHCZD2uSTsnXm8PQSYCwLySv63LMeCWoCrpgTSodshbsf4rkthOv7s9wHvOfmpbhGB6clBC3VCCO9PTKytHfakjDOSdL1hxpIDSeUqIjXaKm6M13Uu96rDZTB08Fx6J3o7hwT5OLsnzXLya4mqv2RMk";
      axios({
        method: "post",
        // url: 'http://192.168.1.148:4000/importReceiptThroughPdf',
        url: "https://ereceiptforwarderapp.receiptmate.com/importReceiptThroughPdf",
        data: formData,
        headers: {
          Authorization: `Bearer ${berarer}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          // console.log(response,"erreer");
          if (response.status === 200) {
            // navigate("/")
            const {
              alert_title,
              barCode,
              barcode_formate,
              category,
              created,
              createdTimeStamp,
              currency,
              currencySymbol,
              date,
              folder_array,
              image_folder_array,
              key,
              merchant,
              note,
              notification_date,
              payment,
              percent_amount,
              return_policy,
              status,
              tax_amount_one,
              tax_amount_two,
              text_name_one,
              text_name_two,
              tip_amount,
              totalAmount,
              receipt_url,
            } = response.data.data;
            const data = {
              alert_title,
              barCode,
              barcode_formate,
              category,
              created,
              createdTimeStamp,
              currency,
              currencySymbol,
              date,
              folder_array,
              image_folder_array,
              key,
              merchant,
              note,
              notification_date,
              payment,
              percent_amount,
              receiptImage: url,
              return_policy,
              status,
              firebaseUrl: "",
              tax_amount_one,
              tax_amount_two,
              text_name_one,
              text_name_two,
              tip_amount,
              totalAmount,
              imagePath: "",
              receipt_url,
            };
            dispatch(editReceiptItemPersistRequest(data));
            navigate("../create-receipt", { state: { from: "Scan" } }); //edit-receipt page
          } else {
            setErrMsg(response.data.message);
          }
        })
        .catch(function (error) {
          props.loaderoff();
          setErrMsg(error.response.data.message);
          // console.log(error);
        });
    }
  };

  return (
    <Modal
      show={props.show}
      onHide={props.isHide}
      // backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{ width: "100%", height: "100%" }}
    >
      <ModalWrapper>
        <Modal.Header className="ImageCropper__title">
          Please crop the image
        </Modal.Header>
        {imagetocrop && (
          <ReactCrop
            style={{ margin: "auto", width: "100%", height: "80vh" }}
            ruleOfThirds
            src={props.imagetocrop}
            crop={cropConfig}
            onImageLoaded={(imageRef) => setImageRef(imageRef)}
            onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(cropConfig) => {
              setCropConfig(cropConfig);
              setErrMsg("");
            }}
          />
        )}
        <span style={{ color: "red", textAlign: "center", fontSize: "20px" }}>
          {errorMsg}
        </span>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={props.onHide}
          >
            Cancel
          </button>
          <button
            style={{ background: "#00b050", color: "#ffff" }}
            type="button"
            className="btn btn-outline-success"
            onClick={saveImage}
          >
            Scan
          </button>
        </div>
      </ModalWrapper>
    </Modal>
  );
}

const ModalWrapper = styled.div`
  width: "400px";
  height: "90vh";
`;

export default ImageCropper;
