import React, { useMemo, useState, useEffect } from 'react'
import Select, { components } from 'react-select'
import { getFolderItemsSnapshot } from '../../Firebase/dbFolderItems'
import { selectCurrentUser } from '../../Redux/Auth/auth.selectors'
import { useSelector } from 'react-redux'

export default function FolderDrop(props) {
  const currentUser = useSelector(selectCurrentUser)
  const [folderList, setfolderList] = useState([])
  const [isFolderList, setIsFolderList] = useState(true)
  const [selectedNames, setSelectedNames] = useState([])

  const timeStamp = currentUser.payload.timeStamp
  //sort list in alpabetically order
  function compare(a, b) {
    if (a.folderName < b.folderName) {
      return -1
    }
    if (a.folderName > b.folderName) {
      return 1
    }
    return 0
  }
  const FolderList = useMemo(() => {
    const tempData = []
    getFolderItemsSnapshot(timeStamp).then((res) => {
      for (var key in res.payload) {
        tempData.push({
          id: key,
          ...res.payload[key],
        })
      }
      const data = tempData.sort(compare)
      // console.log(data, 'data')
      setfolderList([...data])
      setIsFolderList(false)
      return data
    })

    return folderList ?? []
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFolderList])
  const Options = useMemo(() => {
    if (FolderList.length > 0) {
      // console.log('folderlist', FolderList)
      return FolderList.map((data, i) => ({
        options: [
          {
            label: data.folderName,
            value: data.folderName,
            image: data.image,
          },
        ],
      }))
    }

    return []
  }, [FolderList])
  const { Option } = components
  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.image}
        style={{
          height: '30px',
          width: '30px',
          borderRadius: '50%',
          marginRight: '10px',
        }}
        alt=""
      />
      {props.data.label}
    </Option>
  )
  const customStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'blue' : 'grey',

      padding: 20,
    }),
    control: () => ({
      paddingRight: 15,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'
      return { ...provided, opacity, transition }
    },
  }

  const handleChange = (event) => {
    // setSelectedNames(event)
    props.onFolderChange(event)
  }
  useEffect(() => {
    setSelectedNames(props.defaultValue)
  }, [props.defaultValue])

  return (
    <div>
      <Select
        isClearable={false}
        isMulti
        styles={customStyles}
        className="EditReceipt__card--currency"
        value={selectedNames}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
        placeholder={'Search/Select'}
        onChange={handleChange}
        options={Options}
      />
    </div>
  )
}
