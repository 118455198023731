import React, { useState, useEffect } from 'react'
import Select, { components } from 'react-select'

export default function SelectDrop(props) {
  const secondOption = props.options
  const [selectedValue, setSelectedValue] = useState('')
  const spacing = {
    fontSize: '18px',
  }
  const end = {
    float: 'right',
    fontSize: '15px',
  }
  const left = {
    fontSize: '15px',
  }
  const { Option } = components
  const IconOption = (props) => (
    <Option {...props}>
      <span style={spacing}>{props.data.value}</span>
      <span style={left}>({props.data.symbol})</span>
      <span style={end}>{props.data.label}</span>
    </Option>
  )
  const options = [
    {
      label: 'POPULAR',
      options: [
        { label: 'CAD', symbol: '$', value: 'Canadian Dollar' },
        { label: 'USD', symbol: '$', value: 'US Dollar' },
        { label: 'EUR ', symbol: '€', value: 'Euro' },
      ],
    },
    {
      label: 'All',
      options: [...secondOption],
    },
  ]
  const customStyles = {
    menu: (provided) => ({
      ...provided,
      width: 'max-content',
      zIndex: 9999,
      color: '#a3a3a3',
    }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'blue' : 'grey',
      paddingBottom: 20,
    }),
    control: () => ({
      color: 'black',
      // none of react-select's styles are passed to <Control />
      // width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 500ms'

      return { ...provided, opacity, transition, color: '#112153' }
    },
  }
  const getCurrency = (value) => {
    const selected = value.label
    setSelectedValue(selected)
    const symbol = value.symbol
    props.getCurrency({ currency: selected, symbol: symbol })
  }
  useEffect(() => {
    setSelectedValue(props.defaultValue)
  }, [props.defaultValue])

  return (
    <div>
      <Select
        styles={customStyles}
        isMulti={false}
        autosize={true}
        className="EditReceipt__card--currency"
        value={
          selectedValue
            ? {
                label: selectedValue,
                value: selectedValue,
              }
            : ''
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
        placeholder={'Search/Select'}
        onChange={(value) => getCurrency(value)}
        options={options}
      />
    </div>
  )
}
