import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { KeyboardArrowDownOutlined } from "@material-ui/icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./editrecipt.scss";
import Barcode from "react-barcode";
import RecieptImage from "../SlickSlider/RecieptImage";
import { EyeIcon, TrashIcon } from "../../Utils/Constant";
import { EditDoc } from "../../Utils/Constant";
import { EditIcon } from "../../Utils/Constant";
import CurrencyList from "../../JsonFiles/currencyList.json";
import CategoryList from "../../JsonFiles/categoryList.json";
import ListFooterSave from "../ReceieptListContents/ListFooterSave/ListFooterSave";
import { BarcodeArrow } from "../../Utils/Constant";
import CloseButton from "../CloseButton/CloseButton";
import SelectDrop from "../SelectDrop/SelectDrop";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import moment from "moment";
import ImageViewer from "react-simple-image-viewer";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../Loader/RecieptImageLoader";
import SelectDropCategory from "../SelectDrop/SelectDropCategory";
import SelectDropPayment from "../SelectDrop/SelectDropPayment";
import AddTip from "../TaxBarcodeSection/AddTip";
import AddTax from "../TaxBarcodeSection/AddTax";
import ReturnPolicy from "../TaxBarcodeSection/ReturnPolicy";
import { BarcodeScanner } from "../../BarcodeScanner/barcodeScanner";
import {
  deleteImageFile,
  deletePdfFile,
  receiptItemSave,
  uploadImage,
} from "../../Firebase/dbReceiptItem";
import { getImageToBlob } from "../../BlobConversionHelper/imageToBlob";
import FolderDrop from "../SelectDrop/FolderDrop";
import { selectLoadingState } from "../../Redux/RecieptListItem/receiptItemSelector";
import { selecteditReceiptStoredData } from "../../Redux/EditRecieptData/editRecieptDataselector";
import { selectReceiptPushErrors } from "../../Redux/RecieptListItem/receiptItemSelector";
import {
  editReceiptItemPersistRequest,
  receiptItemStoreRemoveDataRequest,
} from "../../Redux/EditRecieptData/editRecieptData.action";
import ImageView from "../SlickSlider/ImageView";
import ReactGA from "react-ga";

export default function CreateReceipt(status) {
  const dispatch = useDispatch();
  const [titleSelected, settitleSelected] = useState("");
  const [userView] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [dateSelected, setdateSelected] = useState(new Date());
  const [buttonStatus, setButtonStatus] = useState("");
  const dateRef = useRef();
  const [totalAmount, settotalAmount] = useState("");
  const [imageView, setImageView] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const navigate = useNavigate();
  const inputRef = useRef();
  //Getiing the router info
  const { state } = useLocation();
  const location = useLocation();
  const [redirectFrom] = useState(state);
  const [preview, setPreview] = useState();
  const [selectedFile, setSelectedFile] = useState(undefined);
  //Checking the tax and other drop down
  const [isTipOpen, setTipState] = useState(false);
  const [pdfPath, setPdfPath] = useState(false);
  const [isReturnOpen, setReturnState] = useState(false);
  const [isTaxOpen, setTaxState] = useState(false);
  //Set state of the barcode
  const [barcodeModel, setBarcodeModel] = useState(false);
  const [barcodeValue, setBarcodeValue] = useState("");
  const [imagePath, setImagePath] = useState();
  const [currentIndex] = useState(0);
  const [isCalculate, setIsCalculate] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const receiptData = useSelector(selecteditReceiptStoredData);
  const [isImageDeleted, setIsImageDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const error = useSelector(selectReceiptPushErrors);
  //loading state
  const loadingState = useSelector(selectLoadingState);
  const [selectedPdf, setselectedPdf] = useState(undefined);
  //When data fetched from the db
  const [imageSlide, setimageSlide] = useState();
  //For the error messsge when user doesn't input the required value
  const [formError, setFormError] = useState({
    merchantErr: "",
    dateErr: "",
    paymentErr: "",
    currencyErr: "",
    totalAmountErr: "",
    categoryErr: "",
    folderListErr: "",
  });
  const [isFormError, setIsFromError] = useState(false);
  //Defining an object for storing all info when user edit receipts
  const [editReceiptData, setEditReceiptData] = useState("");
  //Here I will change the Image state
  const setImageProperty = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    if (e.target.files[0].type === "application/pdf") {
      //navigate to pdf view screen
      setselectedPdf(e.target.files[0]);
    } else {
      setSelectedFile(e.target.files[0]);
    }
  };

  console.log(editReceiptData?.totalAmount, "editReceiptData");

  //Open file input pop up
  const handleOpenFileInput = () => {
    inputRef.current.click();
  };
  // console.log(redirectFrom,"intial");
  //Pdf file strategy
  useEffect(() => {
    ReactGA.pageview(location.pathname);
    if (!selectedPdf) {
      return;
    } else {
      const objectUrl = URL.createObjectURL(selectedPdf);
      //as soon as Pdf changes get the blob for store it in Firebase
      setImageLoader(true);
      getImageToBlob(objectUrl)
        .then((blobImage) => {
          // console.log(blobImage, 'blobImage')
          const timeStamp = currentUser.payload.timeStamp;

          uploadImage(timeStamp, blobImage)
            .then((firebaseUrl) => {
              editReceiptData.receiptImage = "";
              editReceiptData.firebaseUrl = firebaseUrl;
              let slide = [];
              slide.push(editReceiptData);
              setimageSlide(slide);
              setImageLoader(false); //Setting pdf loading state false
            })
            .catch((err) => {
              editReceiptData.firebaseUrl = "";
              toast.warn("Oops! Something went wrong upload failed!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        })
        .catch((err) => {
          setPdfPath("");
          // setImagePath('')
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPdf]);
  //useEffect for the handling

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
    } else {
      const objectUrl = URL.createObjectURL(selectedFile);
      //as soon as Pdf changes get the blob for store it in Firebase
      setImageLoader(true);
      getImageToBlob(objectUrl)
        .then((blobImage) => {
          // console.log(blobImage, 'blobImage')
          const timeStamp = currentUser.payload.timeStamp;

          uploadImage(timeStamp, blobImage)
            .then((imageUrl) => {
              editReceiptData.receiptImage = imageUrl;
              editReceiptData.firebaseUrl = "";
              let slide = [];
              slide.push(editReceiptData);
              setimageSlide(slide);
              setImageLoader(false); //Setting loading state false
            })
            .catch((err) => {
              editReceiptData.firebaseUrl = "";
              toast.warn("Oops! Something went wrong upload failed!", {
                position: "bottom-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            });
        })
        .catch((err) => {
          setPdfPath("");
          // setImagePath('')
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);
  // console.log("imageSldsdsdsdside", editReceiptData);
  //Function for fetching input values for manual
  const addValues = (e) => {
    const { name, value } = e.target;
    console.log(name, "name");
    var num = value ? parseFloat(value).toFixed(2) : "";

    setIsFromError(false);
    setEditReceiptData({
      ...editReceiptData,
      [name]: name == "totalAmount" ? num : value,
    });
  };
  const addDate = (date) => {
    setIsFromError(false);
    setdateSelected(date);
    let formatedDate = moment(date).format("MMM DD, YYYY");

    setEditReceiptData({
      ...editReceiptData,
      date: formatedDate,
      return_policy: "",
      alert_title: "",
      notification_date: "",
    });
  };
  // OPENS UP THE DATEPICKER WHEN THE CALENDAR ICON IS CLICKED FOR THE INPUT FIELD
  function handleClickDatepickerIcon() {
    const datepickerElement = dateRef.current;
    datepickerElement.setFocus(true);
  }

  // calculate the value for percent tip
  useMemo(() => {
    let totalAmount = editReceiptData.totalAmount;
    let tip_amount = editReceiptData.tip_amount;
    console.log(totalAmount, "finall --- totalAmount");
    if (totalAmount !== undefined) {
      if (totalAmount !== "") {
        if (tip_amount !== undefined) {
          totalAmount = totalAmount.replace(/,/g, "");
          if (tip_amount !== "") {
            // console.log(totalAmount, 'totalAmount')
            const valueOb = (tip_amount * 100) / (totalAmount - tip_amount);
            const finalValue = parseInt(valueOb).toFixed(2);
            setEditReceiptData({
              ...editReceiptData,
              percent_amount: finalValue,
            });
            console.log(finalValue, "finalValue--");
          } else {
            setEditReceiptData({
              ...editReceiptData,
              percent_amount: "",
            });
          }
        }
      }
    }
    setIsCalculate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCalculate]);

  //Getting the value of tax
  const getTaxValue = (name, value) => {
    var num = value ? parseFloat(value).toFixed(2) : "";
    if (name === "tax_amount_one" || name === "tax_amount_two") {
      setEditReceiptData({ ...editReceiptData, [name]: num });
    } else {
      setEditReceiptData({ ...editReceiptData, [name]: value });
    }
  };

  //Getting the tip value
  const getTipValue = (name, value) => {
    if (value) {
      console.log("get tip value start", value);
      var num = parseFloat(value).toFixed(2);

      console.log("get tip value", num);
      setEditReceiptData({
        ...editReceiptData,
        [name]: num,
      });
      setIsCalculate(true);
    } else {
      console.log("get tip value zero", value);
      setIsCalculate(false);
      setEditReceiptData({
        ...editReceiptData,
        [name]: "",
        percent_amount: "",
      });
    }
  };

  console.log("editReceiptData", editReceiptData);
  //Getting the retrun value
  const getReturnValue = (value) => {
    const { notification_date, alert_title, return_policy } = value;
    setEditReceiptData({
      ...editReceiptData,
      notification_date: notification_date,
      alert_title: alert_title,
      return_policy: return_policy == "No returns allowed" ? "" : return_policy,
    });
  };

  //gettting payment props
  const getPayment = (data) => {
    const { paymentMethod } = data;
    setEditReceiptData({ ...editReceiptData, payment: paymentMethod });
    setFormError(false);
  };
  //getting the currency value
  const getCurrency = (data) => {
    const { currency, symbol } = data;
    setEditReceiptData({
      ...editReceiptData,
      currency: currency,
      currencySymbol: symbol,
    });
    setFormError(false);
  };
  //getting the categpory value
  const getCategory = (data) => {
    const { category } = data;

    setEditReceiptData({ ...editReceiptData, category: category });
    setFormError(false);
  };
  //get folder name
  const getFolderName = (val) => {
    //getting the icon and name of Folder
    setEditReceiptData({
      ...editReceiptData,
      folder_array: val,
    });
  };
  // saving the values if page refreshs
  // useEffect(() => {
  //   let data = receiptData.payload
  //   window.onbeforeunload = function () {
  //     return 'Data will be lost if you leave the page, are you sure?'
  //   }
  //   return () => {
  //     window.removeEventListener('beforeunload', null)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  //Saving the currnwt index becuse state are run late
  useEffect(() => {
    window.localStorage.setItem("INDEX", currentIndex);
  }, [currentIndex]);

  //Value to be changed according to redirects
  useEffect(() => {
    if (redirectFrom.from === "PdfScan") {
      settitleSelected("Create Receipt");
      //Set button
      setButtonStatus("Save");
      let tempValues = receiptData.payload;
      setEditReceiptData(tempValues);
      let imageSlide = [];
      imageSlide.push(tempValues);
      setimageSlide(imageSlide);
      //end of the function
    } else if (redirectFrom.from === "Scan") {
      //set button
      settitleSelected("Create Receipt");
      setButtonStatus("Save");
      //setting the Image
      let tempValues = receiptData.payload;
      //it accepts a array
      let imageSlides = [];
      imageSlides.push(tempValues);
      setimageSlide(imageSlides);
      if (tempValues.date === "Invalid date") {
        tempValues["date"] = "";
        setEditReceiptData(tempValues);
      } else {
        setEditReceiptData(tempValues);
      }
    } else if (redirectFrom.from === "Manual") {
      settitleSelected("Create Receipt");
      setButtonStatus("Save");
      let dates = new Date();
      let timestamp =
        dateSelected === ""
          ? dates.getTime()
          : new Date(dateSelected).getTime();
      let formatedDate = moment(dateSelected).format("MMM DD, YYYY");

      setEditReceiptData((preValue) => {
        return {
          ...preValue,
          merchant: "",
          totalAmount: "",
          payment: "",
          receiptImage: "",
          firebaseUrl: "",
          category: "",
          note: "",
          barCode: "",
          barcode_formate: "",
          key: dates.getTime(),
          addReceiptTime: dates.getTime(),
          date: formatedDate,
          currency: "CAD",
          created: timestamp,
          createdTimeStamp: timestamp,
          currencySymbol: "$",
          folder_array: "",
          text_name_one: "",
          text_name_two: "",
          tax_amount_one: "",
          tax_amount_two: "",
          tip_amount: "",
          percent_amount: "",
          alert_title: "",
          return_policy: "",
          image_folder_array: "",
          notification_date: "",
          status: 2,
        };
      });
      //Reload the same data when page refresh and redirets
      if (receiptData?.payload) {
        let imageSlides = [];
        imageSlides.push(receiptData.payload);
        setimageSlide(imageSlides);
        setEditReceiptData(receiptData.payload);
        //empty the store after setting the data
        dispatch(receiptItemStoreRemoveDataRequest()); //empty store to handle unusal behavior
      } else {
        let imageSlides = [];
        imageSlides.push(editReceiptData);
        setimageSlide(imageSlides);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, receiptData, redirectFrom]);
  // console.log(editReceiptData, 'manual data')

  //handle the total amount
  useEffect(() => {
    try {
      const totalAmount = editReceiptData.totalAmount.toString();
      // console.log(totalAmount, 'totalAmount')
      let res = totalAmount.replace(/ /g, "");

      setEditReceiptData({ ...editReceiptData, totalAmount: res });
      setIsCalculate(true);
      settotalAmount(res);
    } catch (err) {
      // console.log(err)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReceiptData.totalAmount]);
  // Save the data in firebase storage

  const saveItem = () => {
    //Validation  for form
    var reg = /^\d*(.\d{2})?$/g;
    //first validate then check for redirection
    if (
      editReceiptData.merchant === "" ||
      editReceiptData.merchant === undefined
    ) {
      setFormError((preValue) => {
        return {
          ...preValue,
          merchantErr: "Please provide the merchant name!",
        };
      });
      setIsFromError(true);
    } else if (
      editReceiptData.totalAmount === "" ||
      editReceiptData.totalAmount === undefined
    ) {
      setFormError((preValue) => {
        return {
          totalAmountErr: "Please provide the total amount!",
        };
      });
      setIsFromError(true);
    } else if (!reg.test(totalAmount)) {
      setFormError((preValue) => {
        return {
          totalAmountErr: "Please provide a valid format!",
        };
      });

      setIsFromError(true);
    } else if (
      editReceiptData.payment === "" ||
      editReceiptData.payment === undefined
    ) {
      setFormError((preValue) => {
        return {
          paymentErr: "Please select the payment method!",
        };
      });
      setIsFromError(true);
    } else if (
      editReceiptData.date === "" ||
      editReceiptData.date === undefined
    ) {
      setFormError((preValue) => {
        return {
          dateErr: "Please select date!",
        };
      });
      setIsFromError(true);
    } else if (
      editReceiptData.currency === "" ||
      editReceiptData.currency === undefined
    ) {
      setFormError((preValue) => {
        return {
          currencyErr: "Please select currency!",
        };
      });
      setIsFromError(true);
    } else {
      // if all condition satisfied
      Swal.fire({
        title: "Are you sure you want to save?",
        text: "This will save your receipt data",
        showCancelButton: true,
        confirmButtonColor: "#00b050",
        confirmButtonText: "Yes, save it!",
      }).then((result) => {
        if (result.isConfirmed) {
          if (
            redirectFrom.from === "Scan" ||
            redirectFrom.from === "Manual" ||
            redirectFrom.from === "PdfScan"
          ) {
            const timeStamp = currentUser.payload.timeStamp;
            setEditReceiptData((e) => {
              return {
                ...e,
                timeStamp: timeStamp,
                status: "2",
              };
            });
          }
          setIsLoading(true);
        }
      });
    }
  };

  const handleTipSection = () => {
    if (editReceiptData) {
      setTipState(!isTipOpen);
    } else {
      toast.error("To Open Tip Section, You have to enter total amount first", {
        theme: "colored",
        autoClose: 5000,
      });
    }
  };

  useEffect(() => {
    //Creating the objects
    if (isLoading && redirectFrom.from === "Scan") {
      callingSaveFunction();
    } else if (isLoading && redirectFrom.from === "Manual") {
      callingSaveFunction();
    } else if (isLoading && redirectFrom.from === "PdfScan") {
      callingSaveFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  //one function to hanndle all the three diff operation
  const callingSaveFunction = () => {
    const updatedObj = refineReceiptItem();
    console.log("get final receipt data result", updatedObj);
    receiptItemSave(updatedObj, imagePath)
      .then((res) => {
        if (res === "200") {
          setIsLoading(false);
          toast.success("Receipt created successfully!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: function () {
              navigate("/");
            },
          });
        }
      })
      .catch((err) => {
        setIsLoading(false);
        // setError(err)
      });
  };

  const refineReceiptItem = () => {
    const receiptObject = {
      ...editReceiptData,
      percent_amount:
        editReceiptData?.percent_amount == "0.00"
          ? ""
          : editReceiptData?.percent_amount === ""
          ? ""
          : `${editReceiptData?.percent_amount}%`,

      tax_amount_two:
        editReceiptData?.tax_amount_two === ""
          ? ""
          : `$${editReceiptData?.tax_amount_two}`,
      tax_amount_one:
        editReceiptData?.tax_amount_one === ""
          ? ""
          : `$${editReceiptData?.tax_amount_one}`,
      tip_amount:
        editReceiptData?.tip_amount === ""
          ? ""
          : `$${editReceiptData?.tip_amount}`,
    };
    return receiptObject;
  };

  const deleteImage = () => {
    //condtion for delteing form firebase
    if (
      editReceiptData.receiptImage !== "" &&
      editReceiptData.receiptImage !== undefined
    ) {
      Swal.fire({
        title: "Are you sure you want to delete the receipt image or PDF?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6c757d",
        customClass: ".sweet-alert button",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let key = editReceiptData.id;
          if (key) {
            let timeStamp = currentUser.payload.timeStamp;
            deleteImageFile(key, timeStamp).then((res) => {
              if (res === "200") {
                //updateing the store
                setSelectedFile(undefined);
                toast.success("Receipt image or PDF deleted successfully!", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // dispatch(receiptItemFetchRequest(timeStamp))/
                setIsImageDeleted(true);
              }
            });
          }
          //else default case
          else {
            toast.success("Receipt image or PDF deleted successfully!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });

            // dispatch(receiptItemFetchRequest(timeStamp))
            setIsImageDeleted(true);
          }
        }
      });
    }
    //Case for firebaseUrl
    else if (
      editReceiptData.firebaseUrl !== "" &&
      editReceiptData.firebaseUrl !== undefined
    ) {
      Swal.fire({
        title: "Are you sure you want to delete the receipt image or PDF?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6c757d",
        customClass: ".sweet-alert button",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let key = editReceiptData.id;
          //Case for firebase realtime db
          if (key) {
            let timeStamp = currentUser.payload.timeStamp;
            deletePdfFile(key, timeStamp).then((res) => {
              if (res === "200") {
                toast.success("Receipt image or PDF deleted successfully!", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
                // dispatch(receiptItemFetchRequest(timeStamp))
                setIsImageDeleted(true);
              }
            });
          }
          //else if file in local
          else {
            toast.success("Receipt image or PDF deleted successfully!", {
              position: "bottom-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            // dispatch(receiptItemFetchRequest(timeStamp))
            setIsImageDeleted(true);
          }
        }
      });
    }
    //Case fro local image
    else if (preview !== undefined && selectedFile !== undefined) {
      Swal.fire({
        title: "Are you sure you want to delete the receipt image or PDF?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#6c757d",
        customClass: ".sweet-alert button",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          setIsImageDeleted(true);
        }
      });
    } else {
      toast.warn("No receipt image or PDF to delete!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  //useffect for reflect changes
  useEffect(() => {
    if (isImageDeleted) {
      editReceiptData.firebaseUrl = "";
      editReceiptData.receiptImage = "";
      imageSlide[currentIndex].firebaseUrl = "";
      imageSlide[currentIndex].receiptImage = "";
      setSelectedFile(undefined);
      setPreview(undefined);
      setselectedPdf(undefined);
      setIsImageDeleted(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImageDeleted]);
  // console.log(editReceiptData, 'EGHEG')
  // console.log('preview', preview)
  //arrange elemnt acordingly
  // const moveElement = (array, from, to) => {
  //   const copy = [...array]
  //   const valueToMove = copy.splice(from, 1)[0]
  //   copy.splice(to, 0, valueToMove)
  //   return copy
  // }

  // setting the barcode value
  // setting the barcode value
  const getBarcodeValue = (val) => {
    setBarcodeValue(val);

    const barcode_formate_value = "CODE128";
    setEditReceiptData({
      ...editReceiptData,
      barcode_formate: barcode_formate_value,
      barCode: val,
    });
  };
  const removeBarcode = () => {
    setBarcodeValue("");
    setEditReceiptData({
      ...editReceiptData,
      barcode_formate: "",
      barCode: "",
    });
  };
  //If clicked on Image contaoiner
  const ImageTouch = useCallback(() => {
    let image = [];
    if (
      editReceiptData.receiptImage !== "" &&
      editReceiptData.receiptImage !== undefined
    ) {
      image.push(editReceiptData.receiptImage);
      setCurrentImage(image);
      setImageView(true);
    } else if (preview !== undefined) {
      image.push(preview);
      setCurrentImage(image);
      setImageView(true);
    } else if (
      editReceiptData.firebaseUrl !== "" &&
      editReceiptData.firebaseUrl !== undefined
    ) {
      //Saving the file to local storage to persist
      dispatch(editReceiptItemPersistRequest(editReceiptData));
      navigate("/homepage/pdf-view", {
        state: { pdfUrl: editReceiptData.firebaseUrl },
      });
    } else if (
      editReceiptData.firebaseUrl === "" ||
      editReceiptData.receiptImage === ""
    ) {
      handleOpenFileInput();
    }
  }, [editReceiptData, navigate, preview, dispatch]);

  //Redirect to image full screen view
  const getImageInfo = useCallback(() => {
    let image = [];
    if (
      editReceiptData.receiptImage !== "" &&
      editReceiptData.receiptImage !== undefined
    ) {
      image.push(editReceiptData.receiptImage);
      setCurrentImage(image);
      setImageView(true);
    } else if (preview !== undefined) {
      image.push(preview);
      setCurrentImage(image);
      setImageView(true);
    } else if (
      editReceiptData.firebaseUrl !== "" &&
      editReceiptData.firebaseUrl !== undefined
    ) {
      //Saving the file to local storage to persist
      dispatch(editReceiptItemPersistRequest(editReceiptData));
      //redirecting to pdf view
      navigate("/homepage/pdf-view", {
        state: { pdfUrl: editReceiptData.firebaseUrl },
      });
    } else {
      toast.warn("No receipt image or PDF to view!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [editReceiptData, navigate, preview, dispatch]);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setImageView(false);
  };

  // const images = [editReceiptData.receiptImage, preview]
  const downloadReciept = () => {
    const imageUrl = editReceiptData.receiptImage;
    const pdf = editReceiptData.firebaseUrl;

    if (imageUrl) {
      window.open(imageUrl, "_blank");
      fetch(imageUrl, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${editReceiptData.merchant}.jpg`); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((err) => {
          // console.log(err)
        });

      // saveAs(imageUrl, 'image.jpg') // Put your image url here.
    } else if (pdf) {
      window.open(pdf, "_blank");
      fetch(pdf, {
        method: "GET",
        headers: {},
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const url = window.URL.createObjectURL(new Blob([buffer]));

            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${editReceiptData.merchant}.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();
          });
        })
        .catch((err) => {
          // console.log(err)
        });

      // saveAs(imageUrl, 'image.jpg') // Put your image url here.
    } else {
      toast.warn("No receipt image or PDF to download!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const formatTax = (number) => {
    let format_number = number.replace(/,/g, "");
    const value = new Intl.NumberFormat("en-US", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(format_number);
    var number1 = value.replace(/,/g, "");
    // console.log(number1, 'num')
    if (isNaN(number1)) {
      setFormError((preValue) => {
        return {
          totalAmountErr: "Please provide a valid format!",
        };
      });
      setIsFromError(true);
      return "";
    } else {
      return value;
    }
  };
  //Cancel user actions
  const onCancel = () => {
    navigate("../");
    // setIsCancel(true);
  };
  return (
    <>
      {imageView && (
        <ImageViewer
          className="EditReceipt__imageView"
          src={currentImage}
          currentIndex={currentIndex}
          onClose={closeImageViewer}
          // disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,4,2,10)",
          }}
          closeOnClickOutside={false}
        />
      )}

      {loadingState ? <Loader /> : ""}
      {isLoading ? <Loader /> : ""}
      <BarcodeScanner
        show={barcodeModel}
        data={editReceiptData.barCode}
        onPress={getBarcodeValue}
        onDelete={removeBarcode}
        onHide={() => setBarcodeModel(false)}
      />
      <div className="EditReceipt">
        <div className="EditReceipt__title--container ">
          <div className="d-flex">
            <h1 className="EditReceipt__title--text">{titleSelected}</h1>
            <CloseButton />
          </div>
        </div>
        <div className="EditReceipt__wrapper ">
          <div className="row EditReceipt__small-screen-margin ">
            <div className="col-md-8 spacing ">
              <div className="EditReceipt__card--container row ">
                <div className="EditReceipt__card--contents col-lg-7">
                  <div className="EditReceipt__card--items">
                    <label className="EditReceipt__card--head--text">
                      Merchant Name
                    </label>
                    <input
                      onChange={(e) => addValues(e)}
                      name="merchant"
                      defaultValue={
                        editReceiptData.merchant ? editReceiptData.merchant : ""
                      }
                      className="EditReceipt__card--text-small"
                      placeholder="Enter merchant name"
                    />
                  </div>
                  <div className="EditReceipt__err-msg">
                    {isFormError ? formError.merchantErr : ""}
                  </div>
                  <div className="EditReceipt__card--items">
                    <label className="EditReceipt__card--head--text">
                      Total
                    </label>
                    <div
                      className="d-flex"
                      style={{ borderBottom: "1px solid #a3a3a3" }}
                    >
                      <span
                        className="EditReceipt__card--text-small"
                        style={{ border: "none" }}
                      >
                        {!isNaN(editReceiptData.totalAmount) ||
                        editReceiptData.totalAmount !== ""
                          ? editReceiptData.currencySymbol
                          : ""}
                      </span>
                      <input
                        style={{ border: "none" }}
                        name="totalAmount"
                        type="text"
                        onBlur={(e) => {
                          e.target.value = formatTax(e.target.value);
                        }}
                        onChange={(e) => addValues(e)}
                        defaultValue={
                          editReceiptData.totalAmount ? totalAmount : ""
                        }
                        className="EditReceipt__card--text-small"
                        placeholder="Enter the total amount"
                      />
                    </div>
                  </div>
                  <div className="EditReceipt__err-msg">
                    {isFormError ? formError.totalAmountErr : ""}
                  </div>
                  <div className="EditReceipt__card--items">
                    <label className="EditReceipt__card--head--text">
                      Payment Method
                    </label>
                    <div className="selectParent">
                      <SelectDropPayment
                        defaultValue={editReceiptData.payment}
                        getPayment={getPayment}
                      />
                    </div>
                  </div>
                  <div className="EditReceipt__err-msg">
                    {isFormError ? formError.paymentErr : ""}
                  </div>
                </div>

                <div className="EditReceipt__card--contents col-lg-5 ">
                  <div className="EditReceipt__card--items">
                    <label className="EditReceipt__card--head--text">
                      Date
                    </label>
                    <div className="d-flex EditReceipt__card--date">
                      <DatePicker
                        ref={dateRef}
                        dateFormat="MMM d, yyyy"
                        excludeTimes={true}
                        selected={
                          editReceiptData?.date
                            ? new Date(editReceiptData.date)
                            : new Date()
                        }
                        className="EditReceipt__card-rectdatePic"
                        onChange={(date) => addDate(date)}
                      />
                      <span
                        className="datePic"
                        onClick={() => handleClickDatepickerIcon()}
                      ></span>
                    </div>
                  </div>
                  <div className="EditReceipt__err-msg">
                    {isFormError ? formError.dateErr : ""}
                  </div>

                  <div className="EditReceipt__card--items">
                    <label className="EditReceipt__card--head--text">
                      Currency
                    </label>
                    <div className="selectParent">
                      <SelectDrop
                        defaultValue={editReceiptData.currency}
                        getCurrency={getCurrency}
                        options={CurrencyList}
                      />
                    </div>
                  </div>
                  <div className="EditReceipt__err-msg">
                    {isFormError ? formError.currencyErr : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4  ">
              <div className="EditReceipt__QrTitle--container">
                <div
                  className="text-center barcode"
                  style={{ backgroundColor: "white" }}
                >
                  {editReceiptData.barCode && (
                    <Barcode value={editReceiptData.barCode.toString()} />
                    // ) : (
                    //   <h2 className="EditReceipt__QrCode--title">
                    //     QR CODE (not exists)
                    //   </h2>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* closed the dividation for mobile view */}
          <div className="row">
            <div className="col-md-8  rightSpacing ">
              <div className="EditReceipt__card--middle--container EditReceipt__small-screen-margin">
                <div>
                  {!editReceiptData.tax_amount_one && (
                    <div className="EditReceipt__middle--column d-flex">
                      <label
                        className="EditReceipt__middel--text text-center"
                        onClick={() => {
                          setTaxState(!isTaxOpen);
                        }}
                      >
                        Add taxes
                        <KeyboardArrowDownOutlined className="EditReceipt__middel--dropdown" />
                      </label>
                    </div>
                  )}
                  {isTaxOpen || editReceiptData.tax_amount_one ? (
                    <AddTax
                      onPress={getTaxValue}
                      editReceiptData={editReceiptData}
                    />
                  ) : (
                    ""
                  )}
                  {console.log(
                    editReceiptData.tax_amount_one,
                    "editReceiptData--"
                  )}
                </div>
                <div>
                  {!editReceiptData.tip_amount && (
                    <div className="EditReceipt__middle--column d-flex">
                      <label
                        className="EditReceipt__middel--text"
                        onClick={handleTipSection}
                      >
                        Add a tip
                        <KeyboardArrowDownOutlined className="EditReceipt__middel--dropdown" />
                      </label>
                    </div>
                  )}
                  {isTipOpen || editReceiptData.tip_amount ? (
                    <AddTip
                      onChange={getTipValue}
                      editReceiptData={editReceiptData}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {!editReceiptData.notification_date && (
                    <div className="EditReceipt__middle--column d-flex">
                      <label
                        className="EditReceipt__middel--text"
                        onClick={() => {
                          setReturnState(!isReturnOpen);
                        }}
                      >
                        Add return information
                        <KeyboardArrowDownOutlined className="EditReceipt__middel--dropdown" />
                      </label>
                    </div>
                  )}
                  {isReturnOpen || editReceiptData.notification_date ? (
                    <ReturnPolicy
                      onChange={getReturnValue}
                      alertDefaultValue={editReceiptData.alert_title}
                      returnDefaultValue={editReceiptData.return_policy}
                      editReceiptData={editReceiptData}
                      source={'create'}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className="EditReceipt__middle--column "
                  onClick={() => setBarcodeModel(true)}
                >
                  <label className="EditReceipt__middel--text--barcode">
                    {barcodeValue
                      ? "Edit receipt barcode"
                      : "Add the receipt barcode"}
                  </label>
                  <div className="EditReceipt__middle--circle">
                    <img
                      style={{
                        display: "inline-block",
                        verticalAlign: "center",
                        marginLeft: "3px",
                      }}
                      src={BarcodeArrow}
                      alt="not found"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className={
                editReceiptData.barCode === ""
                  ? "col-md-4 topImage"
                  : " col-md-4 underBarcode "
              }
            >
              <div className="ImageFix">
                {imageLoader ? (
                  <>
                    <div className=" m-10 text-center">
                      <div className="d-inline">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        ></div>
                        <br />
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="EditReceipt__imageslide" onClick={ImageTouch}>
                    <ImageView data={imageSlide} />
                  </div>
                )}
              </div>

              <div className="EditReceipt__Image--button--container">
                <div className="EditReceipt__Image-eye" onClick={getImageInfo}>
                  <img
                    src={EyeIcon}
                    className="EditReceipt__icon__button"
                    alt="not found"
                  />
                </div>

                <div className="EditReceipt__Image--edit">
                  <input
                    ref={inputRef}
                    type="file"
                    id="myfile"
                    name="myfile"
                    accept="application/pdf, image/*"
                    style={{ display: "none" }}
                    onChange={(e) => setImageProperty(e)}
                  />

                  <img
                    onClick={handleOpenFileInput}
                    src={EditDoc}
                    className="EditReceipt__icon__button"
                    alt="not found"
                  />
                </div>

                <div
                  className="EditReceipt__Image--edit"
                  onClick={downloadReciept}
                >
                  <img
                    src={EditIcon}
                    className="EditReceipt__icon__button"
                    alt="not found"
                  />
                </div>
                <div
                  className="EditReceipt__Image--delete"
                  onClick={(e) => deleteImage()}
                >
                  <img
                    src={TrashIcon}
                    className="EditReceipt__icon__button"
                    alt="not found"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="EditReceipt__card--bootom-contents  col-md-8">
            <div className=" EditReceipt__card--bootom--padding">
              <div className="EditReceipt__card--items">
                <label className="EditReceipt__card--head--text">Folders</label>
                <div className="selectParent">
                  <FolderDrop
                    defaultValue={editReceiptData.folder_array}
                    onFolderChange={getFolderName}
                  />
                </div>
              </div>
              <div className="EditReceipt__card--items">
                <label className="EditReceipt__card--head--text">
                  Category
                </label>
                <div className="selectParent">
                  <SelectDropCategory
                    defaultValue={editReceiptData.category}
                    onChange={getCategory}
                    options={CategoryList}
                  />
                </div>
              </div>
              <div className="EditReceipt__card--items">
                <label className="EditReceipt__card--head--text">URL</label>
                <input
                  // style={{ border: 'none' }}
                  name="receipt_url"
                  type="text"
                  onChange={(e) => addValues(e)}
                  defaultValue={
                    editReceiptData.receipt_url
                      ? editReceiptData.receipt_url
                      : ""
                  }
                  className="EditReceipt__card--notes"
                  placeholder="Add a URL"
                />
              </div>
              <div
                className="EditReceipt__card--items"
                style={{ height: "100%" }}
              >
                <label className="EditReceipt__card--head--text">Notes</label>
                <textarea
                  defaultValue={editReceiptData.note}
                  style={{ outline: "none" }}
                  name="note"
                  onChange={(e) => addValues(e)}
                  className="EditReceipt__card--notes"
                  placeholder="Add a note"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <ListFooterSave
        buttonStatus={buttonStatus}
        onSave={saveItem}
        userView={userView}
        error={error}
        onCancel={onCancel}
      />
    </>
  );
}
