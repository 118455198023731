import React, { useMemo, useState, useEffect } from 'react'
import Select, { components } from 'react-select'

export default function SelectDropCategory(props) {
  const [selectedValue, setSelectedValue] = useState('')
  const Options = useMemo(() => {
    if (props.options) {
      return props.options.map((data) => ({
        label: data.label,
        options: data.options.map((opt) => ({
          label: opt.label,
          value: opt.label,
        })),
      }))
    }
    return []
  }, [props.options])

  const { Option } = components
  const IconOption = (props) => <Option {...props}></Option>
  const customStyles = {
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',

      color: state.isSelected ? 'blue' : 'grey',
      padding: 20,
    }),
    control: () => ({}),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition, color: '#112153' }
    },
  }
  const getCategory = (val) => {
    let g = Options.filter((f) =>
      f.options.some((o) => val.value.includes(o.value)),
    )
    const category = g[0].label
    props.onChange({ category: category + ' - ' + val.value })
  }

  useEffect(() => {
    setSelectedValue(props.defaultValue)
  }, [props.defaultValue])
  return (
    <div>
      <Select
        styles={customStyles}
        className="EditReceipt__card--currency"
        value={
          selectedValue
            ? {
                label: selectedValue,
                value: selectedValue,
              }
            : ''
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          Option: IconOption,
        }}
        placeholder={'Search/Select'}
        onChange={(e) => getCategory(e)}
        options={Options}
      />
    </div>
  )
}
