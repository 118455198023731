import React, { useState, useMemo, useEffect } from "react";
import Searchbutton from "../SearchButton/Searchbutton";

import { useNavigate, useLocation } from "react-router-dom";

import "./selecticon.scss";
import { getFolderIconsSnapshot } from "../../Firebase/dbFolderItems";
import RecieptSaveLoader from "../Loader/RecieptImageLoader";
import CloseButtonSelectIcon from "../CloseButton/CloseButtonSelectIcon";

export default function SelectIcon() {
  const nav = useNavigate();
  const { state } = useLocation();
  const [redirectFrom] = useState(state);
  const [searchValue, setSearchValue] = useState("");
  const [iconView, setIconView] = useState("");
  const [isIcon, setisIcon] = useState(false);
  const getIcon = (e) => {
    localStorage.setItem("ICON", e.target.src);
    localStorage.setItem("IMAGENAME", e.target.title);
    nav("../edit-folder");
  };
  useEffect(() => {
    const dataOb = [];
    if (iconView === "") {
      getFolderIconsSnapshot()
        .then((res) => {
          if (res) {
            for (var key in res.payload) {
              dataOb.push({
                id: key,
                ...res.payload[key],
              });
            }
          }
          const sortData = dataOb.sort((a, b) => a.id - b.id);
          setIconView(sortData);
          setisIcon(true);
        })
        .catch(() => {
          setisIcon(true);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconView]);

  const iconsData = useMemo(() => {
    if (searchValue !== "") {
      return iconView.filter((value) =>
        value.title.toLowerCase().includes(searchValue)
      );
    } else {
      return iconView ?? [];
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, iconView]);

  const searchIcons = (userSearching) => {
    const inputValue = userSearching.toLowerCase().trim();
    setSearchValue(inputValue);
  };

  return (
    <>
      {!isIcon && <RecieptSaveLoader />}
      <div className="SelectIcon">
        <div className="SelectIcon__title--container">
          <div className="d-flex">
            <h1 className="SelectIcon__title--text">Select Icon</h1>
            <CloseButtonSelectIcon redirectFrom={redirectFrom.from} />
          </div>
        </div>
        <Searchbutton placeholder="Search" onInputChange={searchIcons} />
        <div className="SelectIcon__card ">
          <div className="row SelectIcon__items">
            {iconsData &&
              iconsData.map((item, i) => (
                <div key={i + 1} className="col-sm-3  col ">
                  <img
                    key={item.id}
                    onClick={(e) => getIcon(e)}
                    className="SelectIcon__icons"
                    src={item.image}
                    alt={item.title}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
