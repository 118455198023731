import React, { useState, useEffect } from 'react'

import Select from 'react-select'

export default function SelectDropPayment(props) {
  const [selectedValue, setSelectedValue] = useState('')
  const options = [
    {
      label: 'Payment',
      options: [
        { label: ' Cash', value: 'Cash' },
        { label: 'Interac', value: 'Interac' },
        { label: 'Mastercard ', value: 'Mastercard' },
        { label: 'Visa ', value: 'Visa' },
        { label: 'American Express ', value: 'American Express' },
        { label: 'Discover ', value: 'Discover' },
        { label: 'Gift Card', value: 'Gift Card' },
        { label: 'PayPal', value: 'PayPal' },
        { label: 'Bitcoin', value: 'Bitcoin' },
        { label: 'Other ', value: 'Other' },
      ],
    },
  ]
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: '1px dotted pink',
      color: state.isSelected ? 'blue' : 'grey',
      padding: 20,
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      //   width: 200,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1
      const transition = 'opacity 300ms'

      return { ...provided, opacity, transition, color: '#a112153' }
    },
  }
  const handleChange = (value) => {
    const selected = value.value
    setSelectedValue(selected)
    props.getPayment({ paymentMethod: selected })
  }
  useEffect(() => {
    setSelectedValue(props.defaultValue)
  }, [props.defaultValue])
  return (
    <div>
      <Select
        name="Payment Type"
        styles={customStyles}
        isMulti={false}
        value={
          selectedValue
            ? {
                label: selectedValue,
                value: selectedValue,
              }
            : ''
        }
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        className="EditReceipt__card--currency"
        // defaultValue={options[0]}
        placeholder={'Search/Select'}
        options={options}
        onChange={(value) => handleChange(value)}
      />
    </div>
  )
}
