import React, { useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { selectCurrentUser } from "../Redux/Auth/auth.selectors";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "../Components/Loader/RecieptImageLoader";
import { useDispatch } from "react-redux";
import { Document, Page, pdfjs } from "react-pdf";
import "./pdf.scss";
import { editReceiptItemPersistRequest } from "../Redux/EditRecieptData/editRecieptData.action";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import CloseButtonPdfView from "../Components/CloseButton/CloseButtonPdfView";
import CloseButton from "../Components/CloseButton/CloseButton";
export default function PdfView() {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const currentUser = useSelector(selectCurrentUser);
  const [loader, setloader] = useState(false);
  const { state } = useLocation();
  const [scale, setScale] = useState(1);
  const [redirectFrom] = useState(state);
  const [errorMessage, seterrorMessage] = useState("");
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const options = {
    cMapUrl: "cmaps/",
    cMapPacked: true,
    standardFontDataUrl: "standard_fonts/",
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  const zoomIn = useCallback(() => {
    setScale((prev) => prev + 0.2);
  }, []);

  const zoomOut = useCallback(() => {
    setScale((prev) => prev - 0.2);
  }, []);

  // if (!redirectFrom.url) return <></>;

  const ConnectToServer = () => {
    seterrorMessage("");
    const file = redirectFrom.pdfUrl;
    let email = currentUser.payload.email;
    if (file && email) {
      setloader(true);
      var formData = new FormData(); // Currently empty
      formData.append("email", email);
      formData.append("fileUrl", file);
      formData.append("type", 1);
      const berarer =
        "8e3riH7G4NEcxmBuzvq41qzMnqvzQZtINgTgH7IudfX63pKxstT32RaFP47KZgzqzTgmcqYFZQeoe31ZsoEafZ84RvkOMvaE3eJqAlv6JEWsiJu5BoGmw2qVAS6USgrtRblyotX1hzLAJNxgCAZsE4Gz2BY1CVh6mUCSCh0ydGLBpmuXp9CyBRoVEYBs3hxrc7pKjOHXU2Qn6ATS9YHCZD2uSTsnXm8PQSYCwLySv63LMeCWoCrpgTSodshbsf4rkthOv7s9wHvOfmpbhGB6clBC3VCCO9PTKytHfakjDOSdL1hxpIDSeUqIjXaKm6M13Uu96rDZTB08Fx6J3o7hwT5OLsnzXLya4mqv2RMk";

      axios({
        method: "post",
        // url: 'http://192.168.1.148:4000/importReceiptThroughPdf',
        url: "https://ereceiptforwarderapp.receiptmate.com/importReceiptThroughPdf",
        data: formData,
        headers: {
          Authorization: `Bearer ${berarer}`,
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (response) {
          if (response.status === 200) {
            // console.log("resp", response);
            setloader(false);
            // navigate("/")
            const {
              alert_title,
              barCode,
              barcode_formate,
              category,
              created,
              createdTimeStamp,
              currency,
              currencySymbol,
              date,
              firebaseUrl,
              folder_array,
              image_folder_array,
              key,
              merchant,
              note,
              notification_date,
              payment,
              percent_amount,
              return_policy,
              status,
              tax_amount_one,
              tax_amount_two,
              text_name_one,
              text_name_two,
              tip_amount,
              totalAmount,
              receipt_url,
            } = response.data.data;
            const data = {
              alert_title,
              barCode,
              barcode_formate,
              category,
              created,
              createdTimeStamp,
              currency,
              currencySymbol,
              date,
              firebaseUrl,
              folder_array,
              image_folder_array,
              key,
              merchant,
              note,
              notification_date,
              payment,
              percent_amount,
              receiptImage: "",
              return_policy,
              status,
              tax_amount_one,
              tax_amount_two,
              text_name_one,
              text_name_two,
              tip_amount,
              totalAmount,
              imagePath: "",
              receipt_url,
            };
            dispatch(editReceiptItemPersistRequest(data));
            navigate("../create-receipt", { state: { from: "PdfScan" } }); //edit-receipt page
          } else {
            seterrorMessage(response.data.message);
          }
        })
        .catch(function (error) {
          setloader(false);
          seterrorMessage(error.response.data.message);
          // console.log(error);
        });
    }
  };
  const redirectUser = () => {
    navigate("/");
  };

  return (
    <>
      {loader && <Loader />}
      <div className="Fixed">
        <div className="ReceiptFolder__title--container">
          <div className="d-flex">
            <CloseButtonPdfView />
          </div>
        </div>
      </div>
      <div className="PdfWrapper">
        <div className="Example__container__document">
          <Document
            file={redirectFrom.pdfUrl}
            options={options}
            loading={
              <>
                <div className="d-flex flex-column justify-content-center align-items-center LoadingCenter">
                  <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  <br />
                  <h6 className="h4 text-light">Processing...</h6>
                </div>
              </>
            }
            // renderMode='svg'
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page
              canvasBackground="transparent"
              renderAnnotationLayer={true}
              renderForms={true}
              renderTextLayer={false}
              scale={scale}
              pageNumber={pageNumber}
            />
          </Document>
        </div>
        {numPages && (
          <>
            <div className="PdfWrapper_bottom ">
              <div
                className={
                  redirectFrom.button
                    ? "PdfWrapper_buttons_expanded"
                    : "PdfWrapper_buttons_small"
                }
              >
                <span
                  style={{
                    color: "red",
                    marginRight: "20px",
                    fontSize: "18px",
                  }}
                >
                  {errorMessage ? errorMessage : ""}
                </span>

                {numPages > 1 && (
                  <>
                    <div className="PageNumber  mx-2">
                      Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                      {numPages || "--"}
                    </div>

                    <button
                      type="button"
                      className="btn btn-outline-secondary mx-2"
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      disabled={pageNumber <= 1}
                      onClick={previousPage}
                    >
                      Previous
                    </button>
                    <button
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      className="btn btn-outline-success  mx-2"
                      type="button"
                      disabled={pageNumber >= numPages}
                      onClick={nextPage}
                    >
                      Next
                    </button>
                  </>
                )}
                <button
                  className="btn btn-outline-secondary  mx-2"
                  onClick={zoomOut}
                  disabled={scale <= 0.6}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-zoom-out"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    />
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                    <path
                      fillRule="evenodd"
                      d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </button>
                <button
                  className="btn btn-outline-success  mx-2"
                  onClick={zoomIn}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-zoom-in"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
                    />
                    <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z" />
                    <path
                      fillRule="evenodd"
                      d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
                    />
                  </svg>
                </button>
                {redirectFrom.button && (
                  <>
                    <button
                      type="button"
                      className="btn btn-outline-secondary  mx-2"
                      onClick={redirectUser}
                    >
                      Cancel
                    </button>
                    <button
                      style={{ background: "#00b050", color: "#ffff" }}
                      type="button"
                      className="btn btn-outline-success  mx-2"
                      onClick={() => {
                        ConnectToServer();
                      }}
                    >
                      Scan
                    </button>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
