import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";
export const createSpreadSheet = (data) => {
  console.log(data, "export------");
  let tempData = [];
  data.map((item, i) => {
    
    return tempData.push({
      Merchant: item.merchant,
      Date: item.date,
      Total: item?.totalAmount
        ? Number(
            item.totalAmount.includes("$")
              ? item.totalAmount.replace("$", "")
              : item.totalAmount
          )
        : "",
      Currency: item.currency,
      Payment: item.payment,
      "Tax Name #1": item.text_name_one,
      "Tax Amount #1": item?.tax_amount_one
        ? Number(
            item.tax_amount_one.includes("$")
              ? item.tax_amount_one.replace("$", "")
              : item.tax_amount_one
          )
        : "",
      "Tax Name #2": item.text_name_two,
      "Tax Amount #2": item?.tax_amount_two
        ? Number(
            item.tax_amount_two.includes("$")
              ? item.tax_amount_two.replace("$", "")
              : item.tax_amount_two
          )
        : "",
      "Tip Amount": item?.tip_amount
        ? Number(
            item.tip_amount.includes("$")
              ? item.tip_amount.replace("$", "")
              : item.tip_amount
          )
        : "",

      Percentage :
        item?.tip_amount == ""
          ? ""
          : item?.percent_amount
          ? `${
              item?.percent_amount.includes("%")
                ? item?.percent_amount
                : `${item?.percent_amount}%`
            }`
          : "",
      // editReceiptData?.percent_amount == "0.00"
      //       ? ""
      //       : `${editReceiptData?.percent_amount}%`,
      "Return Policy": item.return_policy,
      Alert: item.alert_title,
      "Notification Date ": item.notification_date,
      Folder: item?.folder_array && JSON.stringify(
         item.folder_array.map((data) => data.label)
      )
        .replace(/[`~!@#$%^&*()_|+\-=?;:'".<>\{\}\[\]\\\/]/gi, " ")
        .trim(),
      Category: item.category,
      URL: item.receipt_url,
      Note: item.note,
      Image: item.receiptImage,
      PDF: item.firebaseUrl,
    });
  });


  console.log(tempData,'tempData--')
  
  const day = new Date();
  var date =
    day.getFullYear() + "/" + (day.getMonth() + 1) + "/" + day.getDate();
  let formatedDate = moment(date).format("MMM DD,YYYY");
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(tempData);
  XLSX.utils.book_append_sheet(wb, ws, "ClientCopy");
  XLSX.writeFileXLSX(wb, `ReceiptMate Receipt Export ${formatedDate}.xlsx`);
  // window.location = 'mailto:"clientname"';
};
