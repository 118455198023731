import { receiptItemTypes } from "./receiptIem.types"
const initialState = {
    error: null,
    loading: false,
    data: [],
}
const saveReceiptReducer = (state = initialState, action) => {
    switch (action.type) {
        case receiptItemTypes.RECEIPT_ITEM_SAVE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                data: [],
            }
        case receiptItemTypes.RECEIPT_ITEM_SAVE_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }

        case receiptItemTypes.RECEIPT_ITEM_SAVE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                data: [],
            }
        case receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }

        case receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }

        default:
            return state
    }

}

export default saveReceiptReducer