import { createSelector } from "reselect";

const selectUser = (state) => state.auth;

export const selectCurrentUser = createSelector(
    [selectUser],
    auth => auth.currentUser
);

export const selectAuthLoadingState = createSelector(
    [selectUser],
    auth => auth.loading
);
export const selectAuthLoadingStateSignUp = createSelector(
    [selectUser],
    auth => auth.loadingSignUp
);


export const selectAuthErrors = createSelector(
    [selectUser],
    auth => auth.error
);
export const checkUserToken = createSelector(
    [selectUser],
    auth => auth.user
);
