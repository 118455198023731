import React from "react";
import "./accountinfo.scss";
import { FasterLoading } from "../../Utils/Constant";
import { CancelAnyTime } from "../../Utils/Constant";
import { ImprovedAppdesign } from "../../Utils/Constant";
import { Premium } from "../../Utils/Constant";
import { ChatBox } from "../../Utils/Constant";
import CloseButton from "../CloseButton/CloseButton";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

export default function AccountInfo() {
  const currentUser = useSelector(selectCurrentUser);
  console.log(currentUser, "usererer");
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <div className="AcoountInfo">
      <div className="AcoountInfo__title--container">
        <div className="d-flex">
          {currentUser.payload.account_type === 1 && (
            <h1 className="AcoountInfo__title--text">Premium Free Trial</h1>
          )}
          {currentUser.payload.account_type === 2 && (
            <h1 className="AcoountInfo__title--text">
              Activate Premium Account
            </h1>
          )}
          {currentUser.payload.account_type === 3 && (
            <h1 className="AcoountInfo__title--text">Premium Account</h1>
          )}
          <CloseButton />
        </div>
        {currentUser.payload.account_type === 1 && (
          <p className="AcoountInfo__description--text">
            You are benefiting the free trial premium account!<br/> The first 3
            months are on us, then use the basic version or upgrade to premium!<br/>
            Your free trial premium account will expire on {currentUser.payload.subscription.end_date}.
     
          </p>
        )}
        {currentUser.payload.account_type === 2 && (
          <p className="AcoountInfo__description--text">
            Support ReceiptMate! Activate your premium account from the
            ReceiptMate mobile application, in the Apple App store and Google
            Play store!
            {/* <strong style={{ textDecoration: "underline" }}>
              {" "}
              $2.49 CAD{" "}
            </strong>{" "}
            ;
            <strong style={{ textDecoration: "underline" }}>
              {" "}
              1.99 USD/month
            </strong> */}
          </p>
        )}
        {currentUser.payload.account_type === 3 && (
          <p className="AcoountInfo__description--text">
            Thank you for using ReceiptMate Premium!
          </p>
        )}
      </div>
      <div className=" AcoountInfo__card">
        <div className=" AcoountInfo__title ">
          <h1 className="AcoountInfo__card--title">Features</h1>
        </div>

        <div className="AcoountInfo__info--container">
          <div className="d-flex AcoountInfo__padding ">
            <img
              className="AcoountInfo__logo"
              src={Premium}
              alt="not found"
            ></img>
            <label className="AcoountInfo__label--text">
              Ad-free experience
            </label>
          </div>
          <div className="d-flex AcoountInfo__padding">
            <img
              className="AcoountInfo__logo"
              src={FasterLoading}
              alt="not found"
            ></img>
            <label className="AcoountInfo__label--text">
              Multi receipt picture feature
            </label>
          </div>
          <div className="d-flex AcoountInfo__padding">
            <img
              className="AcoountInfo__logo"
              src={ChatBox}
              alt="not found"
            ></img>
            <label className="AcoountInfo__label--text">Priority support</label>
          </div>{" "}
          <div className="d-flex AcoountInfo__padding">
            <img
              className="AcoountInfo__logo"
              src={ImprovedAppdesign}
              alt="not found"
            ></img>
            <label className="AcoountInfo__label--text">
              Improved app design
            </label>
          </div>
          <div className="d-flex AcoountInfo__padding ">
            <img
              className="AcoountInfo__logo"
              src={CancelAnyTime}
              alt="not found"
            ></img>
            <label className="AcoountInfo__label--text">
              Cancel anytime without fees or penalties
            </label>
          </div>
        </div>
      </div>
      {currentUser.payload.account_type === 3 && (
        <div className="AcoountInfo__update--container d-flex flex-column align-items-left">
          <h1 className="AcoountInfo__card--title--bottom">
            To cancel your subscription, go into your smartphone device Settings
            &gt; Account &gt; Subscriptions.
          </h1>
          {/* <label  className="AcoountInfo__label--text">'</label> */}
          {/* <button className="AcoountInfo__cancel--btn">
          Cancel Premium Account
        </button> */}
        </div>
      )}
    </div>
  );
}
