import React, { useState, useEffect } from "react";
import Select from "react-select";
import moment from "moment";

const customStyles = {
  menu: (provided) => ({ ...provided, width: "100%", zIndex: 9999 }),
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted pink",
    color: state.isSelected ? "blue" : "grey",
    paddingBottom: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
    // width: 200,
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
export default function ReturnPolicy({
  onChange,
  editReceiptData,
  alertDefaultValue,
  returnDefaultValue,
  source,
}) {
  const [returnDate, setReturnDate] = useState("");
  const [differnce, setDifference] = useState("");
  const [alertDate, setAlertDate] = useState("");
  const [options, setOptions] = useState([]);
  const [message, setMessage] = useState("No alert set");
  const [alertOptions, setalertOptions] = useState([]);
  useEffect(() => {
    var days = 0;
    switch (returnDate) {
      case "No returns allowed":
        days = 0;
        break;
      case "7 days":
        days = 7;
        break;
      case "15 days":
        days = 15;
        break;
      case "30 days":
        days = 30;
        break;
      case "2 months":
        days = 60;
        break;
      case "3 months":
        days = 90;
        break;
      case "6 months":
        days = 180;
        break;
      case "1 year":
        days = 365;
        break;
      case "2 years":
        days = 730;
        break;
      case "3 years":
        days = 1095;
        break;
      default:
        break;
    }
    setDifference(days);
    let alertOptions = [
      {
        options: [
          { value: "no alert", label: "no alert", days: "0" },
          { value: "1 day before", label: "1 day before", days: "1" },
          { value: "2 days before", label: "2 days before", days: "2" },
          { value: "3 days before", label: "3 days before", days: "3" },
          { value: "5 days before", label: "5 days before", days: "5" },
        ],
      },
    ];
    let newAlert = [];
    // console.log(days, 'dy')
    alertOptions.map((item) =>
      item.options.map((i) => (days > i.days ? newAlert.push(i) : ""))
    );
    setalertOptions(newAlert);
  }, [returnDate]);

  useEffect(() => {
    const dateOfReceipt = moment(editReceiptData.date, "MMM DD, YYYY");
    let date = new Date(dateOfReceipt);
    const alert_day = parseInt(alertDate);
    let diff = differnce - alert_day;
    date.setDate(date.getDate() + diff);
    let formatedDate = moment(date).format("MMM DD, YYYY");
    if (!returnDate && alertDate) {
      setMessage("Please select return date!");
    } else if (returnDate && alertDate) {
      setMessage("");
      const dateObtain = formatedDate;
      //settting the a final value
      onChange({
        return_policy: returnDate,
        alert_title: alertDate,
        notification_date: dateObtain,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alertDate]);
  useEffect(() => {
    const dateOfReceipt = moment(editReceiptData.date, "MMM DD, YYYY");
    const today = moment();
    const days = today.diff(dateOfReceipt, "days");
    let options = [
      {
        options: [
          { value: "No returns allowed", label: "No returns allowed" },
          { value: "7 days", label: "7 days" },
          { value: "15 days", label: "15 days" },
          { value: "30 days", label: "30 days" },
          { value: "2 months", label: "2 months" },
          { value: "3 months", label: "3 months" },
          { value: "6 months", label: "6 months" },
          { value: "1 year", label: "1 year" },
          { value: "2 years", label: "2 years" },
          { value: "3 years", label: "3 years" },
        ],
      },
    ];
    if (days > 6 && days <= 14) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "15 days", label: "15 days" },
            { value: "30 days", label: "30 days" },
            { value: "2 months", label: "2 months" },
            { value: "3 months", label: "3 months" },
            { value: "6 months", label: "6 months" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 14 && days <= 29) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "30 days", label: "30 days" },
            { value: "2 months", label: "2 months" },
            { value: "3 months", label: "3 months" },
            { value: "6 months", label: "6 months" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 29 && days <= 59) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "2 months", label: "2 months" },
            { value: "3 months", label: "3 months" },
            { value: "6 months", label: "6 months" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 59 && days <= 89) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "3 months", label: "3 months" },
            { value: "6 months", label: "6 months" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 89 && days <= 179) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "6 months", label: "6 months" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 179 && days <= 364) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "1 year", label: "1 year" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 364 && days <= 729) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "2 years", label: "2 years" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 729 && days <= 1094) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
            { value: "3 years", label: "3 years" },
          ],
        },
      ];
    } else if (days > 1094) {
      options = [
        {
          options: [
            { value: "No returns allowed", label: "No returns allowed" },
          ],
        },
      ];
    }
    setOptions(options);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReceiptData.date]);

  useEffect(() => {
    if (returnDefaultValue !== "") {
      setReturnDate(returnDefaultValue);
    }
  }, []);

  const PlaceHolderLogic = (returnValue, returnDefaultValue) => {
    if (returnValue == "No returns allowed") {
      return "No returns allowed";
    } else if (returnDefaultValue == "" && source === "edit") {
      return "No returns allowed";
    } else {
      return "Add return policy";
    }
  };

  const ReturnInformation = (returnValue, returnDefaultValue) => {
    console.log(
      returnValue,
      returnDefaultValue,
      "returnValue, returnDefaultValue"
    );
    // returnDate !== "No returns allowed" &&
    // returnDefaultValue !== "No returns allowed"
    //   ? { label: returnDefaultValue, value: returnDefaultValue }
    //   : ""
    if (returnDefaultValue === "") {
      return "";
    } else if (
      returnDate !== "No returns allowed" &&
      returnDefaultValue !== "No returns allowed"
    ) {
      return { label: returnDefaultValue, value: returnDefaultValue };
    } else {
      return "";
    }
  };
  const ReturnInformation2 = (returnValue, returnDefaultValue) => {
    console.log(
      returnValue,
      returnDefaultValue,
      "returnValue, returnDefaultValue"
    );
    // returnDate !== "No returns allowed" &&
    // returnDefaultValue !== "No returns allowed"
    //   ? { label: returnDefaultValue, value: returnDefaultValue }
    //   : ""
    if (alertDefaultValue === "") {
      return "";
    } else if (alertDate !== "no alert" && alertDefaultValue !== "no alert") {
      return { label: alertDefaultValue, value: alertDefaultValue };
    } else {
      return "";
    }
  };

  const PlaceHolderLogic2 = (returnValue, alertDefaultValue) => {
    if (returnValue == "no alert") {
      return "no alert";
    } else if (alertDefaultValue == "no alert") {
      return "no alert";
    } else {
      return "Add alert date";
    }
  };

  useEffect(() => {}, [editReceiptData.notification_date]);

  return (
    <div>
      <div>
        <div className="EditReceipt__card--container tip">
          <div className="row no-gutters">
            <div className="EditReceipt__card--contents col-lg-7">
              <div className="EditReceipt__card--items">
                <label className="EditReceipt__card--head--text">
                  Return Policy
                </label>
                {console.log(
                  returnDefaultValue,
                  alertDate,
                  "returnDate",
                  returnDate
                )}

                <Select
                  styles={customStyles}
                  isMulti={false}
                  autosize={true}
                  className="EditReceipt__card--currency"
                  value={ReturnInformation(returnDate, returnDefaultValue)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={PlaceHolderLogic(returnDate, returnDefaultValue)}
                  onChange={(value) => {
                    setReturnDate(value.value);
                    onChange({
                      return_policy: value.value,
                      alert_title: "",
                      notification_date: "",
                    });
                  }}
                  options={options}
                />
              </div>
              <div className="EditReceipt__card--items">
                <label className="EditReceipt__card--head--text">Alert</label>
                <Select
                  styles={customStyles}
                  isMulti={false}
                  autosize={true}
                  className="EditReceipt__card--currency"
                  // value={
                  //   alertDate !== "no alert" && alertDefaultValue !== "no alert"
                  //     ? { label: alertDefaultValue, value: alertDefaultValue }
                  //     : ""
                  // }
                  value={ReturnInformation2(returnDate, returnDefaultValue)}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  placeholder={
                    // alertDate == "no alert" ? "no alert" : "Add alert date"
                    PlaceHolderLogic2(alertDate, alertDefaultValue)
                  }
                  onChange={(value) => {
                    setAlertDate(value.value);
                    onChange({
                      return_policy: returnDate,
                      alert_title: alertDate,
                      notification_date: "",
                    });
                    console.log(alertDate, "value----------------");
                  }}
                  options={alertOptions}
                />
              </div>
            </div>
            <div
              className="EditReceipt__card--contents col-lg-5  "
              style={{ margin: "auto" }}
            >
              <div className="EditReceipt__card--items">
                <div className="center">
                  {editReceiptData.notification_date !== "" ? (
                    <>
                      Alert set for
                      <br />
                      {editReceiptData.notification_date}
                    </>
                  ) : (
                    <>{message}</>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
