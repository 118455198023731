import React from "react";
import "./closebutton.scss";
import { useNavigate } from "react-router-dom";

export default function CloseButtonSelectIcon(props) {
  const navigate = useNavigate();

  return (
    <div
      className="CloseButton close"
      onClick={() => navigate(`../${props.redirectFrom}`)}
    >
      <span
        style={{ border: "none", fontSize: "30px", paddingBottom: "7px" }}
        className="CloseButton__logo btn btn-outline-danger "
      >
        x
      </span>
    </div>
  );
}
