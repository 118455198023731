import React, { useMemo, useState } from "react";
import Slider from "react-slick";
import { getFolderItemsSnapshot } from "../../../Firebase/dbFolderItems";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "../../../Redux/Auth/auth.selectors";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

import { NavLink } from "react-router-dom";

import "./listnav.scss";
export default function TopNav({ onTouch }) {
  function SampleNextArrow(props) {
    const { onClick } = props;
    return (
      <div className="arrow next-arrow" onClick={onClick}>
        <ArrowForwardIos />
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
      <div className="arrow prev-arrow" onClick={onClick}>
        <ArrowBackIos />
      </div>
    );
  }

  const currentUser = useSelector(selectCurrentUser);
  const timeStamp = currentUser.payload.timeStamp;
  const [folderList, setfolderList] = useState([]);
  const [isFolder, setIsFolder] = useState(true);
  function compare(a, b) {
    if (a.folderName < b.folderName) {
      return -1;
    }
    if (a.folderName > b.folderName) {
      return 1;
    }
    return 0;
  }

  const folderArray = useMemo(() => {
    const tempData = [];
    if (isFolder) {
      getFolderItemsSnapshot(timeStamp).then((res) => {
        for (var key in res.payload) {
          tempData.push({
            id: key,
            ...res.payload[key],
          });
        }
        const data = tempData.sort(compare);
        let noneFolders = {
          id: "",
          folderName: "None",
          image: "",
          imageName: "",
          timeStamp: 1679032565455,
        };
        console.log("folder", data);
        setfolderList([...data, noneFolders]);
        setIsFolder(false);
      });
    }

    console.log(tempData, "tempData");
    return folderList ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFolder, folderList]);
  var settings = {
    infinite: false,
    speed: 500,
    dots: window.innerWidth > 600 ? true : false,
    arrows: folderList.length > 2 ? true : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024, // width to change options
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: folderList.length > 2 ? 3 : 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: folderList.length > 2 ? 2 : 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };
  const getFolderName = (fn) => {
    onTouch(fn);
  };
  return (
    <div className="ListNavWrapper">
      <Slider {...settings} className="FolderList">
        <div className="ListNavWrapper_margin float-left mx-auto">
          <NavLink
            onClick={() => getFolderName("ALL")}
            to="/homepage"
            className={(navData) =>
              navData.isActive
                ? "ListNavWrapper__list--text  hvr-underline-from-center"
                : "ListNavWrapper__list--text "
            }
          >
            All
          </NavLink>
        </div>
        {folderArray.length > 0 &&
          folderArray.map((item, i, arr) => (
            <div
              key={i + 1}
              className="ListNavWrapper_margin float-left d-flex mx-auto"
              onClick={() => getFolderName(item.folderName)}
            >
              <NavLink
                key={i + 3}
                to="/homepage"
                className={(navData) =>
                  navData.isActive
                    ? "d-flex mx-auto ListNavWrapper__list--text  hvr-underline-from-center "
                    : "d-flex ListNavWrapper__list--text"
                }
              >
                {item.folderName !== "None" && (
                  <img
                    key={i + 2}
                    className="ListNavWrapper__logo "
                    src={item.image}
                    alt=""
                  />
                )}
                {item.folderName}
              </NavLink>
            </div>
          ))}

        <div className="ListNavWrapper_margin float-left mx-auto">
          <NavLink to="edit-folder" className="ListNavWrapper__list--addFolder">
            Add folder
          </NavLink>
        </div>
      </Slider>
    </div>
  );
}
