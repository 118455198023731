import { authActionTypes } from "./auth.types"

const initialState = {
    currentUser: null,
    error: null,
    loading: false,
    user: null
}

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authActionTypes.EMAIL_SIGN_IN_START:
            return {
                ...state,
                loading: true,
                user: null,
                currentUser: null,
                error: null,
            }
        case authActionTypes.SIGN_UP_START:
            return {
                ...state,
                currentUser: null,
                user: null,
                loadingSignUp: true,
                error: null,
            }
        case authActionTypes.SIGN_UP_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loadingSignUp: false,
                error: null
            }
        case authActionTypes.SIGN_IN_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
                loading: false,
                error: null
            }
        case authActionTypes.SIGN_OUT_START:
            return {
                ...state,
                error: null,
            }
        case authActionTypes.SIGN_OUT_SUCCESS:
            return {
                ...state,
                user: null,
                currentUser: null,
                loading: false,
                error: null
            }
        case authActionTypes.SIGN_UP_FAILURE:
            return {
                ...state,
                error: action.payload,
                loadingSignUp: false,
            }
        case authActionTypes.SIGN_IN_FAILURE:
        case authActionTypes.SIGN_OUT_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        //Update the user data when user update it from edit profile 
        case authActionTypes.UPDATE_CURRENT_USER_DATA:
            return {
                ...state,
                currentUser: action.payload,
                error: null
            }

        default:
            return state
    }
}

export default authReducer
