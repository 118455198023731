import React, { useEffect, useState, useMemo } from "react";
import "./recieptlist.scss";
import Searchbutton from "../SearchButton/Searchbutton";
import moment from "moment";
import ListItemsHeader from "../ReceieptListContents/TableContents/ListItemsHeader";
import RecieptButton from "../ReceiptButton/RecieptButton";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/RecieptImageLoader";
import { receiptItemFetchRequest } from "../../Redux/RecieptListItem/receiptItem.action";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import { selectReceiptFetchData } from "../../Redux/RecieptListItem/receiptItemSelector";
import { useDispatch, useSelector } from "react-redux";
import NoReceipt from "../NoReceipt/NoReceipt";
import { ToastContainer } from "react-toastify";
import { selectLoadingState } from "../../Redux/RecieptListItem/receiptItemSelector";
import TopNav from "../ReceieptListContents/ListTopNav/SlideFolder";
import ListFooter from "../ReceieptListContents/ListFooter/ListFooter";
import { deleteRecieptItems } from "../../Firebase/dbReceiptItem";
import { createSpreadSheet } from "../../ExportToXls/createSpreadSheet";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { TicSelectedIcon, TicUnSelectedIcon } from "../../Utils/Constant";
import { editReceiptItemPersistRequest } from "../../Redux/EditRecieptData/editRecieptData.action";
import useWindowSize from "../../Hooks/useWidnowHeight";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

export default function ReceiptList() {
  //get window height
  const height = useWindowSize();
  let calculateheight = height.height - 230;
  console.log(height, "heiiii");
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [isCancelRequest, setisCancelRequest] = useState(false);
  const [buttonSelect, setbuttonSelect] = useState(true);
  const [isSelctedAll, setisSelctedAll] = useState(false);
  const [iconSelected, setIconSelected] = useState(false);
  const currentUser = useSelector(selectCurrentUser);
  const timeStamp = currentUser.payload.timeStamp;
  const isLoading = useSelector(selectLoadingState);
  const [searchResult, setSearchResult] = useState("");
  const [displayItem, setDisplayItem] = useState("");
  const [filtter, setFILTTER] = useState(false);
  const [search, setSearch] = useState(false);
  const [filter, setFilter] = useState("");
  const [buttonStatus, setbuttonStatus] = useState(false);
  const [isActionOpen, setisActionOpen] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector(selectReceiptFetchData);

  const location = useLocation();

  console.log(selected, "selectedselected");

  useMemo(() => {
    dispatch(receiptItemFetchRequest(timeStamp));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //Fetch user results
  const getUserResult = (input) => {
    setFILTTER(false);
    setSearch(true);
    const userTyped = input.toLowerCase().trim();
    setSearchResult(userTyped);
  };
  // 1. Make a shallow copy of the array
  useEffect(() => {
    ReactGA.pageview(location.pathname);
    const dataOb = [];
    if (data.payload) {
      for (var key in data.payload) {
        dataOb.push({
          id: key,
          ...data.payload[key],
        });
      }
      //old to latest
      const sorted_ele = dataOb.sort((a, b) =>
        new Date(a.date) > new Date(b.date) ? -1 : 1
      );
      console.log(dataOb, "data");
      // dataOb.reverse();

      setDisplayItem(sorted_ele);
    }
  }, [data]);

  const displayItmes = useMemo(() => {
    if (filtter) {
      console.log(filter, "filter");
      if (filter === "ALL") {
        return displayItem;
      } else if (filter === "None") {
        let res = displayItem?.filter(
          (item) => item?.folder_array == "" && item
        );
        return res;
      } else {
        const non_empty = displayItem.filter(
          (f) => f.folder_array !== "" && f.folder_array !== undefined
        );
        return non_empty.filter((f) =>
          f.folder_array.some((o) => o.label?.includes(filter))
        );
      }
    } else if (search) {
      return displayItem.filter((value) =>
        value.merchant.toLowerCase().includes(searchResult)
      );
    } else {
      return displayItem ?? [];
    }
  }, [filtter, search, filter, displayItem, searchResult]);

  console.log(displayItem, "displayItem");
  //fillter items according to the folder name
  const getItem = (filter) => {
    setFilter(filter);
    setFILTTER(true);
    setSearch(false);
  };
  //
  //User actions
  const selectAll = () => {
    if (buttonSelect) {
      setisSelctedAll(true);
      setbuttonSelect(false);
    } else if (selectAll) {
      setisSelctedAll(false);
      setbuttonSelect(true);
    }
  };
  const changeIcon = (index) => {
    const checked = selected.filter((id) => {
      return id === index;
    });
    if (checked.length > 0) {
      const new_arr = selected.filter((id) => id !== index);
      setSelected(new_arr);
      setbuttonSelect(true);
    } else {
      setSelected([...selected, index]);
    }
    setisActionOpen(true);
  };

  //this will help to check export state
  const redirectTo = (item) => {
    dispatch(editReceiptItemPersistRequest(item));
    navigate("../edit-receipt", { state: { from: "Home" } });
  };

  const cancelSelected = () => {
    setisActionOpen(false);
    if (selected.length > 0) {
      setisCancelRequest(true);
    }
  };

  const calculateTipAmount = (item) => {
    if (item.tip_amount == "$NaN") {
      return "";
    } else if (item.tip_amount) {
      return "Tip";
    } else {
      return "";
    }
  };
  //handle the select all operation and cancel opertion
  useEffect(() => {
    if (isSelctedAll && !buttonSelect) {
      const temp_selectd = [];
      displayItmes && displayItmes.map((item, i) => temp_selectd.push(item.id));
      setSelected(temp_selectd);
    } else if (!isSelctedAll && buttonSelect) {
      setSelected([]);
      setisCancelRequest(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelctedAll, buttonSelect, buttonStatus]);
  useEffect(() => {
    if (iconSelected && buttonStatus) {
      setbuttonSelect(false);
      setisSelctedAll(true);
      const temp_selectd = [];
      displayItmes && displayItmes.map((item, i) => temp_selectd.push(item.id));
      setSelected(temp_selectd);
    } else if (!iconSelected && !buttonStatus) {
      setbuttonSelect(true);
      // setisSelctedAll(false)
      setSelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonStatus, iconSelected]);

  useEffect(() => {
    if (isCancelRequest) {
      setSelected([]);
      setisCancelRequest(false);
    }
  }, [isCancelRequest]);

  //Export the data
  const exportToPdf = () => {
    if (selected.length > 0) {
      const exportList = displayItmes.filter((item) =>
        selected.includes(item.id)
      );
      console.log(exportList, "exportList");
      createSpreadSheet(exportList);
    } else {
      toast.warn("Nothing to export please select items!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleClick = () => {
    setIconSelected(!iconSelected);
    // setbuttonSelect(!buttonSelect)
    // setisSelctedAll(!isSelctedAll)
    setbuttonStatus(!buttonStatus);
    setisActionOpen(true);
  };

  //Delete the selected files
  const deletePopup = () => {
    if (selected.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "red",
        customClass: ".sweet-alert button",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          //call firebase delete operation
          deleteRecieptItems(selected, timeStamp).then((res) => {
            if (res === "200") {
              setisActionOpen(false);
              if (selected.length > 1) {
                toast.success("Receipts deleted successfully!", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  onClose: function () {
                    navigate("/");
                    dispatch(receiptItemFetchRequest(timeStamp));
                  },
                });
              } else {
                toast.success("Receipt deleted successfully!", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  onClose: function () {
                    // navigate('/');
                    dispatch(receiptItemFetchRequest(timeStamp));
                  },
                });
              }
            }
          });
        }
      });
    } else {
      toast.warn("Please select items to delete!", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <div className="container-fluid ">
        <div className="row ms-4 me-4">
          <div>
            {isLoading && <Loader />}
            {displayItem.length > 0 && (
              <>
                <Searchbutton
                  placeholder="Search receipts"
                  onInputChange={getUserResult}
                />
                <TopNav onTouch={getItem} />
              </>
            )}
            {displayItmes.length !== 0 ? (
              <>
                <div
                  className="HidenScroll"
                  style={{
                    overflowX: "auto",
                    paddingLeft: "20px",
                    // paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingRight: "20px",
                    // background:'red',
                    // zIndex: "999",
                    height: calculateheight,
                  }}
                >
                  <div className="fixTableHead">
                    <table className="table table-hover table-responsive table-custom">
                      <ListItemsHeader handleClick={handleClick} />

                      <tbody>
                        {displayItmes &&
                          displayItmes.map((item, i) => (
                            <tr key={i} className="ReceiptListHeader">
                              {console.log(
                                item.tip_amount,
                                "item.tip_amount--"
                              )}
                              <td
                                colSpan="1"
                                key={i + 1}
                                className="ReceiptListHeader__icon--uncheck"
                                style={{ cursor: "pointer" }}
                                onClick={() => changeIcon(item.id)}
                              >
                                {selected.includes(item.id) ? (
                                  <img
                                    className="ReceiptListHeader_checkbox"
                                    key={i + 2}
                                    src={TicSelectedIcon}
                                    alt="checked"
                                  />
                                ) : (
                                  <img
                                    className="ReceiptListHeader_checkbox"
                                    key={i + 3}
                                    src={TicUnSelectedIcon}
                                    alt="checked"
                                  />
                                )}
                              </td>
                              <td
                                colSpan="1"
                                key={i + 4}
                                className="ReceiptListHeader__List--text "
                                onClick={(e) => redirectTo(item)}
                              >
                                {item.date}
                              </td>
                              <td
                                colSpan="5"
                                key={i + 5}
                                className="ReceiptListHeader__List--text "
                                onClick={(e) => redirectTo(item)}
                              >
                                <span
                                  style={{
                                    textAlign: "left",
                                    padding: "0px !important",
                                  }}
                                >
                                  {item.merchant}
                                </span>
                              </td>
                              <td
                                colSpan="3"
                                key={i + 6}
                                className="ReceiptListHeader__List--text"
                                onClick={(e) => redirectTo(item)}
                              >
                                {item.currencySymbol}
                                {item.totalAmount}
                                <span style={{ marginLeft: "1px" }}>
                                  {" "}
                                  {item.currency}
                                </span>
                                <br />
                                <span>{item.payment}</span>
                              </td>
                              {item.text_name_one ? (
                                <td
                                  style={{ textAlign: "center" }}
                                  colSpan="2"
                                  key={i + 7}
                                  className="ReceiptListHeader__List--text"
                                  onClick={(e) => redirectTo(item)}
                                >
                                  {item.text_name_one}
                                  <span style={{ margin: "2px" }}>
                                    {item.text_name_two ? (
                                      <span>&middot;</span>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  <span style={{ margin: "2px" }}>
                                    {item.text_name_two
                                      ? item.text_name_two
                                      : ""}
                                  </span>

                                  <br />
                                  <span style={{ textAlign: "left" }}>
                                    {calculateTipAmount(item)}
                                  </span>
                                </td>
                              ) : (
                                <td
                                  style={{ textAlign: "center" }}
                                  colSpan="2"
                                  key={i + 7}
                                  className="ReceiptListHeader__List--text"
                                  onClick={(e) => redirectTo(item)}
                                >
                                  <span style={{ textAlign: "left" }}>
                                    {calculateTipAmount(item)}
                                  </span>
                                </td>
                              )}

                              <td
                                style={{ textAlign: "center" }}
                                colSpan="3"
                                key={i + 8}
                                className="ReceiptListHeader__List--text "
                                onClick={(e) => redirectTo(item)}
                              >
                                {item.category &&
                                  item.category.split("-")[0] + "-"}
                                <br />

                                {item.category && item.category.split("-")[1]}
                              </td>
                              <td
                                colSpan="3"
                                key={i + 9}
                                className="ReceiptListHeader__List--text"
                                onClick={(e) => redirectTo(item)}
                              >
                                <div className="d-flex">
                                  <span className="mx-2 my-auto">
                                    {item.folder_array &&
                                    item.folder_array.length > 2 ? (
                                      <strong>
                                        {"+"}
                                        {item.folder_array.length - 2}
                                      </strong>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                  {item.folder_array &&
                                    item.folder_array
                                      .map((image, i) => (
                                        <img
                                          key={i + 10}
                                          className="ReceiptListHeader__Folder--icon "
                                          src={image.image}
                                          alt=""
                                        />
                                      ))
                                      .slice(0, 2)}
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>

                  <ToastContainer
                    position="bottom-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                  />
                </div>
                {!isActionOpen && <RecieptButton />}
              </>
            ) : (
              <NoReceipt displayItem={displayItem} />
            )}
          </div>
        </div>
      </div>
      {isActionOpen && (
        <ListFooter
          buttonSelect={buttonSelect}
          onCancel={cancelSelected}
          onDelete={deletePopup}
          onExport={exportToPdf}
          onSelect={selectAll}
        />
      )}
    </>
  );
}
