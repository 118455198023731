import { userUpdateTypes } from './UpdateUser.type'
const initialState = {
    error: null,
    loading: false,
    currentUser: null,
}
const updateUserReducer = (state = initialState, action) => {
    switch (action.type) {
        case userUpdateTypes.UPDATE_PROFILE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case userUpdateTypes.UPDATE_PROFILE_REQUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload,
                error: null,
            }
        case userUpdateTypes.UPDATE_PROFILE_REQUEST_FAILURE:
            return {
                ...state,
                error: action.payload,
                loading: false,
            }
        case userUpdateTypes.USER_IMAGE_UPLOAD_REQUEST:
            return {
                ...state,
                loading: false,
                error: null,
            }


        default:
            return state
    }
}
export default updateUserReducer
