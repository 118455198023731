import { createSelector } from "reselect";
const selectState = (state) => state.saveReceiptReducer;
export const selectLoadingState = createSelector(
    [selectState],
    saveReceiptReducer => saveReceiptReducer.loading
);
export const selectReceiptPushErrors = createSelector(
    [selectState],
    saveReceiptReducer => saveReceiptReducer.error
);
export const selectReceiptFetchData = createSelector(
    [selectState],
    saveReceiptReducer => saveReceiptReducer.data
);

