import { takeLatest, all, put, call } from "redux-saga/effects";


import { authActionTypes } from "./auth.types";
import { auth, createUserProfileDocument, getCurrentUser, getSnapshotFromDb } from "../../Firebase/FirebaseUtils";
import {
    signInFailure, signInSuccess, signOutFailure, signOutSuccess, signUpFailure, signUpSuccess,
} from "./auth.actions";
import { VerifyErroCode } from "./errorCode";

export function* getSnapshotFromUserAuth(userAuth, additionalData) {
    try {
        yield call(createUserProfileDocument, userAuth, additionalData);
        let createdAt = userAuth._delegate.metadata.createdAt;
    } catch (e) {
        yield put(signUpFailure(e.message));
    }
}


export function* signInWithEmail({ payload: { email, password } }) {
    try {
        const { user } = yield auth.signInWithEmailAndPassword(email, password);
        //get user info and save 
        let createdAt = user._delegate.metadata.createdAt;
        const userRef = yield call(getSnapshotFromDb, createdAt);
        if (userRef.user !== undefined) {
            yield put(signInSuccess({ payload: userRef.user }));
        }
        else {
            yield put(signInFailure(" Sorry User data not found"));
        }

    } catch (e) {
        console.log(e.code, "login error");
        // VerifyErroCode(e.code);
    
        const errMsg = VerifyErroCode(e.code);
     
        yield put(signInFailure(errMsg));
    }
}




export function* checkIfUserIsAuthenticated() {
    try {
        const userAuth = yield getCurrentUser();
        if (!userAuth) return;
        yield getSnapshotFromUserAuth(userAuth);
    } catch (e) {
        yield put(signInFailure(e.message));
    }
}

export function* signOut() {
    try {
        yield auth.signOut();
        yield put(signOutSuccess());
    } catch (e) {
        yield put(signOutFailure(e.message));
    }
}

export function* signUp({ payload: { first_name, last_name, email, password } }) {
    const additionalData = {
        "first_name": first_name,
        "last_name": last_name
    }
    try {
        const { user } = yield auth.createUserWithEmailAndPassword(email, password)
        yield getSnapshotFromUserAuth(user, additionalData);
        if (user._delegate.accessToken !== undefined) {
            // console.log(user._delegate.accessToken, "user._delegate.accessToken ");
            yield put(signUpSuccess({ payload: user.uid }))
        }
    } catch (e) {

        // const msg = e.code.toString().substring(5, e.code.toString().length )
        const errMsg = VerifyErroCode(e.code);
      
        yield put(signUpFailure(errMsg));
    }
}
export function* onCheckUserSession() {
    yield takeLatest(authActionTypes.CHECK_USER_SESSION, checkIfUserIsAuthenticated);
}



export function* onEmailSignInStart() {
    yield takeLatest(authActionTypes.EMAIL_SIGN_IN_START, signInWithEmail);
}



export function* onSignOutStart() {
    yield takeLatest(authActionTypes.SIGN_OUT_START, signOut);
}

export function* onSignUpStart() {
    yield takeLatest(authActionTypes.SIGN_UP_START, signUp);
}
export function* authSagas() {
    yield all([
        call(onCheckUserSession),
        call(onEmailSignInStart),
        call(onSignOutStart),
        call(onSignUpStart),


    ]);
}