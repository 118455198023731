import React, { useState, useEffect, useRef } from "react";
import CloseButton from "../CloseButton/CloseButton";
import { useDispatch, useSelector } from "react-redux";
import { signOutStart } from "../../Redux/Auth/auth.actions";
import { firebase } from "../../Firebase/FirebaseUtils";
import { ProfilePicEdit, DefaultProfilePic } from "../../Utils/Constant";
import { capitalizeWord } from "../SignUp/SignUp";
import Loader from "../Loader/LoginLoader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Formik, Form, Field, ErrorMessage } from "formik";

import "./editprofile.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { updateUserPassword } from "../../Firebase/FirebaseUtils";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import {
  selectUpdateLoadingState,
  selectUpdateErrors,
} from "../../Redux/UpdateUserData/updateUserSelector";
import { updateProfileStart } from "../../Redux/UpdateUserData/updateUser.actions";
import Swal from "sweetalert2";
import ReactGA from "react-ga";

export default function EditProfile() {
  const navigate = useNavigate(); //Use navigate for the redirect
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const updateError = useSelector(selectUpdateErrors);
  const isLoading = useSelector(selectUpdateLoadingState);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const [preview, setPreview] = useState();
  const [showProgress, setshowProgress] = useState("");
  const [first_name, setFirstName] = useState(currentUser.payload.first_name);
  const [last_name, setLastName] = useState(currentUser.payload.last_name);
  const [isErrormsg, setErrormsg] = useState(false);
  const [messsage, setMessage] = useState("");
  const dispatch = useDispatch();
  //Call the Logout function
  const handleLogout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "We are sad to see you logging out :(",
      icon: "warning",
      showCancelButton: true,

      confirmButtonColor: "#d33",
      cancelButtonColor: "#6c757d",
      customClass: ".sweet-alert button",
      confirmButtonText: "Log Out",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(signOutStart());
        navigate("/");
      }
    });
  };
  //Copy the text to clipbpard
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(str) {
    // textAreaRef.current
    const el = document.createElement("textarea");
    el.value = str;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast.success("Email address copied successfully!", {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setTimeout(() => {
      setCopySuccess("");
    }, 2000);

    // console.log('Copy', textAreaRef.current?.value.length)
    return;
  } //Close the function

  //Here I will change the Image state
  const setImageProperty = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }
    setSelectedFile(e.target.files[0]);
  };
  const uploadImage = () => {
    //functinality for uploading the image on cloud db and get link
    return new Promise((resolve, reject) => {
      var storage = firebase.storage();
      var uploadTask = storage
        .ref(`/images/${selectedFile.name}`)
        .put(selectedFile);
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setshowProgress(progress);
          //save the image link in db
        },
        function (error) {
          // console.log(error)
          reject(error);
        },
        async function () {
          const url = await storage
            .ref(`/images/${selectedFile.name}`)
            .getDownloadURL();
          if (url) {
            // We 'awaited' the imgURL, now resolve this Promise
            resolve(url);
          }
          setSelectedFile("");
          setshowProgress("");
        }
      );
    });
  };

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    // create a preview as a side effect, whenever selected file is changed
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);
    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile, currentUser, showProgress]);
  return (
    <>
      <div className="EditProfile">
        <div className="EditProfile__title--container">
          <div className="d-flex">
            <h1 className="EditProfile__title--text">Edit Profile</h1>
            <CloseButton />
          </div>
        </div>
        <div className="row EditProfile__card">
          <div className=" EditProfile__left col-sm-12 col-md-auto col-lg-4">
            <h1
              className="EditProfile__card--title "
              style={{ textAlign: "center" }}
            >
              Basic Info
            </h1>
            <div className="EditProfile__image--container ">
              {currentUser.payload.image !== "" ? (
                <img
                  className="EditProfile__card--image"
                  src={preview ? preview : currentUser.payload.image}
                  alt="not found"
                />
              ) : (
                <img
                  className="EditProfile__card--image"
                  src={preview ? preview : DefaultProfilePic}
                  alt="not found"
                />
              )}

              <label className="EditProfile__filebuttonshow">
                <img
                  className="EditProfile__filebutton"
                  src={ProfilePicEdit}
                  alt="not found"
                />
                <span>
                  <input
                    type="file"
                    id="myfile"
                    name="myfile"
                    accept="image/*"
                    onChange={(e) => setImageProperty(e)}
                  />
                </span>
              </label>
            </div>
            {showProgress && (
              <div class="progress">
                <div
                  className="progress-bar progress-bar-striped"
                  role="progressbar"
                  style={{ width: showProgress }}
                  aria-valuenow="10"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            )}
          </div>
          {/* Formik values for the validation to check user name last name */}
          <div className="col-sm-12 col-md-auto col-lg-8 EditProfile__right">
            <Formik
              initialValues={{
                first_name: currentUser.payload.first_name,
                last_name: currentUser.payload.last_name,
                update: "",
              }}
              validate={(values) => {
                const errors = {};
                setErrormsg(false);
                // console.log('first_name', first_name)
                if (!values.first_name) {
                  errors.first_name = "Please provide first name!";
                }
                // else if (!/^[A-Z][a-z_0-9-\s]{3,15}$/i.test(values.first_name)) {
                //   errors.first_name = 'Invalid name format!'
                // }
                else {
                  const capitlize_name = capitalizeWord(values.first_name);
                  values.first_name = capitlize_name;
                }
                if (!values.last_name) {
                  errors.last_name = "Please provide last name!";
                  // } else if (!/^[\w]{3,15}$/i.test(values.last_name)) {
                  //   errors.last_name = 'Invalid name format!'
                } else {
                  const capitlize_name = capitalizeWord(values.last_name);
                  values.last_name = capitlize_name;
                }

                return errors;
              }}
              //Update user name $function

              onSubmit={(values, { setSubmitting }) => {
                const { first_name, last_name } = values;
                setSubmitting(false);

                if (
                  first_name === currentUser.payload.first_name &&
                  last_name === currentUser.payload.last_name &&
                  preview === undefined
                ) {
                  toast.warn("Nothing to update!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                }
                //Else if user wants to change image
                else if (preview !== undefined) {
                  uploadImage()
                    .then((imageUrl) => {
                      const image = imageUrl;
                      const { timeStamp } = currentUser.payload;
                      dispatch(
                        updateProfileStart({
                          first_name,
                          last_name,
                          timeStamp,
                          image,
                        })
                      );
                      if (updateError === null) {
                        setPreview(undefined);
                        toast.success("Profile data updated successfully!", {
                          position: "bottom-right",
                          autoClose: 2000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        });
                        setErrormsg(false);
                        //  Set update name in placeholder
                        setFirstName(currentUser.payload.first_name);
                        setLastName(currentUser.payload.last_name);
                      } else {
                        setErrormsg(true);
                      }
                    })
                    .catch((error) => {
                      // console.log(error)
                    });
                } else {
                  const { image, timeStamp } = currentUser.payload;
                  // console.log('time', timeStamp)
                  dispatch(
                    updateProfileStart({
                      first_name,
                      last_name,
                      timeStamp,
                      image,
                    })
                  );

                  if (updateError === null) {
                    toast.success("Profile data updated successfully!", {
                      position: "bottom-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                    });
                    setErrormsg(false);
                    //  Set update name in placeholder
                    setFirstName(currentUser.payload.first_name);
                    setLastName(currentUser.payload.last_name);
                  } else {
                    setErrormsg(true);
                  }
                }
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="EditProfile__update--container ">
                    <button
                      className="EditProfile__update--btn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isLoading ? <Loader /> : "Update"}
                    </button>
                    <ToastContainer
                      position="bottom-right"
                      autoClose={2000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                    />
                  </div>

                  <div className="EditProfile__info--container  ">
                    <label className="EditProfile__label--text">
                      First Name
                    </label>
                    <Field
                      className="EditProfile__label--useinfo"
                      placeholder="Enter first name"
                      defaultValue={first_name}
                      name="first_name"
                    />
                    <ErrorMessage
                      style={{ margin: "0px" }}
                      className="EditProfile__err-msg"
                      name="first_name"
                      component="div"
                    />
                    <label className="EditProfile__label--text">
                      Last Name
                    </label>
                    <Field
                      className="EditProfile__label--useinfo"
                      placeholder="Enter last name"
                      defaultValue={first_name}
                      name="last_name"
                    />

                    <ErrorMessage
                      style={{ margin: "0px" }}
                      className="EditProfile__err-msg"
                      name="last_name"
                      component="div"
                    />
                  </div>
                </Form>
              )}
            </Formik>
            {isErrormsg ? updateError : ""}
          </div>
        </div>

        {/* //Middle card  */}
        <div className="row EditProfile__card--middle">
          <div className="col-md-6">
          <div className=" EditProfile__left">
            <h1 className="EditProfile__card--title">Contact Info</h1>
            <div className="EditProfile__info--container--middle">
              <label className="EditProfile__label--text">Email Address</label>
              <input
                className="EditProfile__label--useinfo"
                value={currentUser.payload.email}
              />
            </div>
            </div>
          </div>
          <div className="col-md-6">
          <div className="EditProfile__right--middle">
            <div className="EditProfile__right-card--contents">
              <div className="EditProfile__spacing">
                <label className="EditProfile__label--text--middle">
                  eReceipt Forwarder Email
                </label>
                <label
                  className="EditProfile__label--text--middle"
                  ref={textAreaRef}
                >
                  receipts@receiptmate.com
                </label>
                <button
                  className="EditProfile__copy--btn"
                  onClick={() => copyToClipboard("receipts@receiptmate.com")}
                >
                  Copy
                </button>
                {copySuccess}
              </div>
            </div>
          </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-6 col-md-12 EditProfile__card--bottom ">
            <div className=" EditProfile__left ">
              <h1 className="EditProfile__card--title">Change Password</h1>
              <>
                <Formik
                  initialValues={{
                    oldPassword: "",
                    password: "",
                    confirmPassword: "",
                  }}
                  validate={(values) => {
                    const errors = {};
                    setMessage("");
                    if (!values.oldPassword) {
                      errors.oldPassword = "Please provide password!";
                    }
                    if (!values.password) {
                      errors.password = "Please provide new password!";
                    } else if (values.password < 6) {
                      errors.password = "Password should be minimum 6 length!";
                    }
                    if (!values.confirmPassword) {
                      errors.confirmPassword = "Please confirm new password!";
                    } else if (values.password !== values.confirmPassword) {
                      errors.confirmPassword = "Password should be the same!";
                    }
                    return errors;
                  }}
                  onSubmit={(values, { setSubmitting, resetForm }) => {
                    const { oldPassword, password } = values;
                    setSubmitting(false);
                    updateUserPassword(currentUser.payload.email, oldPassword)
                      .then(function (user) {
                        if (user) {
                          firebase.auth().currentUser.updatePassword(password);
                          toast.success(
                            "Your password is changed succesfully!",
                            {
                              position: "bottom-right",
                              autoClose: 2000,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                            }
                          );
                        }
                      })
                      .catch(function (err) {
                        // console.log(err.message)
                        setMessage(err.message);
                      });
                    resetForm();
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div className="EditProfile__info--container--bottom">
                        <label className="EditProfile__label--text--bottom">
                          Enter Current Password
                        </label>
                        <Field
                          type="password"
                          name="oldPassword"
                          className="EditProfile__label--useinfo--bottom"
                          placeholder="Enter current password"
                        />
                        <ErrorMessage
                          className="EditProfile__err-msg"
                          name="oldPassword"
                          component="div"
                        />
                        <label className="EditProfile__label--text--bottom">
                          Choose New Password
                        </label>
                        <Field
                          type="password"
                          name="password"
                          className="EditProfile__label--useinfo--bottom"
                          placeholder="Choose new password"
                        />
                        <ErrorMessage
                          className="EditProfile__err-msg"
                          name="password"
                          component="div"
                        />
                        <label className="EditProfile__label--text--bottom">
                          Confirm New Password
                        </label>
                        <Field
                          type="password"
                          name="confirmPassword"
                          className="EditProfile__label--useinfo--bottom"
                          placeholder="Confirm new password"
                        />
                        <ErrorMessage
                          className="EditProfile__err-msg"
                          name="confirmPassword"
                          component="div"
                        />
                        <span
                          className="EditProfile__err-msg "
                          style={{ marginTop: "0px" }}
                        >
                          {messsage}
                        </span>
                        <button
                          className="EditProfile__copy--btn"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Change Password
                        </button>
                        <ToastContainer
                          position="bottom-right"
                          autoClose={2000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          rtl={false}
                          pauseOnFocusLoss
                          draggable
                          pauseOnHover
                        />
                      </div>
                    </Form>
                  )}
                </Formik>
              </>
            </div>
          </div>
          <div className="col-sm-12  col-lg-6 col-md-12 EditProfile__right--bottom ">
            <h1 className="EditProfile__card--title text-center">Log Out</h1>
            <button className="EditProfile__btn--logout" onClick={handleLogout}>
              Log Out
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
