import React, { useEffect } from 'react'
// import { Backlogo } from '../../Utils/Constant'

import Navigation from '../Components/SideBarNavigation/Navigation'
import { MenuOpen } from '@material-ui/icons'

import { Outlet } from 'react-router-dom'
import './home.scss'
import { useState } from 'react'
export default function HomePage(props) {
  const [toggle, setToggle] = useState(false)
  const [lastScrollY, setLastScrollY] = useState(0)
  const [show, setShow] = useState(true)
  const handleToggle = (payload) => {
    setToggle(payload)
  }
  const handleoutSide = () => {
    if (toggle) {
      setToggle(false)
    }
  }
  const controlNavbar = () => {
    if (typeof window !== 'undefined') {
      if (window.scrollY > lastScrollY) {
        // if scroll down hide the navbar
        setToggle(false)
      }

      // remember current page location to use in the next move
      setLastScrollY(window.scrollY)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar)

      // cleanup function
      return () => {
        window.removeEventListener('scroll', controlNavbar)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastScrollY])

  return (
    <div>
      <div className={toggle ? 'nav-wrap show-me' : 'nav-wrap hide'}>
        <Navigation
          HandleToggle={(payload) => handleToggle(payload)}
          Toggle={toggle}
        />
      </div>

      <div className="content-wrap" onClick={handleoutSide}>
        <Outlet />
      </div>
    </div>
  )
}
