import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./loginform.scss";
import Swal from "sweetalert2";
import AppleIcon from "@material-ui/icons/Apple";
import { Backlogo, AndroidLogo } from "../Utils/Constant";
import { emailSignInStart } from "../Redux/Auth/auth.actions";
import { useDispatch, useSelector } from "react-redux";
import SignUp from "../Components/SignUp/SignUp";
import { ToastContainer, toast } from "react-toastify";

import LoginLoader from "../Components/Loader/Loader";
import {
  selectAuthLoadingState,
  selectAuthErrors,
  selectCurrentUser,
} from "../Redux/Auth/auth.selectors";
import ForgetPasswordModel from "../Components/ForgetPasword/ForgetPassword";
import TermsConditon from "../Components/TermsCondition/TermsConditon";
import PrivacyPolicy from "../Components/TermsCondition/PrivacyPolicy";

export default function LoginForm(props) {
  const [modalShow, setModalShow] = useState(false);
  const [termShow, settermShow] = useState(false);
  const [privacyShow, setprivacyShow] = useState(false);
  const [error_message, setError] = useState(false);
  //Pop up for the forget password
  const [forgetPopUp, setForgetPopUp] = useState(false);
  const authError = useSelector(selectAuthErrors);
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoadingState);

  return (
    <>
      <SignUp show={modalShow} onHide={() => setModalShow(false)} />
      <ForgetPasswordModel
        show={forgetPopUp}
        hidePopUp={() => setForgetPopUp(false)}
      />
      <TermsConditon show={termShow} onHide={() => settermShow(false)} />
      <PrivacyPolicy show={privacyShow} onHide={() => setprivacyShow(false)} />

      <div className="LoginForm row h-100">
        <div className="col-md-6 col-md-auto">
          <div className="LoginForm--content-left">
            <div className="LoginForm--logo">
              <img className="LoginForm--img" src={Backlogo} alt="not found" />
            </div>
            <h1 className="LoginForm--title">
              Organizing receipts has <br /> never been this easy
            </h1>
            <p className="LoginForm--description">Get the app.</p>
            <div className="page-content page-container " id="page-content">
              <div className="LoginForm__mobile--btn">
                <div className="mt-2">
                  <div className="LoginForm__mobile--btn-small">
                    <a
                      href="https://apps.apple.com/ca/app/receiptmate/id1529746707"
                      target="_blank"
                      className="btn btn-dark btn-icon-text LoginForm--download-btn "
                      rel="noreferrer"
                    >
                      <AppleIcon className=" LoginForm--icon--apple" />

                      <span className="d-inline-block text-right">
                        <small className="font-weight-light d-block">
                          Download on the
                        </small>
                        <span className="LoginForm--btn_txt_large">
                          App Store
                        </span>
                      </span>
                    </a>

                    <a
                      href="https://play.google.com/store/apps/details?id=com.receiptmate.app"
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-dark btn-icon-text mx-10 LoginForm--download-btn"
                    >
                      <img
                        className="LoginForm--icon-play-store "
                        src={AndroidLogo}
                        alt="not found"
                      />
                      <span className="d-inline-block text-left">
                        <small className="font-weight-light d-block">
                          GET IT ON
                        </small>
                        <span className="LoginForm--btn_txt_large">
                          Google Play
                        </span>
                      </span>
                    </a>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-6 col-md-auto">
          <div className="LoginForm--right">
            <Formik
              initialValues={{ email: "", password: "" }}
              validate={(values) => {
                setError(false);
                const errors = {};
                if (!values.email) {
                  errors.email = "Please enter email address";
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = "Invalid email address!";
                }
                if (!values.password) {
                  errors.password = "Please enter password";
                }
                return errors;
              }}
              //Submit function
              onSubmit={(values, { setSubmitting }) => {
                const { email, password } = values;
                dispatch(emailSignInStart({ email, password }));
                setSubmitting(false);
                if (props.timeStamp) {
                  setTimeout(() => {
                    Swal.fire({
                      title: "You are Logged in sucessfully.",
                      icon: "success",
                      showConfirmButton: true,
                    });
                  }, 400);
                  setError(false);
                } else {
                  setError(true);
                }
              }}
            >
              {({ isSubmitting }) => (
                <div className="LoginForm--FormContents">
                  <div className="LoginForm__inner--Contents">
                    <Form>
                      <Field
                        className="LoginForm--username"
                        type="email"
                        name="email"
                        placeholder="Email address"
                      />
                      <ErrorMessage
                        className="LoginForm__err-msg"
                        name="email"
                        component="div"
                      />

                      <Field
                        className="LoginForm--user-password"
                        type="password"
                        name="password"
                        placeholder="Password"
                      />
                      <ErrorMessage
                        className="LoginForm__err-msg"
                        name="password"
                        component="div"
                      />
                      <span className="LoginForm__err-msg">
                        {error_message ? authError : ""}
                      </span>
                      <button
                        className="LoginForm--submit-btn"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isLoading ? <LoginLoader /> : "Log In"}
                      </button>
                      <label
                        className="LoginForm--forget-link"
                        onClick={() => setForgetPopUp(true)}
                      >
                        Forgot password?
                      </label>
                      <hr className="LoginForm--seprator" />
                    </Form>
                    <div className="LoginForm__create-account-container">
                      <button
                        className="LoginForm--sign-up"
                        onClick={() => setModalShow(true)}
                      >
                        Create new account
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
            <div className="LoginForm__terms-link-container">
              <span className="LoginForm--copyright--text">
                Copyright © ReceiptMate inc. All rights reserved.
              </span>
              <span className="d-flex LoginForm--bottom-container">
                <a
                  href="https://www.receiptmate.com/"
                  target="_blank"
                  className="LoginForm--bottom-link"
                >
                  Home page
                </a>
                <span style={{ color: "#A3A3A3" }}>|</span>
                <label
                  className="LoginForm--bottom-link"
                  onClick={() => settermShow(true)}
                >
                  Terms of use
                </label>
                <span style={{ color: "#A3A3A3" }}>|</span>
                <label
                  className="LoginForm--bottom-link"
                  onClick={() => setprivacyShow(true)}
                >
                  Privacy policy
                </label>
              </span>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}
