import React, { useState } from "react";

function AddTax({ onPress, editReceiptData }) {
  const [errorTaxOne, setErrorTaxOne] = useState("");
  const [errorTaxTwo, setErrorTaxTwo] = useState("");
  const getTax = (e) => {
    setErrorTaxOne("");
    setErrorTaxTwo("");
    const { name, value } = e.target;
    onPress(name, value);
  };

  //formating number😎

  const formatTax = (number) => {
    const value = new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
    let number1 = value.replace(/,/g, "");
    if (isNaN(number1)) {
      setErrorTaxOne((prev) => {
        return {
          error: "Please enter valid input!",
        };
      });
      return "";
    } else {
      if (errorTaxOne !== number) {
        return value;
      } else {
        return "";
      }
    }
  };
  const formatTaxTwo = (number) => {
    const value = new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
    let number1 = value.replace(/,/g, "");
    if (isNaN(number1)) {
      setErrorTaxTwo((prev) => {
        return {
          error: "Please enter valid input!",
        };
      });
      return "";
    } else {
      if (errorTaxOne !== number) {
        return value;
      } else {
        return "";
      }
    }
  };

  return (
    <div className="EditReceipt__card--container tip">
      <div className="EditReceipt__card--contents ">
        <div className="row">
          <div className="EditReceipt__card--items col-lg-7 col-md-12 col-sm-12">
            <label className="EditReceipt__card--head--text">Tax Name #1</label>
            <input
              onChange={(e) => getTax(e)}
              name="text_name_one"
              defaultValue={editReceiptData.text_name_one}
              className="EditReceipt__card--text-small"
              placeholder="Add tax #1"
            />
          </div>
          <div className="EditReceipt__card--items  col-lg-5 col-md-12 col-sm-12">
            <label className="EditReceipt__card--head--text">
              Tax Amount #1
            </label>

            <div className="EditReceipt__card--date d-flex">
              <span>
                {!isNaN(editReceiptData.tax_amount_one) ||
                editReceiptData.tax_amount_one !== ""
                  ? editReceiptData.currencySymbol
                  : ""}
              </span>
              <input
                step="0.01"
                style={{ border: "none", width: "90%" }}
                name="tax_amount_one"
                onBlur={(e) => {
                  e.target.value = formatTax(e.target.value);
                }}
                onChange={getTax}
                defaultValue={
                  editReceiptData.tax_amount_one
                    ? editReceiptData.tax_amount_one
                        .toString()
                        .replace(/[^.\d]/g, "")
                    : ""
                }
                type="text"
                placeholder="Add amount"
              />
            </div>
            <span className="EditReceipt__err-msg-tax">
              {errorTaxOne.error ? errorTaxOne.error : ""}
            </span>
          </div>
        </div>

        <div className="EditReceipt__card--contents">
          <div className="row ">
            <div className="EditReceipt__card--items  col-lg-7 col-md-12  col-sm-12">
              <label className="EditReceipt__card--head--text">
                Tax Name #2
              </label>

              <input
                onChange={(e) => getTax(e)}
                name="text_name_two"
                defaultValue={editReceiptData.text_name_two}
                className="EditReceipt__card--text-small"
                placeholder="Add tax #2"
              />
            </div>

            <div className="EditReceipt__card--items  col-lg-5 col-md-12  col-sm-12">
              <label className="EditReceipt__card--head--text">
                Tax Amount #2
              </label>
              <div className="EditReceipt__card--date d-flex">
                {
                  <span>
                    {!isNaN(editReceiptData.tax_amount_two) ||
                    editReceiptData.tax_amount_two !== ""
                      ? editReceiptData.currencySymbol
                      : ""}
                  </span>
                }
                <input
                  style={{ border: "none", width: "90%" }}
                  name="tax_amount_two"
                  step="0.01"
                  onBlur={(e) => {
                    e.target.value = formatTaxTwo(e.target.value);
                  }}
                  defaultValue={
                    editReceiptData.tax_amount_two
                      ? editReceiptData.tax_amount_two
                          .toString()
                          .replace(/[^.\d]/g, "")
                      : ""
                  }
                  onChange={getTax}
                  type="text"
                  placeholder="Add amount"
                />
              </div>
              {
                <span className="EditReceipt__err-msg-tax">
                  {errorTaxTwo.error ? errorTaxTwo.error : ""}
                </span>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AddTax;
