import {
    getSnapshotFromDb, updateUserName,
} from "../../Firebase/FirebaseUtils";
import { takeLatest, all, put, call } from "redux-saga/effects";
import { userUpdateTypes } from "./UpdateUser.type";
import { updateCurrentUser } from "../Auth/auth.actions";
import { updateProfileSuccess, updateProfileFaliure } from "./updateUser.actions";

export function* onUpdateProfileStart() {
    yield takeLatest(userUpdateTypes.UPDATE_PROFILE_REQUEST, sendRequest);
}
export function* sendRequest({ payload: { first_name, last_name, timeStamp, image } }) {
    try {
        yield call(updateUserName, first_name, last_name, timeStamp, image);
        const userRef = yield call(getSnapshotFromDb, timeStamp);
        //Updating the user in  redux store 
        yield put(updateCurrentUser({ payload: userRef.user }));
        yield put(updateProfileSuccess({ payload: userRef.user }));

    } catch (e) {
        const msg = e.code.toString().substring(5, e.code.toString().length )
        const errMsg = msg.replace(/-/g, ' ');
        yield put(updateProfileFaliure(errMsg+'!'));
    }
}



export function* updateSagas() {
    yield all([
        call(onUpdateProfileStart),
    ]);
}