import { useState } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { firebase } from '../../Firebase/FirebaseUtils'
import Swal from 'sweetalert2'
export default function ForgetPasswordModel(props) {
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const hidePopUp = () => {
    props.hidePopUp()
  }
  const sendInfo = () => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(function () {
        setTimeout(() => {
          Swal.fire({
            title: 'Success',
            type: 'success',
            text: 'Your reset password link was sent to your email address.',
          })
        }, 400)
        hidePopUp()
      })
      .catch(function (e) {
        // console.log(e.FirebaseError)
        setError('Something went wrong')
        setTimeout(() => {
          setError('')
        }, 7000)
      })
  }
  return (
    <Modal
      {...props}
      size="md"
      backdrop={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton onClick={hidePopUp}>
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{ color: '#a3a3a3' }}
        >
          Please enter your email to search for your account.
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input
          className="LoginForm--username"
          type="email"
          placeholder="Enter email address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <span className="LoginForm__err-msg">{error}</span>
        <button onClick={sendInfo} className="LoginForm--submit-btn">
          Submit
        </button>
      </Modal.Body>
    </Modal>
  )
}
