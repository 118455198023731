import React, { useState, useEffect, useMemo } from "react";
import "./addtip.scss";
export default function AddTip({ onChange, editReceiptData }) {
  // console.log(editReceiptData, 'editReceiptData')
  const [percentAmount, setpercentAmount] = useState("");
  const [errorTaxOne, setErrorTaxOne] = useState("");
  const { totalAmount, tip_amount } = editReceiptData;

  // var flag = false;

  // console.log(percentAmount, totalAmount, "totalllllllllllllll");
  console.log(percentAmount, "setpercentAmount----");

  const getTipValue = (e) => {
    setErrorTaxOne("");
    const { name, value } = e.target;
    console.log(value, typeof value, "value");
    onChange(name, value);
  };
  //formating number😎
  const formatTax = (number) => {
    const value = new Intl.NumberFormat("en", {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(number);
    let number1 = value.replace(/,/g, "");
    console.log(number1, "number1");
    if (isNaN(number1)) {
      setErrorTaxOne(() => {
        return {
          error: "Please enter valid input!",
        };
      });
      return "";
    } else {
      if (errorTaxOne !== number) {
        return value;
      } else {
        return "";
      }
    }
  };

  useEffect(() => {
    const { totalAmount, tip_amount } = editReceiptData;

    console.log(tip_amount, "totalAmount-tip_amount--");
    console.log("get receipt value--", editReceiptData);

    var tip_amo = Number(tip_amount.replace(/[^0-9\.-]+/g, ""));

    const per_amt = parseFloat(
      (tip_amo * 100) / (totalAmount - tip_amo)
    ).toFixed(2);
    console.log(tip_amo, typeof tip_amo, "tip_amo000");
    if (totalAmount && tip_amount) {
      if (per_amt !== "") {
        if (per_amt !== "0.00") {
          setpercentAmount(per_amt);
          // flag = true;
        } else {
          setpercentAmount("");
        }
      } else {
        setpercentAmount("");
      }
    } else {
      setpercentAmount("");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editReceiptData.totalAmount, editReceiptData.tip_amount]);

  const getPercentAmount = () => {
    if (percentAmount && totalAmount && tip_amount) {
      return percentAmount + "%";
    } else if (totalAmount && tip_amount == "0.00") {
      return "0.00%";
    } else {
      // setpercentAmount("");
      // percent_amount
      return "Percent";
    }
  };
  // console.log("tip_amount--11", typeof tip_amount, tip_amount);

  useMemo(() => {
    getPercentAmount();
  }, [percentAmount && totalAmount]);

  return (
    <div className="EditReceipt__card--container tip">
      <div className="row no-gutters">
        <div className="EditReceipt__card--contents col-lg-7">
          <div className="row">
            <div className=" col-7 EditReceipt__card--items">
              <label className="EditReceipt__card--head--text">
                Tip Amount
              </label>
              <div className="d-flex EditReceipt__card--text-small">
                {
                  <span>
                    {!isNaN(editReceiptData.tip_amount)
                      ? editReceiptData.currencySymbol
                      : editReceiptData.tip_amount !== ""
                      ? editReceiptData.currencySymbol
                      : ""}
                  </span>
                }
                {console.log(
                  editReceiptData.tip_amount,
                  "editReceiptData.tip_amount--"
                )}
                <input
                  style={{ border: "none" }}
                  type="text"
                  name="tip_amount"
                  defaultValue={
                    editReceiptData.tip_amount
                      ? editReceiptData.tip_amount
                          .toString()
                          .replace(/[^.,\d]/g, " ")
                      : ""
                  }
                  onChange={(e) => getTipValue(e)}
                  onBlur={(e) => {
                    e.target.value = formatTax(e.target.value);
                  }}
                  // className="EditReceipt__card--text-small"
                  placeholder="Amount"
                />
              </div>
              {
                <span className="EditReceipt__err-msg-tax">
                  {errorTaxOne.error ? errorTaxOne.error : ""}
                </span>
              }
            </div>
            <div className=" col-5 EditReceipt__card--items">
              <label className="EditReceipt__card--head--text">
                Percentage
              </label>

              <input
                name="percent_amount"
                value={getPercentAmount()}
                // defaultValue={percentAmount && totalAmount ? percentAmount + "%" : "Percent"}
                readOnly
                type="text"
                style={{ border: "none" }}
                className="EditReceipt__card--text-small"
                // placeholder="Percents"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
