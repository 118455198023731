import { editReceiptItemTypes } from './editRecieptData.types'
//data will be persisted during page refresh
export const editReceiptItemPersistRequest = (data) => ({
  type: editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REQUEST,
  payload: data,
})

export const receiptItemStoreRequestSuccess = (data) => ({
  type: editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_STORE_SUCCCESS,
  payload: data,
})
export const receiptItemStoreRemoveDataRequest = (data) => ({
  type: editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_REQUEST,
  payload: '',
})
export const receiptItemStoreRemoveDataSuccess = () => ({
  type: editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_SUCCESS,
  payload: '',
})
