import React from 'react'
export default function ListFooterSave(props) {


  console.log(props, "propsssss")
  return (
    <div className="ListFooter">
      <div className="ListFooter__btn--wrapper">
        <div className="EditProfile__err-msg">{props.error}</div>
        {props.userView ? (
        <>
            <button
            onClick={props.onCancel}
            className="ListFooter__btn btn btn-outline-success"
          >
            Cancel
          </button>
              <button
                className="ListFooter__btn btn btn-outline-success"
                onClick={props.onExportFile}
              >
                Export
              </button>
          
           
              <button
                className="ListFooter__btn--delete btn btn-outline-danger"
                onClick={props.onDelete}
              >
                Delete
              </button>
          
           
              <button
                className="ListFooter__btn-save btn btn-outline-success"
                onClick={props.onSave}
              >
                Update
              </button>
      </>
       
        ) : (
          <>
          <button
            onClick={props.onCancel}
            className="ListFooter__btn btn btn-outline-success"
          >
            Cancel
          </button>
            <button
              className="ListFooter__btn-save btn btn-outline-success"
              onClick={props.onSave}
            >
              {props.buttonStatus}
            </button>
         </>
        )}
      </div>
    </div>
  )
}
