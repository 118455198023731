import React, { useState, useRef } from "react";
import { KeyboardArrowUp } from "@material-ui/icons";
import ImageCropper from "../../ImageCropper/ImageCropper";
import "./recieptbutton.scss";
import { EditDoc } from "../../Utils/Constant";
import { ScannerOutline } from "../../Utils/Constant";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/RecieptImageLoader";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { receiptItemStoreRemoveDataRequest } from "../../Redux/EditRecieptData/editRecieptData.action";

export default function RecieptButton(props) {
  const inputFile = useRef(null);
  const [merchantName, setMerchantName] = useState("");
  const [modelState, setModelState] = useState(false);
  const [isCropWindow, setCropWindow] = useState(false);
  const [imageToCrop, setImageToCrop] = useState(false);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  //navigate function
  const navigate = useNavigate();
  const handleModel = () => {
    setImageToCrop("");
    setModelState(!modelState);
    // props.onButtonClick();
  };

  const redirectTo = () => {
    //Empty the store if any data exists
    dispatch(receiptItemStoreRemoveDataRequest());
    navigate("create-receipt", { state: { from: "Manual" } });
  };
  //Opening file picker for uploading reciept data
  function handleTakePhoto() {
    inputFile.current.click();
  }

  const onUploadFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      if (event.target.files[0].type === "application/pdf") {
        //navigate to pdf view screen
        navigate("pdf-view", {
          state: { pdfUrl: event.target.files[0], button: true },
        });
      } else {
        var filename = event.target.files[0].name;
        setMerchantName(filename);
        setCropWindow(true);
        const reader = new FileReader();

        reader.addEventListener("load", () => {
          const image = reader.result;

          setImageToCrop(image);
        });

        reader.readAsDataURL(event.target.files[0]);
      }
    }
    setModelState(false);
  };
  const onLoader = () => {
    setLoader(true);
  };
  const offLoader = () => {
    setLoader(false);
  };
  return (
    <>
      <ImageCropper
        show={isCropWindow}
        merchantname={merchantName}
        imagetocrop={imageToCrop}
        loaderon={onLoader}
        loaderoff={offLoader}
        Imagecropped={() => setImageToCrop("")}
        onHide={() => setCropWindow(false)}
      />
      {loader && <Loader />}
      <div className="RecieptButton__bottom ">
        <div className="RecieptButton__bottom_postion">
          {modelState ? (
            <div className="RecieptButton__model_container">
              <ul
                className="RecieptButton__model"
                style={{ bottom: props.modelBottom }}
              >
                <div
                  className="RecieptButton__list--container"
                  onClick={(e) => redirectTo()}
                >
                  <li className="RecieptButton__list">
                    <img
                      className="RecieptButton__logo"
                      src={EditDoc}
                      alt="not found"
                    />
                    Create manually
                  </li>
                </div>
                <div
                  style={{ borderBottom: "none" }}
                  className="RecieptButton__list--container"
                  onClick={handleTakePhoto}
                >
                  <li className="RecieptButton__list">
                    <input
                      className="visuallyhidden"
                      type="file"
                      ref={inputFile}
                      onChange={onUploadFile}
                      accept="image/*application/pdf*"
                      // capture="environment"
                    />
                    <img
                      className="RecieptButton__logo"
                      src={ScannerOutline}
                      alt="not found"
                    />
                    Auto-Scan receipt
                  </li>
                </div>
              </ul>
            </div>
          ) : (
            ""
          )}
          <div
            className="RecieptButton__button-container d-flex"
            style={{ bottom: props.bottom }}
            onClick={(e) => handleModel()}
          >
            <button className="RecieptButton__button">Add Receipt</button>
            <span className="RecieptButton__arrowUp">
              <KeyboardArrowUp style={{ fontSize: "32px" }} />
            </span>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </>
  );
}
