import { combineReducers } from "redux"
import persistReducer from "redux-persist/es/persistReducer";
import storage from 'redux-persist/lib/storage';
import auth from "./Auth";
import updateUserReducer from "./UpdateUserData";
import saveReceiptReducer from "./RecieptListItem";
import editReceiptItemReducer from "./EditRecieptData";

const persistConfig = {
    key: 'root',
    storage: storage,
    whitelist: ['auth',],
    blacklist: ['error', 'updateUserReducer', 'auth.loading'],
}

const authPersistConfig = {
    key: 'auth',
    storage: storage,
    blacklist: ['auth.error', 'auth.loading']
}
const editReceiptPersitConfig = {
    key: 'editReceiptItemReducer',
    storage: storage
}


const rootReducer = combineReducers({
    auth: persistReducer(authPersistConfig, auth),
    updateUserReducer,
    saveReceiptReducer,
    editReceiptItemReducer: persistReducer(editReceiptPersitConfig, editReceiptItemReducer),


})
export default persistReducer(persistConfig, rootReducer);