import React, { useState, useEffect } from "react";
import { SelectIcon } from "../../Utils/Constant";

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import "./create-edit.scss";
import {
  checkFolderExistence,
  saveFolderData,
  updateFolder,
} from "../../Firebase/dbFolderItems";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import Loader from "../Loader/RecieptImageLoader";
import CloseButtonCreateEditFolder from "../CloseButton/CloseButtonCreateEditFolder";
export default function CreateFolder() {
  const navigate = useNavigate();
  //use selector
  const currentUser = useSelector(selectCurrentUser);
  const redirectTo = () => {
    navigate("../select-icon", { state: { from: "create-folder" } });
  };
  const logo = localStorage.getItem("ICON");

  const logoName = localStorage.getItem("IMAGENAME");
  const [logoSelected, setlogoSelected] = useState(undefined);
  // const [blobImage, setBlobImage] = useState('')
  const [changedIcon, setchangedIcon] = useState(false);
  const [chnagedFolderName, setchnagedFolderName] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [errorMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [buttonName, setButtonName] = useState("");
  const [iconName, setIconName] = useState("");

  const [key, setKey] = useState("");
  //gettting the data if exists 😎
  const dataToEdit = localStorage.getItem("iconData");
  const [disableSave, setdisableSave] = useState(false);

  useEffect(() => {
    const name = "extract";
    const data = JSON.parse(dataToEdit);
    const folder_Name = localStorage.getItem("FolderName");
    if (data !== null) {
      localStorage.removeItem("ICON");
      setButtonName("Update");
      setIconName("Change Icon");
      const result = data.reduce((obj, cur) => ({ ...obj, [name]: cur }), {});
      if (logo !== null) {
        setlogoSelected(logo);
        setImageName(logoName);
        //as this will notify the firebase to upload the image to get new link
        setchangedIcon(true);
      } else {
        setlogoSelected(result.extract.image);
        //By defult sent previous link
      }
      //If user changed the folder  name
      if (folder_Name !== null || undefined) {
        setFolderName(folder_Name);
        setchnagedFolderName(true);
      } else {
        setFolderName(result.extract.folderName);
      }
      //setting the id for performing opration😝😜
      setKey(result.extract.id);
      setImageName(result.extract.imageName);
      setKey(result.extract.id);
    } else {
      setButtonName("Save");
      setIconName("Select Icon");
      setlogoSelected(logo);
      setImageName(logoName);
      //call change icon useEffect
      setchangedIcon(true);
      if (folder_Name !== null || undefined) {
        setFolderName(folder_Name);
        setchnagedFolderName(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataToEdit]);
  //check for changing  logo instance

  const getInstance = (e) => {
    const timeStamp = currentUser.payload.timeStamp;
    var re = /^[a-zA-Z 0-9].*/;
    const name = e.target.name;
    if (name === "Update") {
      if (!chnagedFolderName && !changedIcon) {
        // console.log(chnagedFolderName, changedIcon)
        // warn the user to that everything is be all right😁
        toast.warn("Nothing to update!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        if (folderName === "") {
          toast.warn("Please add a folder name!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (logoSelected === undefined) {
          toast.warn("Please select an icon!", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else if (!re.test(folderName)) {
          setErrMsg("Invalid format for folder name!");
        } else if (folderName.length > 20) {
          setErrMsg("Folder name should be less than 20 character !");
        } else {
          setIsLoading(true);
          setdisableSave(true);
          const image = logoSelected;
          const data = {
            folderName,
            imageName,
            timeStamp,
            image,
          };
          //Check if folder already exists with same name
          checkFolderExistence(folderName, timeStamp).then((res) => {
            //if not folder exists
            if (res === "200" || Object.keys(res).length <= 1) {
              updateFolder(key, data)
                .then((res) => {
                  setIsLoading(false);
                  if (res === "200") {
                    // empty the states
                    setlogoSelected(undefined);
                    localStorage.removeItem("iconData");
                    localStorage.removeItem("ICON");
                    localStorage.removeItem("IMAGENAME");
                    localStorage.removeItem("FolderName");
                    // redirect the user on success
                    toast.success("Folder updated successfully!", {
                      position: "bottom-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      onClose: function () {
                        setdisableSave(false);
                        navigate("../receipt-folder", { replace: true });
                      },
                    });
                  }
                })
                .catch((err) => {
                  // console.log(err)
                  setErrMsg(err);
                });
            } else {
              setIsLoading(false);
              setErrMsg("Folder with same name already exists!");
            }
          });
        }
      }
    } else {
      if (folderName === "") {
        toast.warn("Please add a folder name!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (!logoSelected) {
        toast.warn("Please select an icon!", {
          position: "bottom-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (!re.test(folderName)) {
        setErrMsg("Inavalid format for folder name!");
      }
      //finallly save the data in firebase
      else {
        setIsLoading(true);
        const image = logoSelected;
        setdisableSave(true);
        checkFolderExistence(folderName, timeStamp).then((res) => {
          if (res === "200") {
            saveFolderData(folderName, timeStamp, imageName, image)
              .then((res) => {
                setIsLoading(false);
                if (res === "200") {
                  localStorage.removeItem("iconData");
                  localStorage.removeItem("ICON");
                  localStorage.removeItem("IMAGENAME");
                  localStorage.removeItem("FolderName");
                  // empty the states
                  setlogoSelected(undefined);
                  // redirect the user on success
                  toast.success("Folder created successfully!", {
                    position: "bottom-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    onClose: function () {
                      setdisableSave(false);
                      navigate("../receipt-folder", { replace: true });
                    },
                  });
                }
              })
              .catch((err) => {
                // console.log(err)
                setErrMsg(err);
              });
          } else {
            setIsLoading(false);
            setErrMsg("Folder with same name already exists!");
          }
        });
      }
    }
  };

  const saveFolderName = (e) => {
    const { value } = e.target;
    setFolderName(value);
    setErrMsg("");
    setchnagedFolderName(true);
    localStorage.setItem("FolderName", value);
  };

  return (
    <>
      {isLoading ? <Loader /> : ""}
      <div className="CreateEditFolder">
        <div className="CreateEditFolder__title--container">
          <div className="d-flex">
            <h1 className="CreateEditFolder__title--text">Create Folder</h1>
            <CloseButtonCreateEditFolder />
          </div>
          <p className="CreateEditFolder__description--text">
            Enter a folder name and select an icon from the list
          </p>
        </div>
        <div className="CreateEditFolder__card ">
          <div className="row CreateEditFolder__items">
            <div className="col-sm-5 col-5">
              <div className="CreateEditFolder__left">
                <img
                  className="CreateEditFolder__svgicons"
                  src={logoSelected ? logoSelected : SelectIcon}
                  alt={logoName}
                />
              </div>
            </div>
            <div className="col-sm-7 col-7">
              <button
                disabled={disableSave}
                onClick={getInstance}
                name={buttonName}
                className="CreateEditFolder__btn-top btn-outline-success"
              >
                {buttonName}
              </button>
            </div>
          </div>
          <div className="row CreateEditFolder__items">
            <div className="col-sm-5 col-5">
              <label
                className="CreateEditFolder__icon--label"
                onClick={redirectTo}
              >
                {iconName}
              </label>
            </div>

            <div className="col-sm-7 col-7">
              <div className="CreateEditFolder__right">
                <input
                  className="CreateEditFolder__edit--input"
                  placeholder="Folder Name"
                  value={folderName}
                  onChange={(e) => {
                    saveFolderName(e);
                  }}
                />
              </div>
            </div>
          </div>
          <span className="err_Msg"> {errorMsg}</span>
        </div>

        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </>
  );
}
