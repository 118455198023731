import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import "./App.css";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginForm from "./Login/Login";
import EditProfile from "./Components/EditProfile/EditProfile";
import EditReceipt from "./Components/EditReceipt/EditReceipt";
import AccountInfo from "./Components/AccountInfo/AccountInfo";
import PublicRoute from "./routes/PublicRoute";
import PrivateRoute from './routes/privateRoute'
import UserPreferences from "./Components/UserPreferences/UserPreferences";
import RecieptListMain from "./Components/RecieptListMain/ReceipetList";
import ReceiptFolder from "./Components/ReceiptFolder/ReceiptFolder";

import HomePage from "./Pages/HomePage";
import SelectIcon from "./Components/SelectIcon/SelectIcon";
import Settings from "./Components/Settings/Settings";
import { selectCurrentUser, checkUserToken } from "./Redux/Auth/auth.selectors"
import { useDispatch, useSelector } from "react-redux";
import Maintain from "./Components/ErrorPage/404";
import { selectReceiptFetchData } from "./Redux/RecieptListItem/receiptItemSelector";
import PdfView from "./ImageCropper/PdfView";
import CreateReceipt from "./Components/EditReceipt/CreateView";
import CreateFolder from "./Components/CreateEditFolder/CreateFolder";
import EditFolder from "./Components/CreateEditFolder/EditFolder";
import ReactGA from "react-ga";
import { firebase, firebaseConfig } from "./Firebase/FirebaseUtils";

ReactGA.initialize(firebaseConfig.measurementId);

function App() {
  const recieptData = useSelector(selectReceiptFetchData)
  const dispatch = useDispatch()
  const currentUser = useSelector(selectCurrentUser);

  const [isSignedUp, setSignedUp] = useState(false);
  const [isSignedIn, setSignedIn] = useState(false);


  useEffect(() => {
    //Send message to Sign Up that user have token and created in db
    if (checkUserToken !== null) {
      setSignedUp(true);
    }
    else {
      setSignedUp(false);
    }
    //Send sign in message to Login
    if (currentUser !== null) {
      setSignedIn(true);
    }
    else {
      setSignedIn(false);
    }

    // dispatch(checkUserSession());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentUser, recieptData])

//testing comment

  return (
    <div className="App">
      <BrowserRouter >
        <Routes >
          <Route element={<PublicRoute isAuthenticated={currentUser} />}>
            <Route path="/" element={<LoginForm userToken={isSignedUp} timeStamp={isSignedIn} />} />
          </Route>

          <Route element={<PrivateRoute isAuthenticated={currentUser} />} >
            <Route path="homepage" element={<HomePage />} >
              <Route path="" element={<RecieptListMain />} />
              <Route path="account-information" element={< AccountInfo />} />
              <Route path="edit-profile" element={<EditProfile />} exact />
              <Route path="edit-receipt" element={<EditReceipt />} />
              <Route path="create-receipt" element={<CreateReceipt />} />
              <Route path="preferences" element={< UserPreferences />} />
              <Route path="receipt-folder" element={< ReceiptFolder />} />
              <Route path="edit-folder" element={< EditFolder/>} />
                  <Route path="create-folder" element={< CreateFolder />} />
              <Route path="select-icon" element={< SelectIcon />} />
              <Route path="settings" element={< Settings />} />
              <Route path="pdf-view" element={<PdfView />} />
            </Route>
            <Route path='*' element={<Maintain />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div >
  );
}

export default App;
