import React from 'react'
import { Modal } from 'react-bootstrap'
import privacyPolicy from '../../JsonFiles/Privacy.json'
import './terms.scss'
export default function PrivacyPolicy(props) {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="TermsCondition__Model--content"
      >
        <Modal.Header
          closeButton={() => props.onHide}
          className="TermsCondition--model_header"
        >
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="TermsCondition__model_title"
          >
            Privacy policy
          </Modal.Title>
          <Modal.Body
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {privacyPolicy &&
              privacyPolicy.title.map((dataMain, index, arr) => (
                <div key={index}>
                  {dataMain.heading && dataMain.heading ? (
                    <h2 key={index} className="TermsCondition__title">
                      {dataMain.heading}
                    </h2>
                  ) : (
                    ''
                  )}
                  {dataMain.discription ? (
                    <p className="TermsCondition__description" key={index}>
                      {dataMain.discription}
                    </p>
                  ) : (
                    ''
                  )}

                  {dataMain &&
                    dataMain.subPriv.map((data, i, lastArr) => (
                      <div key={i}>
                        {data.subHeading ? (
                          <h4 key={i} className="TermsCondition__title">
                            {data.subHeading}
                          </h4>
                        ) : (
                          ''
                        )}

                        {data.subDisc ? (
                          <p className="TermsCondition__description" key={i}>
                            {data.subDisc}
                          </p>
                        ) : (
                          ''
                        )}

                        {index === arr.length - 1 && i === lastArr.length - 1 && (
                          <address className="mx-2">
                            <span className="mx">Email:</span>
                            <a
                              className="mx-2"
                              href="mailto:privacy@receiptmate.com"
                            >
                              privacy@receiptmate.com
                            </a>
                            <br />
                            Mail:
                            <br />
                            ReceiptMateInc.
                            <br />
                            2043 rue de paris <br />
                            Montréal, QC <br />
                            H3K1T9
                            <br />
                            Canada
                          </address>
                        )}
                      </div>
                    ))}
                </div>
              ))}
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </div>
  )
}
