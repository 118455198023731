import React from 'react'
import { Modal } from 'react-bootstrap'
import termAndCond from '../../JsonFiles/TermsandConditions.json'
import './terms.scss'
export default function TermsConditon(props) {
  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        className="TermsCondition__Model--content"
      >
        <Modal.Header
          closeButton={() => props.hideModel}
          className="TermsCondition--model_header"
        >
          <Modal.Title
            id="contained-modal-title-vcenter "
            className="TermsCondition__model_title"
          >
            Terms and Conditons
          </Modal.Title>
          <Modal.Body
            style={{
              maxHeight: 'calc(100vh - 210px)',
              overflowY: 'auto',
            }}
          >
            {termAndCond.terms.map((data, index, arr) => (
              <div key={index}>
                <h4 key={index} className="TermsCondition__title">
                  {data.heading}
                </h4>
                {data &&
                  data.discription.map((desc, i, lastArr) => (
                    <div key={i} className="d-flex">
                      <p key={i} className="TermsCondition__description d-flex">
                        {desc.title}
                      </p>
                      {index === arr.length - 1 && i === lastArr.length - 1 && (
                        <a
                          className="mx-2"
                          href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                        >
                          -services/itunes/dev/stdeula/
                        </a>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </Modal.Body>
        </Modal.Header>
      </Modal>
    </div>
  )
}
