import React from "react";
import "./listitems.scss";
import { CheckCircleOutline } from "@material-ui/icons";
export default function ListItemsHeader({ handleClick }) {
  return (
    <>
      <thead>
        <tr className="ReceiptListHeader">
          <th colSpan="1" className="ReceiptListHeader__icon--uncheck ">
            <CheckCircleOutline onClick={handleClick} />
          </th>
          <th colSpan="1" className="ReceiptListHeader__links">
            Date
          </th>
          <th colSpan="5" className="ReceiptListHeader__links">
            Merchant Name
          </th>
          <th colSpan="3" className="ReceiptListHeader__links">
            Amount
          </th>
          <th
            colSpan="2"
            className="ReceiptListHeader__links"
            style={{ textAlign: "center" }}
          >
            Tax & Tip
          </th>
          <th
            colSpan="3"
            className="ReceiptListHeader__links"
            style={{ textAlign: "center" }}
          >
            Category
          </th>
          <th colSpan="3" className="ReceiptListHeader__links">
            Folders
          </th>
        </tr>
      </thead>
    </>
  );
}
