export const VerifyErroCode =(errorCode)=> {
    // fonte: https://firebase.google.com/docs/reference/js/firebase.auth.Auth
    // fonte: https://firebase.google.com/docs/auth/admin/errors?hl=pt-br
    switch (errorCode) {
        case 'auth/app-deleted':
            return 'App has been deleted';
        case 'auth/expired-action-code!':
            return 'Expired action code';
        case 'auth/invalid-action-code':
            return 'Invalid action code!';
        case 'auth/user-disabled':
            return 'User has been disabled!';
        case 'auth/user-not-found':
            return 'User not found!';
        case 'auth/weak-password':
            return 'Weak password!';
        case 'auth/email-already-in-use':
            return 'Email already in use!';
        case 'auth/invalid-email':
            return 'Invalid email!';
        case 'auth/operation-not-allowed':
            return 'Opertion not allowed!';
        case 'auth/account-exists-with-different-credential':
            return 'Account exists with different credential!';
        case 'auth/auth-domain-config-required':
            return 'A domain configuartion is required!';
        case 'auth/credential-already-in-use':
            return 'Credential already in use!';
        case 'auth/operation-not-supported-in-this-environment':
            return 'Opertion not supported in this environment!';
        case 'auth/timeout':
            return 'Action timeout!';
        case 'auth/missing-android-pkg-name':
            return 'Missing android package name!';
        case 'auth/missing-continue-uri':
            return 'A próxima URL deve ser fornecida na solicitação.';
      
        case 'auth/wrong-password':
            return 'The username and password does not match!';
        case 'auth/invalid-verification-code':
            return 'Invalid verification code!';
        case 'auth/invalid-verification-id':
            return 'Invalid verification id!';
       
        case 'auth/invalid-phone-number':
            return 'Invalid phone number';
        case 'auth/missing-phone-number':
            return 'Missing phone number!';
        case 'auth/quota-exceeded':
            return 'A cota de SMS foi excedida.';
        case 'auth/cancelled-popup-request':
            return 'Somente uma solicitação de janela pop-up é permitida de uma só vez.';
        case 'auth/popup-blocked':
            return 'A janela pop-up foi bloqueado pelo navegador.';
        case 'auth/popup-closed-by-user':
            return 'A janela pop-up foi fechada pelo usuário sem concluir o login no provedor.';
        case 'auth/unauthorized-domain':
            return 'O domínio do aplicativo não está autorizado para realizar operações.';
        case 'auth/invalid-user-token':
            return 'O usuário atual não foi identificado.';
        case 'auth/user-token-expired':
            return 'O token do usuário atual expirou.';
        case 'auth/null-user':
            return 'O usuário atual é nulo.';
        case 'auth/app-not-authorized':
            return 'Aplicação não autorizada para autenticar com a chave informada.';
        case 'auth/invalid-api-key':
            return 'A chave da API fornecida é inválida.';
        case 'auth/network-request-failed':
            return 'Falha de conexão com a rede.';
        case 'auth/requires-recent-login':
            return 'O último horário de acesso do usuário não atende ao limite de segurança.';
        case 'auth/too-many-requests':
            return 'As solicitações foram bloqueadas devido a atividades incomuns. Tente novamente depois que algum tempo.';
        case 'auth/web-storage-unsupported':
            return 'O navegador não suporta armazenamento ou se o usuário desativou este recurso.';
        case 'auth/invalid-claims':
            return 'Os atributos de cadastro personalizado são inválidos.';
        case 'auth/claims-too-large':
            return 'O tamanho da requisição excede o tamanho máximo permitido de 1 Megabyte.';
        case 'auth/id-token-expired':
            return 'O token informado expirou.';
        case 'auth/id-token-revoked':
            return 'O token informado perdeu a validade.';
        case 'auth/invalid-argument':
            return 'Um argumento inválido foi fornecido a um método.';
        case 'auth/invalid-creation-time':
            return 'O horário da criação precisa ser uma data UTC válida.';
        case 'auth/invalid-disabled-field':
            return 'A propriedade para usuário desabilitado é inválida.';
        case 'auth/invalid-display-name':
            return 'O nome do usuário é inválido.';
        case 'auth/invalid-email-verified':
            return 'O e-mail é inválido.';
        case 'auth/invalid-hash-algorithm':
            return 'O algoritmo de HASH não é uma criptografia compatível.';
        case 'auth/invalid-hash-block-size':
            return 'O tamanho do bloco de HASH não é válido.';
        case 'auth/invalid-hash-derived-key-length':
            return 'O tamanho da chave derivada do HASH não é válido.';
        case 'auth/invalid-hash-key':
            return 'A chave de HASH precisa ter um buffer de byte válido.';
        case 'auth/invalid-hash-memory-cost':
            return 'O custo da memória HASH não é válido.';
        case 'auth/invalid-hash-parallelization':
            return 'O carregamento em paralelo do HASH não é válido.';
        case 'auth/invalid-hash-rounds':
            return 'O arredondamento de HASH não é válido.';
        case 'auth/invalid-hash-salt-separator':
            return 'O campo do separador de SALT do algoritmo de geração de HASH precisa ser um buffer de byte válido.';
        case 'auth/invalid-id-token':
            return 'O código do token informado não é válido.';
        case 'auth/invalid-last-sign-in-time':
            return 'O último horário de login precisa ser uma data UTC válida.';
        case 'auth/invalid-page-token':
            return 'A próxima URL fornecida na solicitação é inválida.';
        case 'auth/invalid-password':
            return 'Invalid password!';
    
    
        case 'auth/email-already-exists':
            return 'Email already exists!';
        case 'auth/phone-number-already-exists':
            return 'Phone number already exists!';
     
        case 'auth/insufficient-permission':
            return 'Insufficient permission error!.';
        case 'auth/internal-error':
            return 'Internal error!';
        default:
            return null;
    }
}