import React, { useState, useMemo } from "react";
import "./receiptfolder.scss";
import Searchbutton from "../SearchButton/Searchbutton";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ArrowRight } from "../../Utils/Constant";

import CloseButton from "../CloseButton/CloseButton";
import { selectCurrentUser } from "../../Redux/Auth/auth.selectors";
import { getFolderItemsSnapshot } from "../../Firebase/dbFolderItems";
import SearchButtonFolder from "../SearchButton/SearchButtonFolder";
import CloseButtonSelectIcon from "../CloseButton/CloseButtonSelectIcon";
import { useEffect } from "react";
import ReactGA from "react-ga";

export default function ReceiptFolder() {
  const location = useLocation();

  const navigate = useNavigate();
  const currentUser = useSelector(selectCurrentUser);
  const [width, setwidth] = useState("");
  const [folderList, setfolderList] = useState([]);
  const [searchKeyWord, setSearchKeyword] = useState("");
  const redirectTo = () => {
    //remove previous records from local storage
    localStorage.removeItem("iconData");
    localStorage.removeItem("ICON");
    localStorage.removeItem("IMAGENAME");
    localStorage.removeItem("FolderName");
    navigate("../create-folder", { state: { from: "Create" } }); //redirect to create folder view
  };
  //getting folder list from firebase
  const timeStamp = currentUser.payload.timeStamp;

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  function compare(a, b) {
    if (a.folderName < b.folderName) {
      return -1;
    }
    if (a.folderName > b.folderName) {
      return 1;
    }
    return 0;
  }

  //gettting the item id to redirect user to  select icon for performing crud opertion😎
  const getItemDetail = (id) => {
    const tempData = folderList.filter((items) => items.id === id);
    //remove the previos value if exists
    localStorage.removeItem("FolderName");
    //save the new values in local storage
    localStorage.setItem("iconData", JSON.stringify(tempData));
    navigate("../edit-folder", { replace: true }); //redirect to edit folder
  };
  useMemo(() => {
    let mounted = true;
    getFolderItemsSnapshot(timeStamp).then((res) => {
      const tempData = [];
      for (var key in res.payload) {
        tempData.push({
          id: key,
          ...res.payload[key],
        });
      }
      const data = tempData.sort(compare);
      if (mounted) {
        setfolderList([...data]);
      }
    });

    return () => (mounted = false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredData = useMemo(() => {
    if (searchKeyWord !== "") {
      return folderList.filter((value) =>
        value.folderName.toLowerCase().includes(searchKeyWord)
      );
    }

    return folderList ?? [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyWord, folderList]);

  //Searching the folder for key
  const searchForKeyWord = (key) => {
    const inputValue = key.toLowerCase().trim();
    setSearchKeyword(inputValue);
  };

  return (
    <div className="ReceiptFolder">
      <div className="ReceiptFolder__title--container">
        <div className="d-flex">
          <h1 className="ReceiptFolder__title--text">Receipt Folders</h1>
          <CloseButtonSelectIcon redirectFrom={"../"} />
        </div>
      </div>
      <div className="ReceiptFolder_sBcontainer">
        <SearchButtonFolder
          placeholder="Search"
          onInputChange={searchForKeyWord}
        />

        <div className="ReceiptFolder__btn--container" onClick={redirectTo}>
          <button className="ReceiptFolder__btn--add-folder btn-success">
            Create Folder
          </button>
        </div>
      </div>
      {filteredData.length > 0 && (
        <div className="ReceiptFolder__card ">
          {filteredData &&
            filteredData.map((itmes, i) => (
              <div key={itmes.id}>
                <div className="ReceiptFolder__items" key={itmes.id}>
                  <div
                    key={i + 2}
                    className="row row-no-gutters ReceiptFolder__padding"
                    onClick={() => getItemDetail(itmes.id)}
                  >
                    <div className="col-sm-10 col-10" key={i + 3}>
                      <div className="d-flex" key={i + 4}>
                        <img
                          key={i + 5}
                          className="ReceiptFolder__items_icons"
                          src={itmes.image}
                          alt={itmes.imageName}
                        />
                        <label
                          className="ReceiptFolder__label--text"
                          key={i + 6}
                        >
                          {itmes.folderName}
                        </label>
                      </div>
                    </div>
                    <div
                      className="col-sm-2 col-2 ReceiptFolder__align-items-end "
                      key={i + 8}
                    >
                      <img
                        key={i + 9}
                        className="ReceiptFolder__items_arrow"
                        src={ArrowRight}
                        alt="not found"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
