import React from "react";
import { Search } from "@material-ui/icons";
import "./searchbutton.scss";
export default function SearchButtonFolder(props) {
  const getTypedValue = (e) => {
    props.onInputChange(e.target.value);
  };
  return (
    <div className="SearchBarwrapper w-100">
      <div className="SearchBarwrapper__container d-flex">
        <Search
          className="SearchBarwrapper__icon"
          style={{
            color: "#112153",
            marginTop: "10px",
            margin: "10px",
            width: "35px",
            height: "35px",
          }}
        />
        <input
          className="SearchBarwrapper__search-input"
          type="search"
          id="Search"
          name="search_receipts"
          placeholder={props.placeholder}
          onChange={(e) => getTypedValue(e)}
        />
      </div>
    </div>
  );
}
