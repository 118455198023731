import { editReceiptItemTypes } from "./editRecieptData.types";
const initialState = {
  data: [],
};
//Saving edit reciepts data during page refresh
const editReceiptItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REQUEST:
      return {
        ...state,
        data: [],
      };
    case editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_STORE_SUCCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_REQUEST:
      return {
        ...state,
        data: [],
      };
    case editReceiptItemTypes.RECEIPT_ITEM_EDITRECEIPT_DATA_STORE_REMOVE_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };

    default:
      return state;
  }
};

export default editReceiptItemReducer;
