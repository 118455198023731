import { createSelector } from "reselect";

const selectState = (state) => state.updateUserReducer;
export const selectUpdateLoadingState = createSelector(
    [selectState],
    updateUserReducer => updateUserReducer.loading
);
export const selectUpdateErrors = createSelector(
    [selectState],
    updateUserReducer => updateUserReducer.error
);
export const selectUpdateInfo = createSelector(
    [selectState],
    updateUserReducer => updateUserReducer.data
);
