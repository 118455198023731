import { firebase } from "./FirebaseUtils";
//Function for sdtoring data in firebase
let path = "";
export const receiptItemSave = async (editReceiptData, imagePath) => {
  return new Promise((resolve, reject) => {
    const { timeStamp } = editReceiptData;
    const path = `/ReceiptItem/${timeStamp}`;
    const dbRef = firebase.database().ref(path);
    var response = "";
    //if user chnged the image
    if (imagePath !== undefined) {
      uploadImage(timeStamp, imagePath)
        .then((imgUrl) => {
          const receiptImage = imgUrl;
          try {
            dbRef.push().set(
              {
                receiptImage: receiptImage,
                ...editReceiptData,
              },
              (error) => {
                if (error) {
                  // console.log('Data could not be saved.' + error);
                  reject(error);
                } else {
                  // console.log('Data saved successfully.');
                  response = "200";
                  resolve(response);
                }
              }
            );
          } catch (error) {
            response = error;
            // console.log("error creating ", error.message)
          }
        })
        .catch((err) => {
          //If image is not uploaded
          reject(err);
        });
    } else {
      try {
        dbRef.push().set(
          {
            ...editReceiptData,
          },
          (error) => {
            if (error) {
              // console.log('Data could not be saved.' + error);
              reject(error);
            } else {
              // console.log('Data saved successfully.');
              response = "200";
              resolve(response);
            }
          }
        );
      } catch (error) {
        response = error;
        // console.log("error creating ", error.message)
      }
    }
  });
};
//Update reciept Item😎
export const receiptItemUpdate = async (
  editReceiptData,
  isImageChangedFlag,
  imagePath,
  timeStamp
) => {
  try {
    return await new Promise((resolve, reject) => {
      var response = "";
      const { id } = editReceiptData;
      const path = `/ReceiptItem/${timeStamp}`;
      if (isImageChangedFlag) {
        uploadImage(timeStamp, imagePath).then((imgUrl) => {
          const receiptImage = imgUrl;
          try {
            firebase
              .database()
              .ref(path)
              .child(id)
              .update({
                ...editReceiptData,
                receiptImage: receiptImage,
              })
              .then(function () {
                response = "200";
                resolve(response);
              })
              .catch(function (error) {
                reject(error.message);
              });
          } catch (err) {
            // console.log(err, "errr");
          }
        });
      } else {
        //simply update the values without upload img
        firebase
          .database()
          .ref(path)
          .child(id)
          .update({
            ...editReceiptData,
          })
          .then(function () {
            response = "200";
            resolve(response);
          })
          .catch(function (error) {
            reject(error.message);
          });
      }
    });
  } catch (err) {
    // console.log(err);
  }
};
//delete records from storage

export const deleteRecieptItems = async (key, timeStamp) => {
  return new Promise((resolve, reject) => {
    const path = `/ReceiptItem/${timeStamp}`;
    var response = "";
    try {
      key.map((k, i) => {
        firebase
          .database()
          .ref(path)
          .child(k)
          .remove()
          .then(function () {
            response = "200";
            // console.log("sucess");
            resolve(response);
          })
          .catch(function (error) {
            reject(error.message);
          });
        return true;
      });
    } catch (error) {
      // console.log(error);
    }
  });
};

//Getting the recipt data
export const getRecipetDataSnapshot = async (timeStamp) => {
  const dbRef = firebase.database().ref();
  const path = `/ReceiptItem/${timeStamp}`;
  const snapshot_val = await dbRef
    .child(path)
    .once("value")
    .then((snapshot) => {
      if (snapshot.exists()) {
        return snapshot.val();
      }
    })
    .catch((error) => {
      // console.error(error);
    });
  return Promise.resolve({ payload: snapshot_val });
};
export const uploadImage = (timeStamp, imagePath) => {
  //functionality for uploading the image on cloud db and get link
  // Return a promise that will either resolve or emit an error
  return new Promise((resolve, reject) => {
    var storage = firebase.storage();
    var seq = (Math.floor(Math.random() * 108098980000) + 10090900)
      .toString()
      .substring(1);
    path = seq;
    var uploadTask = storage.ref(`/images/${path}`).put(imagePath);
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.log('Upload is ' + progress + '% done')
        // console.log(snapshot.state)
        //save the image link in db
      },
      function (error) {
        // console.log(error)
        // setshowProgress(false)
        // Handle unsuccessful uploads
        // An error occurred so inform the caller
        reject(error);
      },

      async () => {
        // Handle successful uploads on complete
        const url = await storage.ref(`/images/${path}`).getDownloadURL();

        // console.log("this is preview", url)
        if (url) {
          // We 'awaited' the imgURL, now resolve this Promise
          resolve(url);
        }
      }
    );
  });
};

export const deleteImageFile = async (key, timeStamp) => {
  // console.log(timeStamp, "timeStamp");
  // console.log("key", key);
  return new Promise((resolve, reject) => {
    const path = `/ReceiptItem/${timeStamp}`;
    let response = "";
    try {
      firebase
        .database()
        .ref(path)
        .child(key)
        .update({
          receiptImage: "",
        })
        .then(function () {
          response = "200";
          // console.log("sucess");
          resolve(response);
        })
        .catch(function (error) {
          reject(error.message);
        });
    } catch (error) {
      response = error;
      // console.log("error creating ", error.message)
    }
  });
};
export const deletePdfFile = async (key, timeStamp) => {
  // console.log(timeStamp, "timeStamp");
  // console.log("key", key);
  return new Promise((resolve, reject) => {
    const path = `/ReceiptItem/${timeStamp}`;
    let response = "";
    try {
      firebase
        .database()
        .ref(path)
        .child(key)
        .update({
          firebaseUrl: "",
        })
        .then(function () {
          response = "200";
          // console.log("sucess");
          resolve(response);
        })
        .catch(function (error) {
          reject(error.message);
        });
    } catch (error) {
      response = error;
      // console.log("error creating ", error.message)
    }
  });
};
