//Convert image to blob
export function imageToBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1])
    var ab = new ArrayBuffer(byteString.length)
    var ia = new Uint8Array(ab)
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
    }
    var bb = new Blob([ab])
    return bb
}
export async function getImageToBlob(logo) {
    let blob = await fetch(logo).then((r) => r.blob())
    return blob;
}