import React from "react";
import CloseButton from "../CloseButton/CloseButton";
import "./preferences.scss";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
export default function UserPreferences() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  return (
    <div className="Preferences">
      <div className="Preferences__title--container">
        <div className="d-flex">
          <h1 className="Preferences__title--text">Preferences</h1>
          <CloseButton />
        </div>
      </div>
      <div className="row Preferences__card  Preferences__left g-2 g-lg-3">
        <div className="col-md-12">
          <h1 className="Preferences__card--title">Mobile App</h1>
        </div>

        <div className="col col-md-4 Preferences__card--items">
          <label className="Preferences__label--text">Data</label>
        </div>
        <div className="col col-md-4 Preferences__card--items">
          <label className="Preferences__label--text">Default</label>
        </div>
        <div className="col  col-md-4 Preferences__card--items"></div>
        <div className="Preferences__seperator col-md-12"></div>
        <>
          <div className="col col-md-4 Preferences__card--items">
            <label className="Preferences__label--useinfo">Home page</label>
          </div>
          <div className="col col-md-4 Preferences__card--items">
            <label className="Preferences__label--useinfo">Receipts</label>
          </div>
          <div className="col col-md-4  Preferences__card--items">
            <button className="Preferences__btn--modify">Modify</button>
          </div>
          <div className="Preferences__seperator col-md-12"></div>
        </>

        <div className="col col-md-4 Preferences__card--items ">
          <label className="Preferences__label--useinfo">
            Save receipt pictures in phone library
          </label>
        </div>
        <div className="col col-md-4 Preferences__card--items ">
          <label className="Preferences__label--useinfo">Yes</label>
        </div>
        <div className="col col-md-4 Preferences__card--items ">
          <button className="Preferences__btn--modify">Modify</button>
        </div>
        <div className="Preferences__seperator col-md-12"></div>
      </div>
      <div className="row Preferences__card  Preferences__left g-2 g-lg-3">
        <div className="col-md-12">
          <h1 className="Preferences__card--title">Receipt Data</h1>
        </div>
        <>
          <div className="col col-md-4 Preferences__card--items">
            <label className="Preferences__label--text">Data</label>
          </div>
          <div className="col col-md-4 Preferences__card--items">
            <label className="Preferences__label--text">Default</label>
          </div>
          <div className="col col-md-4"></div>
          <div className="Preferences__seperator"></div>
        </>
        <>
          <div className="col col-md-4 Preferences__card--items ">
            <label className="Preferences__label--useinfo">Currently</label>
          </div>
          <div className="col col-md-4 Preferences__card--items ">
            <label className="Preferences__label--useinfo">CAD</label>
          </div>
          <div className="col col-md-4 Preferences__card--items ">
            <button className="Preferences__btn--modify">Modify</button>
          </div>
          <div className="Preferences__seperator col-md-12"></div>
        </>
      </div>
    </div>
  )
}
