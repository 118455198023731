import { firebase } from "./FirebaseUtils";
import { uploadImage } from "./dbReceiptItem";
export const saveFolderData = async (folderName, timeStamp, imageName, image) => {
      let timeZone = new Date().getTime();
            // console.log(timeStamp, "timddd");
    return new Promise((resolve, reject) => {
        const path = `/FolderItems/${timeStamp}`;
        const dbRef = firebase.database().ref(path);
        var response = ''
        try {
            dbRef
                .push()
                .set({
                    folderName: folderName,
                    image: image,
                    timeStamp: timeZone,
                    imageName: imageName
                }, (error) => {
                    if (error) {
                        // console.log('Data could not be saved.' + error);
                        reject(error);
                    }
                    else {
                        // console.log('Data saved successfully.');
                        response = "200"
                        resolve(response)
                    }
                });
        }
        catch (error) {
            response = error
            // console.log("error creating ", error.message)
        }


    });
}
//get folder icons list data from firebase db
export const getFolderIconsSnapshot = async () => {
    const dbRef = firebase.database().ref();
    const path = `/FolderIcons`;
    const snapshot_val = await dbRef.child(path).get().then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    })
        .catch((error) => {
            // console.error(error);
        });
    return Promise.resolve({ payload: snapshot_val });

}
//get folder list data from firebase db
export const getFolderItemsSnapshot = async (timeStamp) => {
    const dbRef = firebase.database().ref();
    const path = `/FolderItems/${timeStamp}`;
    const snapshot_val = await dbRef.child(path).get().then((snapshot) => {
        if (snapshot.exists()) {
            return snapshot.val()
        }
    })
        .catch((error) => {
            // console.error(error);
        });
    return Promise.resolve({ payload: snapshot_val });

}
// delete the folder from database 🤦‍♂️
export const deleteItem = (key, timeStamp) => {
    return new Promise((resolve, reject) => {
        var response = ''
        // const path = `/FolderItems/${timeStamp}`;
        const path = `/FolderItems/${timeStamp}/${key}`;
        firebase.database().ref(path)
            .remove().then(function () {
                response = "200"
                resolve(response);
            })
            .catch(function (error) {
                reject(error.message)
            });
    })
}
export const checkFolderExistence = async (folderName, timeStamp) => {
    const path = `/FolderItems/${timeStamp}`;
    let response = ''
    try {
        return await new Promise((resolve, reject) => {
            firebase.database().ref(path)
                .orderByChild('folderName')
                .equalTo(folderName)
                .once('value')
                .then(snapshot => {
                    if (snapshot.exists()) {
                        let folderData = snapshot.val();
                        reject(folderData)
                    } else {
                        response = "200"
                        resolve(response);
                    }
                })
        })
    } catch (err) {
        return err;
    }


}
export const updateFolder = async (key, data) => {
    try {
        return await new Promise((resolve, reject) => {
            var response = '';
            const { timeStamp } = data;
            const path = `/FolderItems/${timeStamp}`;
            firebase.database().ref(path).child(key)
                .update({
                    ...data,

                }).then(function () {
                    response = "200";
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error.message);
                });




        });
    } catch (err) {
        // console.log(err);
    }
}