import React from 'react'
import './listfooter.scss'
export default function ListFooter({
  onCancel,
  onDelete,
  onExport,
  onSelect,
  buttonSelect,
}) {
  return (
    <div className="ListFooter">
      <div className="ListFooter__btn--wrapper">

          <button
            onClick={onCancel}
            className="ListFooter__btn btn btn-outline-success"
          >
            Cancel
          </button>
   
     
          <button
            onClick={onSelect}
            className="ListFooter__btn btn btn-outline-success"
          >
            {buttonSelect ? 'Select All' : 'Unselect All'}
  
            </button>
          <button
            onClick={onExport}
            className="ListFooter__btn btn btn-outline-success"
          >
            Export
          </button>
      
     
          <button
            onClick={onDelete}
            className="ListFooter__btn--delete btn btn-outline-danger"
          >
            Delete
          </button>
    
      </div>
    </div>
  )
}
