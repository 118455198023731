import { receiptItemTypes } from "./receiptIem.types";
export const receiptItemSaveRequest = recieptItem => ({
    type: receiptItemTypes.RECEIPT_ITEM_SAVE_REQUEST,
    payload: recieptItem
})

export const receiptItemSaveFailure = error => ({
    type: receiptItemTypes.RECEIPT_ITEM_SAVE_FAILURE,
    payload: error
})
export const receiptItemSaveSuccess = data => ({
    type: receiptItemTypes.RECEIPT_ITEM_SAVE_SUCCESS,
    payload: data
})
export const receiptItemFetchRequest = timeStamp => ({
    type: receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST,
    payload: timeStamp
})
export const receiptItemFetchRequestSuccess = data => ({
    type: receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST_SUCCESS,
    payload: data
})
export const receiptItemFetchRequestFailure = error => ({
    type: receiptItemTypes.RECIEPT_ITEM_FETCH_REQUEST_FAILURE,
    payload: error
})




